import React from "react"
import { Modal, Button } from "react-bootstrap"
import { withTranslation, WithTranslation } from "react-i18next"
import { inject, observer } from "mobx-react"
import { MerchantAdminApi } from "../../Api"
import { MessagesLocation } from "../../Locations"
import { IUserSessionStore } from "../../models/UserSessionStore"
import {useHistory} from "react-router";
import { IMessageStore } from "../../models/MessageStore"
interface CancelScheduleMessageModalProps extends WithTranslation {
  show: boolean
  onHide: () => void
  onConfirm: () => void
  messageId?: string
  history?: any
  userSessionStore?: IUserSessionStore
  api?: MerchantAdminApi
  messageStore?: IMessageStore
}



const CancelScheduleMessageModal: React.FC<CancelScheduleMessageModalProps> = ({ t, show, onHide, onConfirm, api, messageId, userSessionStore, messageStore }) => {
  const history = useHistory()
  
  const handleConfirm = async () => {
    if (messageId) {
      const isDeleteSuccess = await api?.deleteMessage(messageId)
   

      if (isDeleteSuccess) {
        messageStore?.removeMessage(messageId)
        history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
      }
  
    }
  }

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header >
        <Modal.Title style={{
            width: '100%',
            textAlign: 'center'
        }}>{t("sendPushNotificationsScreen.cancelScheduleMessageModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        padding: 20,
        textAlign: 'center'
      }}>{t("sendPushNotificationsScreen.cancelScheduleMessageModal.description")}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onHide}>
          {t("sendPushNotificationsScreen.cancelScheduleMessageModal.close")}
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          {t("sendPushNotificationsScreen.cancelScheduleMessageModal.confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default withTranslation()(inject("userSessionStore", "api", "messageStore")(observer(CancelScheduleMessageModal)))
