import {Prompt, RouteComponentProps, withRouter} from "react-router"
import {IUserSessionStore} from "../models/UserSessionStore"
import {inject, observer} from "mobx-react"
import React from "react"
import {withTranslation, WithTranslation} from "react-i18next"
import {IRootStore} from "../models/RootStore"
import AlertMessage from "../components/AlertMessage"
import {trackEvent} from "../tracking"
import trackingConstants from "../tracking/trackingConstants"
import log from "loglevelnext"
import {BenefitsLocation, BenefitsModifyLocation} from "../Locations"
import {IBenefitStore} from "../models/BenefitStore"
import {clone, getSnapshot, resolveIdentifier} from "mobx-state-tree"
import {IVenue, Venue} from "../models/Venue"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Button from "react-bootstrap/Button"
import {Benefit, BenefitFormat, BenefitInterval, BenefitKind, IBenefit} from "../models/Benefit"
import * as _ from "lodash"
import BenefitModifyCard from "../components/BenefitModifyCard"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import moment from "moment-timezone"
import {autorun} from "mobx"
import {MerchantAdminApi} from "../Api"
import {getModifications} from "../utils/objectModifications"
import {ModalBox} from "../components/ModalBox"
import Modal from "react-bootstrap/Modal"
import { IMemberStore } from "../models/MemberStore"

const BENEFITMODIFY_SCREEN_ERROR = "benefitModifyScreen"
const NEW_BENEFIT_ID_PREFIX = "NEW__"

export interface BenefitsModifyScreenProps extends RouteComponentProps, WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  benefitStore?: IBenefitStore
  rootStore?: IRootStore
  memberStore?: IMemberStore
}

interface BenefitsModifyScreenState {
  editableBenefitStore?: IBenefitStore
  venueBenefits?: Array<IBenefit>
  venue?: IVenue
  edited: boolean
  showNavigationConfirmation: boolean
}

const transformLinkedVenueStatuses = (data: Array<any>) => {
  return data.map(item => {
    const hasLinkedVenueStatuses = Boolean(item.linkedVenueStatuses);

    return {
      ...item,
      ...(hasLinkedVenueStatuses && { linkedVenueStatuses: Object.values(item.linkedVenueStatuses) }),
    };
  });
};


const removeUndefinedFields = (data: Array<any>) => {
  return data.map(item => (
      Object.fromEntries(
          Object.entries(item).filter(([key, value]) => value !== null && value !== undefined)
      )
  ));
};


@(withRouter as any)
@inject("userSessionStore")
@inject("benefitStore")
@inject("rootStore")
@inject("memberStore")
@inject("api")
@observer
class BenefitsModifyScreen extends React.Component<BenefitsModifyScreenProps, BenefitsModifyScreenState> {

  benefitModifyCardRefs: any
  modificationAutorunDisposer: any
  beforeUnloadBind: any

  constructor(props: Readonly<BenefitsModifyScreenProps>) {
    super(props)

    this.state = {
      editableBenefitStore: clone(this.props.benefitStore!),
      edited: false,
      showNavigationConfirmation: true,
    }

    this.benefitModifyCardRefs = {}

  }

  beforeUnload(this: this, event: any) {
    if (this.state.edited) {
      event.returnValue = "block"
    }
  }

  componentDidMount(): void {
    log.debug("BenefitsModifyScreen mounted")
    this.initScreen()
    this.registerModificationAutorun(this.props.benefitStore!.benefits, this.state.editableBenefitStore!.benefits)
    this.beforeUnloadBind = this.beforeUnload.bind(this)
    window.addEventListener('beforeunload', this.beforeUnloadBind)
  }

  registerModificationAutorun(originalBenefits: Array<IBenefit>, modifiedBenefits: Array<IBenefit>) {
    if (this.modificationAutorunDisposer) this.modificationAutorunDisposer()
    this.modificationAutorunDisposer = autorun(() => {
      const edited = this.hasModifications(originalBenefits, modifiedBenefits)
      this.setState({
        edited: edited,
      })
    })
  }

  componentWillUnmount(): void {
    this.modificationAutorunDisposer()
    window.removeEventListener("beforeunload", this.beforeUnloadBind)
  }

  componentDidUpdate(prevProps: Readonly<BenefitsModifyScreenProps>) {
    log.debug("BenefitsModifyScreen updated")
    this.initScreen()
  }

  initScreen() {
    const {userSessionStore, history} = this.props
    const locationParams = BenefitsModifyLocation.parseLocationParams()

    if (locationParams.idType === "org") {
      history.push(BenefitsLocation.toUrl(userSessionStore!.currentOrganization!.urlFields()))
      return
    }

    const venue: IVenue = resolveIdentifier(Venue, userSessionStore!.organizations, locationParams.id) as IVenue
    if (venue !== this.state.venue) {
      trackEvent(trackingConstants.screens.benefitsModify, {selectedBranch: userSessionStore!.selectedBranch!.name})
      this.setState({
        venue: venue,
      })
    }
  }

  async saveModifications() {
    if (!this.tryCloseAndSaveEditing()) return
    const {api} = this.props

    const {editableBenefitStore} = this.state
    // @ts-ignore
    const modifications: Array<any> = getSnapshot(editableBenefitStore!.benefits)
    const original: Array<any> = getSnapshot(this.props.benefitStore!.benefits)

    const apiRequest = getModifications(modifications, original)

    const apiRequestWithUndefinedFieldsRemoved = removeUndefinedFields(apiRequest)

    const transformedApiREquest = transformLinkedVenueStatuses(apiRequestWithUndefinedFieldsRemoved)

    const updateResult = await api!.updateBenefits(transformedApiREquest, undefined, BENEFITMODIFY_SCREEN_ERROR)

    if (updateResult) {
      await this.props.rootStore!.fetchData(false, false, true)
      this.revert()
    }
  }

  hasModifications(originalBenefits: Array<IBenefit>, modifiedBenefits: Array<IBenefit>): boolean {
    // @ts-ignore
    const hasChanges = !_.isEqual(
      // @ts-ignore
      getSnapshot(originalBenefits).map((benefit: IBenefit) => {
        return _.omit(benefit, "editedAt")
      }),
      // @ts-ignore
      getSnapshot(modifiedBenefits).map((benefit: IBenefit) => {
        return _.omit(benefit, "editedAt")
      }),
    )
    return hasChanges
  }

  onBenefitModify(this: this, modifications: boolean) {
    if (!modifications) {
      const edited = this.hasModifications(this.props.benefitStore!.benefits, this.state.editableBenefitStore!.benefits)
      this.setState({edited: edited})
    } else {
      this.setState({edited: modifications})
    }
  }

  tryCloseAndSaveEditing(this: this): boolean {
    const benefitModifyCardRefKeys = Object.keys(this.benefitModifyCardRefs)
    for (var i = 0; i < benefitModifyCardRefKeys.length; i++) {
      const benefitModifyCardRef = this.benefitModifyCardRefs[benefitModifyCardRefKeys[i]]
      if (benefitModifyCardRef.formRef.current !== null) {
        if (benefitModifyCardRef.editMode) {
          if (benefitModifyCardRef.formRef.current.refs.form.checkValidity() === false) {
            benefitModifyCardRef.formRef.current.handleSubmit(new Event("submit"))
            return false
          } else {
            benefitModifyCardRef.formRef.current.handleSubmit(new Event("submit"))
            return true
          }
        }
      }
    }
    return true
  }

  resetEditing() {
    const benefitModifyCardRefKeys = Object.keys(this.benefitModifyCardRefs)
    for (var i = 0; i < benefitModifyCardRefKeys.length; i++) {
      const benefitModifyCardRef = this.benefitModifyCardRefs[benefitModifyCardRefKeys[i]]
      if (!benefitModifyCardRef.isOriginal || benefitModifyCardRef.isModified) benefitModifyCardRef.resetCard()
    }
  }

  revert() {
    const editableBenefitStore = clone(this.props.benefitStore!)

    this.setState({
      editableBenefitStore: editableBenefitStore,
      edited: false,
    })
    this.resetEditing()
    this.registerModificationAutorun(this.props.benefitStore!.benefits, editableBenefitStore!.benefits)
  }

  render() {
    const {userSessionStore, t} = this.props
    const {editableBenefitStore, venue} = this.state

    if (!this.state.venue) return null

    const venueBenefitsActive = _.orderBy(
      editableBenefitStore!.benefits.filter(
        (benefit: IBenefit) => getSnapshot(benefit).restaurantId === venue!.id && !benefit.isHidden),
      ["sortScore", "editedAt"],
      ["desc", "desc"],
    )

    const venueBenefitsInactive = _.orderBy(
      editableBenefitStore!.benefits.filter(
        (benefit: IBenefit) => getSnapshot(benefit).restaurantId === venue!.id && benefit.isHidden),
      ["editedAt"],
      ["desc"],
    )

    let revertModalRef: any
    let saveModalRef: any
    let backModalRef: any
    let alcoholMarketingModalRef: any

    const activeMemberStatusesAndSubscriptions = 
    this.props.memberStore?.statuses
    .filter(status => status.visible && !status.restaurantId.isHidden)


    return (
      <div>
        <Prompt
          when={this.state.showNavigationConfirmation && this.state.edited}
          message={t("navigationConfirmDialogMessage")}
        />
        <ModalBox getRef={(ref: any) => revertModalRef = ref.current}
                  title={t("benefitModifyScreen.modals.revertConfirm.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("benefitModifyScreen.modals.revertConfirm.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("benefitModifyScreen.modals.revertConfirm.cancelButton")}
                          </Button>
                          <Button variant="primary" onClick={action}>
                            {t("benefitModifyScreen.modals.revertConfirm.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <ModalBox getRef={(ref: any) => saveModalRef = ref.current}
                  title={t("benefitModifyScreen.modals.saveConfirm.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("benefitModifyScreen.modals.saveConfirm.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("benefitModifyScreen.modals.saveConfirm.cancelButton")}
                          </Button>
                          {/*
                          // @ts-ignore */}
                          <Button variant="hentovihrea" onClick={action}>
                            {t("benefitModifyScreen.modals.saveConfirm.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <ModalBox getRef={(ref: any) => backModalRef = ref.current}
     
                  title={t("benefitModifyScreen.modals.backConfirm.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("benefitModifyScreen.modals.backConfirm.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("benefitModifyScreen.modals.backConfirm.cancelButton")}
                          </Button>
                          {/*
                          // @ts-ignore */}
                          <Button variant="primary" onClick={action}>
                            {t("benefitModifyScreen.modals.backConfirm.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

<ModalBox getRef={(ref: any) => alcoholMarketingModalRef = ref.current}
                  title={<div>
                    {t("alcoholMarketing.title")}
                    <div className="font-size-normal mt-3 text-secondary">

{t("alcoholMarketing.description")}
</div>
                  </div>}
                  hideCloseButton={true}
                  titleStyle={{textAlign: 'center', width: '100%'}}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body style={{
                          textAlign: 'center',
                          paddingLeft: 30,
                          paddingRight: 30,
                        }}>
                          <p className="p-0 m-0 mb-3 font-size-medium font-weight-bold text-primary">{t("alcoholMarketing.summary.title")}</p>

                          <ul>
                          {t("alcoholMarketing.summary.content").split('\n').map((text: any, index) => 
        <li className="p-0 m-0 font-size-small text-primary">{text}</li>
                            
      )}
                          </ul>

                          <div className="font-size-normal mt-3 text-secondary">

{t("alcoholMarketing.note")}
</div>
              
                          </Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("back")}
                          </Button>
                          {/*
                          // @ts-ignore */}
                          <Button variant="primary" onClick={() => {
                            window.open('https://valvira.fi/en/alcohol/serving-and-retail-sales', '_blank')
                          }}>
                            {t("alcoholMarketing.website")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <AlertMessage source={BENEFITMODIFY_SCREEN_ERROR}/>
        <Container>
          <Row>
            <Col className="col-4 p-0">
              <Button
                onClick={
                  () => {
                    if (this.state.edited) {
                      backModalRef.show(
                        () => {
                          this.setState({
                              showNavigationConfirmation: false,
                            }, () => this.props.history.push(BenefitsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields())),
                          )
                        },
                      )
                    } else {
                      this.props.history.push(BenefitsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
                    }
                  }
                }
                variant="secondary"><FontAwesomeIcon className="mr-2"
                                                           icon={["fal", "chevrons-left"]}/>{t("benefitModifyScreen.backButton")}
              </Button>
            </Col>
            <Col className="col-8 p-0 text-right">
              <Button
                disabled={!this.state.edited}
                className="mr-2" onClick={
                () => {
                  revertModalRef.show(() => {
                    this.revert()
                  })
                }
              }
                variant="outline-secondary"><FontAwesomeIcon className="mr-2"
                                                             icon={["fal", "trash-undo"]}/>{t("benefitModifyScreen.revertButton")}
              </Button>

              <Button
                disabled={!this.state.edited}
                className="" onClick={() => {
                saveModalRef.show(() => {
                  this.saveModifications()
                })
              }}
                // @ts-ignore
                variant="hentovihrea"><FontAwesomeIcon className="mr-2"
                                                       icon={["fal", "cloud-arrow-up"]}/>{t("benefitModifyScreen.saveButton")}
              </Button>
            </Col>
          </Row>
        </Container>
        <div className="text-center mt-5">
          <h2 className="p-0 m-0 text-primary font-weight-bold">{venue!.name}</h2>
          <p className="p-0 m-0 mb-2 font-size-medium">{t("benefitModifyScreen.slogan")}</p>

          {t("alcoholMarketing.disclaimer").split('\n').map((text: any, index) => 

          <p className="p-0 m-0 font-size-normal text-secondary">{text}
          {index === 1 && <p className=" m-0 font-size-normal" onClick={() => alcoholMarketingModalRef.show()} style={{
            display: 'inline-block',
            paddingLeft: 5,
            cursor: 'pointer'
          }}><span className='font-weight-bold'>{`(${t('readMoreHere')})`}</span></p>}
          </p>

            )}
      
       
       
        </div>
        <Container className="m-0 p-0 mt-3">
          <Row>
            <Col className="col-7">
              <h4
                className="mt-3 text-primary">{t("benefitModifyScreen.activeBenefitsTitle")} ({venueBenefitsActive.length})</h4>
            </Col>

            <Col className="col-5 align-items-center d-flex justify-content-end">
              <Button variant={"success"} onClick={() => {
                // do not allow adding if there are unsaved benefits
                if (!this.tryCloseAndSaveEditing()) return

                const venueId = this.state.venue!.id
                const newBenefit = Benefit.create({
                  id: NEW_BENEFIT_ID_PREFIX + this.state.editableBenefitStore!.benefits.length,
                  kind: BenefitKind.ONE_TIME,
                  rules: {
                    offset: "5 hours",
                    interval: BenefitInterval.ONE_TIME,
                    activation: 300,
                  },
                  format: BenefitFormat.OFFER,
                  restaurantId: venueId,
                  sortIndex: 9999999,
                  createdAt: moment.utc().toISOString(),
                  editedAt: moment.utc().toISOString(),
                  isHidden: false,
                  style: {
                    icon: {
                      name: "star",
                      style: "solid",
                    },
                  },
                  content: {
                    text: {
                      en: "",
                      fi: "",
                    },
                  },
                })

                this.state.editableBenefitStore!.addBenefit(newBenefit)
              }}>
                <FontAwesomeIcon className="mr-2" icon={["fal", "star"]}/>{t("benefitModifyScreen.addButton")}
              </Button>
            </Col>
          </Row>
        </Container>
        <div className="mt-3">
          {venueBenefitsActive.length > 0 ?
            venueBenefitsActive!.map((benefit: IBenefit) => {
              if (!this.benefitModifyCardRefs[benefit.id]) this.benefitModifyCardRefs[benefit.id] = React.createRef()

              return (
                <BenefitModifyCard
                  onBenefitModify={this.onBenefitModify.bind(this)}
                  onEditModeToggle={this.tryCloseAndSaveEditing.bind(this)}
                  getRef={(ref: any) => this.benefitModifyCardRefs[benefit.id] = ref.current}
                  key={benefit.id}
                  isNew={benefit.id.startsWith(NEW_BENEFIT_ID_PREFIX)}
                  benefit={benefit}
                  activeMemberStatusesAndSubscriptions={activeMemberStatusesAndSubscriptions}
                  originalBenefit={resolveIdentifier(Benefit, this.props.benefitStore!.benefits, benefit.id)}
                />
              )
            }) : <p className="font-size-medium">{t("benefitModifyScreen.noActiveBenefits")}</p>}
        </div>

        <h4
          className="mt-5 text-primary">{t("benefitModifyScreen.inactiveBenefitsTitle")} ({venueBenefitsInactive.length})</h4>
        <div className="mt-3">
          {venueBenefitsInactive.length > 0 ?
            venueBenefitsInactive!.map((benefit: IBenefit) => {
              if (!this.benefitModifyCardRefs[benefit.id]) this.benefitModifyCardRefs[benefit.id] = React.createRef()

              return (
                <BenefitModifyCard
                  onBenefitModify={this.onBenefitModify.bind(this)}
                  onEditModeToggle={this.tryCloseAndSaveEditing.bind(this)}
                  getRef={(ref: any) => this.benefitModifyCardRefs[benefit.id] = ref.current}
                  key={benefit.id}
                  isNew={benefit.id.startsWith(NEW_BENEFIT_ID_PREFIX)}
                  benefit={benefit}
                  activeMemberStatusesAndSubscriptions={activeMemberStatusesAndSubscriptions}
                  originalBenefit={resolveIdentifier(Benefit, this.props.benefitStore!.benefits, benefit.id)}
                />
              )
            }) : <p className="font-size-medium">{t("benefitModifyScreen.noInactiveBenefits")}</p>}
        </div>
      </div>
    )
  }
}

export default withTranslation()(BenefitsModifyScreen)
