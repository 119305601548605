import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import { getSnapshot } from "mobx-state-tree"
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import Card from 'react-bootstrap/Card'
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { withTranslation, WithTranslation } from "react-i18next"
import { MerchantAdminApi } from "../Api"
import AlertMessage from "../components/AlertMessage"
import EditOrganizationNameForm from "../components/EditOrganizationNameForm"
import { SuccessMessage } from "../components/SuccessMessage"
import { CreateVenueLocation, ModifyVenueLocation } from "../Locations"
import { IUserSessionStore } from "../models/UserSessionStore"
import { IVenue } from "../models/Venue"

export interface ManageVenuesScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  history?: any
  userSessionStore?: IUserSessionStore
}

const UPDATE_ORGANIZATION_NAME_ERROR = "UpdateOrganizationNameError"

const ManageVenuesScreen = (props: ManageVenuesScreenProps) => {
  const [organizationNameEditable, setOrganizationNameEditable] = useState(false)
  const [organizationNameUpdated, setOrganizationNameUpdated] = useState(false)
  const [selectedVenue, setSelectedVenue] = useState<IVenue | undefined>(undefined)

  const {t, userSessionStore, history} = props

  const buttonVariant = (isVenueHidden: boolean, isVenueSelected: boolean) => {
    if (isVenueSelected)
      return 'primary'
    else 
      return 'outline-primary'
  }

  const updateOrganizationName = async (newName: string) => {
    try {
      const newOrganizations = [{
        "id": userSessionStore!.currentOrganization!.id,
        "name": newName
      }]
      const response: any = await props.api!.createOrganization(newOrganizations, UPDATE_ORGANIZATION_NAME_ERROR)

      userSessionStore?.updateOrganization(response.organizations[0])
      setOrganizationNameUpdated(true)
    } catch {
    }
  }


  return (
    <Container>
      <SuccessMessage show={organizationNameUpdated} message={t("manageVenuesScreen.updateOrganizationNameSuccess")}/>
      <AlertMessage source={UPDATE_ORGANIZATION_NAME_ERROR}/>
      <Row className="row no-gutters mt-4">
        <Col className="col-lg-3">
        </Col>
        <Col className="col-lg-6">

          {organizationNameEditable ?
            <EditOrganizationNameForm 
              name={userSessionStore!.currentOrganization!.name || ""} 
              onSubmit={(organizationName) => {
                updateOrganizationName(organizationName)
                setOrganizationNameEditable(false)
              }}/>
            :
            <>
              <h5 className="text-primary d-inline">{userSessionStore!.currentOrganization!.name} </h5>
              <Button className="pt-0" variant="link" onClick={() => setOrganizationNameEditable(true)}>
                <FontAwesomeIcon className="text-muted" icon={["fal", "pen-to-square"]}/>
              </Button>
            </>
            
          }
        </Col>
        <Col className="col-lg-3">
          <button
            onClick={() => history.push(CreateVenueLocation.toUrl({...userSessionStore!.selectedBranch!.urlFields()}))}
            className="btn-block btn btn-primary font-weight-bold">
              {props.t("manageVenuesScreen.addVenue")}
          </button>

        </Col>
      </Row>

      <Row className="justify-content-center mt-4">
        <Col className="col-lg-6">
        <Card border="stroke" style={{ width: '100%' }}>
          <Card.Header>{t("manageVenuesScreen.chooseVenue")}</Card.Header>
          <Card.Body>
            {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) =>
              <Button 
                className={venue.isHidden && venue.id !== selectedVenue?.id ? 'bg-disabled-button' : ''}
                variant={buttonVariant(venue.isHidden, venue.id === selectedVenue?.id)}
                key={venue.id}
                onClick={() => {
                  setSelectedVenue(venue)
                  history.push(ModifyVenueLocation.toUrl({...userSessionStore!.selectedBranch!.urlFields(), venueId: venue.id}))
                }} 
                block>
                {venue.name}
                {venue.isHidden &&
                  <FontAwesomeIcon className="ml-2" icon={["fal", "eye-slash"]}/>
                }
                
              </Button>
            )}
          </Card.Body>
        </Card>
        </Col>
        
      </Row>

    </Container>
  )
}

export default withTranslation()(inject("userSessionStore", "api")(observer(ManageVenuesScreen)))