import React, { useCallback, useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from 'react-bootstrap/Modal'
import Cropper from 'react-easy-crop'
import { withTranslation, WithTranslation } from "react-i18next"
import getCroppedImg from './cropImage'
import "./ImageUploadModal.scss"


export interface ImageUploadModalProps extends WithTranslation {
  imageURL: string
  show: boolean
  onCroppedImage: (croppedImage: string) => void
  onCancel: () => void
  onSave: () => void
  aspectRatio?: number
  cropShape?: "rect" | "round"
}

const ImageUploadModal = (props: ImageUploadModalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const [rotation, setRotation] = useState(0)
  const {t, imageURL, onCancel, onSave, show, onCroppedImage, aspectRatio, cropShape} = props


  const handleCrop = useCallback(async (imgURL, croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(
        imgURL,
        croppedAreaPixels,
        rotation
      )
      onCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [rotation])
  

  return (
    <Modal dialogClassName="imageUploadModal" show={show} centered onHide={onCancel}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body style={{height: '80vh'}}>
        <Cropper
          image={imageURL}
          crop={crop}
          zoom={zoom}
          showGrid={false}
          cropShape={cropShape || 'rect'}
          aspect={aspectRatio || 1}
          style={{
            containerStyle: {height: '100%', width: '100%'},
          }}
          onCropChange={setCrop}
          onCropComplete={(croppedArea, croppedAreaPixels) => {
            handleCrop(imageURL, croppedAreaPixels)
          }}
          onZoomChange={setZoom}
        />
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onCancel}>
          {t("manageVenuesScreen.cancel")}
        </Button>
        <Button variant="primary" onClick={onSave}>
          {t("manageVenuesScreen.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default withTranslation()(ImageUploadModal)
