import { useEffect, useState } from "react";
import { FETCH_MEMBERS_SORTING } from "../../../Api";
import { useStores } from "../../../hooks/UseStores";
import { IMember } from "../../../models/Member";

export const useNewMembersFetch = () => {
    const { memberStore, userSessionStore } = useStores();
  
    const [newMembers, setNewMembers] = useState<IMember[]>([]);
    const [loading, setLoading] = useState(true);
    const [lastFetchedBranchId, setLastFetchedBranchId] = useState<string | null>(null);
  
  
    useEffect(() => {
      const currentBranchId = userSessionStore.selectedBranch?.id;
  
      if (currentBranchId && currentBranchId !== lastFetchedBranchId) {
        const fetchNewMembers = async () => {
          setLoading(true);
          await memberStore.fetchMembers({
            sorting: `-${FETCH_MEMBERS_SORTING.JOINED}`,
            limit: 30,
          });
          setNewMembers(memberStore.members.filter((member: IMember) => Boolean(member.venueMemberships?.length)).slice(0, 11));
          setLoading(false);
          setLastFetchedBranchId(currentBranchId);
        };
  
        fetchNewMembers();
      }
    }, [memberStore, userSessionStore.selectedBranch?.id, lastFetchedBranchId]);
  
    return { newMembers, loading }
  }