import React, { CSSProperties } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { colors } from "../../theme/colors";

const cardBorderRadius = 5;

interface MemberDemographyProps {
  data?: {
    genders: { [key: string]: string };
    age_ranges: { [key: string]: string };
  } | null;
}

const MemberDemography: React.FC<MemberDemographyProps> = ({ data }) => {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const keyValueStyles: CSSProperties = {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: 'white',
    marginBottom: '10px'
  };

  const dotStyle: CSSProperties = {
    margin: '0 8px',
    fontSize: 16,
  };

  const columnStyle: CSSProperties = {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px 0',
  };

  const dividerStyle: CSSProperties = {
    width: 1,
    backgroundColor: colors.stroke,
    height: '100%',
  };

  return (
    <Card style={{
      borderRadius: cardBorderRadius,
      width: 370,
      maxWidth: 370,
      backgroundColor: colors.background,
      border: `1px solid ${colors.stroke}`
    }}>
      <Card.Header as="h5" style={{
        backgroundColor: colors.boxFill,
        textAlign: 'center',
      }}>{t("homeScreen.memberDemography")}</Card.Header>
      <div style={{
        borderRadius: cardBorderRadius,
        display: 'flex',
        borderTop: `1px solid ${colors.stroke}`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        height: 220,
      }}>
        <div style={columnStyle}>
          <h3 style={{ color: 'white', fontSize: '16px', marginBottom: '15px', textAlign: 'center' }}>{t("homeScreen.gender")}</h3>
          {Object.entries(data.genders).map(([key, value]) => (
            <div key={key} style={keyValueStyles}>
              <span>{t(`homeScreen.genders.${key}`)}</span>
              <span style={dotStyle}>·</span>
              <span>{value}</span>
            </div>
          ))}
        </div>
        <div style={dividerStyle} />
        <div style={columnStyle}>
          <h3 style={{ color: 'white', fontSize: '16px', marginBottom: '15px', textAlign: 'center' }}>{t("homeScreen.age")}</h3>
          {Object.entries(data.age_ranges).map(([key, value]) => (
            <div key={key} style={keyValueStyles}>
              <span>{key}</span>
              <span style={dotStyle}>·</span>
              <span>{value}</span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default MemberDemography;