import {Instance, types} from "mobx-state-tree"


export const CountryConfig = types.model("CountryConfig")
  .props({
    id: types.identifier,
    cities: types.array(types.string),
  })

export type ICountryConfig = Instance<typeof CountryConfig>
