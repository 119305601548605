import {destroy, flow, getEnv, Instance, types} from "mobx-state-tree"
import {Benefit, IBenefit} from "./Benefit"
import {ApiResult} from "../ApiResult";

export const BenefitStore = types.model("BenefitStore")
  .props({
    benefits: types.optional(types.array(Benefit), []),
  }).actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {
      const response: ApiResult = yield getEnv(self).api.fetchBenefits(undefined, requestSource)
      if (response.modified) {
        self.benefits.replace(response.payload.data)
      }
      return response.etag
    }),
    resetData() {
      self.benefits.clear()
    },
    addBenefit(benefit: IBenefit) {
      self.benefits.push(benefit)
    },
    removeBenefit(benefit: IBenefit) {
      destroy(benefit)
    }
  }))

export type IBenefitStore = Instance<typeof BenefitStore>
