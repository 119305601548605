import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import React from "react"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Modal from "react-bootstrap/esm/Modal"
import Row from "react-bootstrap/Row"
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, match } from "react-router"
import { MerchantAdminApi } from "../Api"
import AlertMessage from "../components/AlertMessage"
import { MemebershipsSummaryCard } from "../components/MemberStatusSummaryCard"
import { MembersLocation, MemberStatusModifyLocation, MemberStatusSubscriptionModifyLocation } from "../Locations"
import { IMemberStore, VENUE_STATUS_TYPE } from "../models/MemberStore"
import { IRootStore } from "../models/RootStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import {Form as BootstrapForm} from "react-bootstrap"
import { StatusSubscriptionSummaryCard } from "../components/MemberStatusSubscriptionsCard"
import { SUBSCRIPTION_STATE } from "./CreateUpdateMemberStatusSubscriptionScreen/CreateUpdateMemberStatusSubscriptionScreen"

const MANAGE_MEMEBER_CARDS_SCREEN_ERROR = "manageMemberCardsScreen"

interface matchParam {
  id?: string
}


export interface ManageMemberStatusSubscriptionsScreenProps extends RouteComponentProps, WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  memberStore?: IMemberStore
  rootStore?: IRootStore
  match: match<matchParam>
}

export const STATUS_TYPE = {
  PUBLIC: 'PURCHASE',
  PRIVATE: 'PRIVATE'
}


const ManageMembersStatusSubscriptionsScreen = (props: ManageMemberStatusSubscriptionsScreenProps) => {
  const {userSessionStore, history, t} = props
  const {match: {params: {id}}} = props
  // TODO: Fix to return the data correctly from the URL statusType should return statusType, shouldnt come as id
  const statusType = id
  const [showAddCardModal, setShowAddCardModal] = useState(false);

  const getVenueIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')

    if (urlVars.length >= 7 && urlVars[7] === 'venue') {
      return urlVars[8]
    } else {
      return ''
    }
  }

  


  const venueId = getVenueIDFromURL()
  const selectedVenue = userSessionStore?.currentOrganization?.venues.find(venue => venue.id === venueId)
  const statusSubscriptionsReports = props.memberStore?.statusSubscriptionsReports

  const statusSubscriptions = props.memberStore?.venueStatusSubscriptions(selectedVenue)
  const activeMemberStatuses: any[] = 
  statusSubscriptions?.filter(status => status.subscriptionState === SUBSCRIPTION_STATE.ACTIVE)
    .filter(status => status.payments.type === "RECURRING") || []
  const inactiveMemberStatuses = 
  statusSubscriptions?.filter(status => status.subscriptionState === SUBSCRIPTION_STATE.INACTIVE)
    .filter(status => status.payments.type === "RECURRING") || []
  const archivedMemberStatuses = 
  statusSubscriptions?.filter(status => status.subscriptionState === SUBSCRIPTION_STATE.ARCHIVED)
    .filter(status => status.payments.type === "RECURRING") || []


  
  const navToCardModifyScreen = (statusSubscriptionType: string, statusSubscriptionId: string) => history.push(MemberStatusSubscriptionModifyLocation.toUrl({...selectedVenue?.urlFields(), statusSubscriptionId: statusSubscriptionId, statusSubscriptionType: statusSubscriptionType}))

  const pageDescriptionText = statusType === STATUS_TYPE.PUBLIC ? t("manageMembersStatusSubscriptionsScreen.description") : t("manageMembersStatusSubscriptionsScreen.description")

  const onAddNewSubscription = () => statusType === STATUS_TYPE.PUBLIC ? navToCardModifyScreen(VENUE_STATUS_TYPE.SOLD, "NEW__") : navToCardModifyScreen(VENUE_STATUS_TYPE.GIVEN, "NEW__")

  const getSubscriptionReport = (statusSubscriptionId: string) => {
    return statusSubscriptionsReports?.find(report => report.venueStatusId === statusSubscriptionId)
  }

  return (
    <div>

      <AlertMessage source={MANAGE_MEMEBER_CARDS_SCREEN_ERROR}/>
      <Container>
        <Row>
          <Col className="col-4 p-0">
            <Button
              onClick={() => history.push(MembersLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))}
              variant="secondary">
                <FontAwesomeIcon className="mr-2" icon={["fal", "chevrons-left"]}/>
                {t("manageMembersCardScreen.back")}
            </Button>
          </Col>
          <Col className="col-8 p-0 text-right">
          </Col>
        </Row>
      </Container>
      <div className="text-center mt-5 mb-5">
        <h2 className="p-0 m-0 text-primary font-weight-bold mb-2">{selectedVenue?.name}</h2>
        {pageDescriptionText.split('\n').map(text => 
          <p className="p-0 m-0 font-size-medium">{text}</p>
        )}
        
      </div>

      <Container>
        <Row>
          <Col className="col-6">
            <h4 className="mt-3 text-primary">{t("manageMembersStatusSubscriptionsScreen.activeSubscriptions")} ({activeMemberStatuses.length})</h4>
            <p style={{margin: 0}}>
                {t("manageMembersStatusSubscriptionsScreen.activeSubscriptionsDescription")}
            </p>
          </Col>
          <Col className="col-6 d-flex align-items-center justify-content-end">
            <Button
            className="mr-2"
              onClick={onAddNewSubscription}>
                <FontAwesomeIcon className="mr-2" icon={["fal", "arrows-rotate"]}/>
                {t("manageMembersStatusSubscriptionsScreen.addNewSubscription")}
            </Button>


          </Col>
        </Row>
      </Container>

      <div className="mt-3">
        {activeMemberStatuses?.length > 0 
          ? activeMemberStatuses.map((memberStatus) => <StatusSubscriptionSummaryCard report={getSubscriptionReport(memberStatus.id)} key={memberStatus.id} onEdit={() => navToCardModifyScreen(memberStatus.type, memberStatus.id)} memberStatus={memberStatus}/>)
          : <p className="font-size-medium">{t("manageMembersStatusSubscriptionsScreen.noActiveSubscriptions")}</p>}
      </div>

      {inactiveMemberStatuses?.length > 0  &&
        <>
          <h4
            className="mt-5 text-primary">{t("manageMembersStatusSubscriptionsScreen.inactiveSubscriptions")} ({inactiveMemberStatuses?.length})</h4>
          <p>
            {t("manageMembersStatusSubscriptionsScreen.inactiveSubscriptionsDescription")}
          </p>
          <div className="mt-3">
          {inactiveMemberStatuses?.length > 0 
              ? inactiveMemberStatuses?.map((memberStatus) => <StatusSubscriptionSummaryCard report={getSubscriptionReport(memberStatus.id)} key={memberStatus.id} onEdit={() => navToCardModifyScreen(memberStatus.type, memberStatus.id)} memberStatus={memberStatus}/>)
              : <p className="font-size-medium">{t("manageMembersStatusSubscriptionsScreen.noInactiveSubscriptions")}</p>}
          </div>
        </>

      }

      {archivedMemberStatuses?.length > 0 &&
        <>
          <h4
            className="mt-5 text-primary">{t("manageMembersStatusSubscriptionsScreen.archivedSubscriptions")} ({archivedMemberStatuses?.length})</h4>
          <p>
            {t("manageMembersStatusSubscriptionsScreen.archivedSubscriptionsDescription")}
          </p>
          <div className="mt-3">
          {archivedMemberStatuses?.length > 0 
              ? archivedMemberStatuses?.map((memberStatus) => <StatusSubscriptionSummaryCard report={getSubscriptionReport(memberStatus.id)} key={memberStatus.id} onEdit={() => navToCardModifyScreen(memberStatus.type, memberStatus.id)} memberStatus={memberStatus}/>)
              : <p className="font-size-medium">{t("manageMembersStatusSubscriptionsScreen.noArchivedSubscriptions")}</p>}
          </div>
        </>
      }
    </div>
  )
}

export default withTranslation()(inject("userSessionStore", "memberStore", "rootStore", "api")(observer(ManageMembersStatusSubscriptionsScreen)))
