import log from "loglevelnext"
import { inject, observer } from "mobx-react"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from 'react-bootstrap/Modal'
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MerchantAdminApi } from "../Api"
import { colors } from "../theme/colors"

export interface UpgradeToPremiumModalProps extends RouteComponentProps, WithTranslation {
  api?: MerchantAdminApi
  showModal?: boolean
  setShowModal?: any
  userSessionStore?: any
}

const UPGRADE_TO_PREMIUM_MODAL_ERROR = 'UPGRADE_TO_PREMIUM_MODAL_ERROR'

// @ts-ignore
const UpgradeToPremiumModal = (props: UpgradeToPremiumModalProps) => {
    const {t, showModal, setShowModal, api, userSessionStore} = props
    const premiumPerks = [
        t("messagesScreen.upgradePopUp.premiumPerk1"),
        t("messagesScreen.upgradePopUp.premiumPerk2"),
        t("messagesScreen.upgradePopUp.premiumPerk3"),
    ]
    const [showThanksModal, setShowThanksModal] = useState(false);

  const onSubmitPushNotification = async (e: any) => {
    e.preventDefault()

    const currentVenue = userSessionStore!.selectedBranch
    const text = `Venue: ${currentVenue.name} clicked on the Upgrade to Premium`

    try {
      
      const requestSuccess = await api!.sendSimplePushNotificationRequest(
        text,
        undefined,
        UPGRADE_TO_PREMIUM_MODAL_ERROR,
      )

      if (requestSuccess) {
        setShowThanksModal(true)
        return
      }

    } finally {

    }

    log.debug("UpgradeToPremiumModal: Error while sending push notification")
  }

  // @ts-ignore
  const ThanksForChoosingUpgradeModal = () => {
    return <Modal show={showModal} centered onHide={() => {
      setShowModal(false)
    }}>
      <Modal.Header closeButton>
      <Modal.Title className="text-center" style={{width: '100%'}}>
          <div>
            {t("messagesScreen.thanksForUpgradePopUp.title").split('\n').map((text: any) => 
              <div className="font-weight-bold">{text}</div>
            )}
          </div>
          
      </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center" style={{width: '100%'}}>
          {t("messagesScreen.thanksForUpgradePopUp.contentTitle").split('\n').map((text: any) => 
            <div className="font-weight-bold">{text}</div>
          )}

          <br/>

          {t("messagesScreen.thanksForUpgradePopUp.contentDescription").split('\n').map((text: any) => 
            <div>{text}</div>
          )}

          <div className="mt-3 mb-3">info@cluby.com</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
          <Button variant="primary pl-4 pr-4" onClick={() => {
              setShowModal(false)
          }}>
              <h4  style={{
              color: colors.black}}>{t("messagesScreen.thanksForUpgradePopUp.button")}</h4>
          </Button>
      </Modal.Footer>
    </Modal>
  }

  const UpgradeModal = () => <Modal show={showModal} centered onHide={() => {
    setShowModal(false)
  }}>
      <Modal.Header closeButton>
      <Modal.Title className="text-center">
          <div className="font-weight-bold">{t("messagesScreen.upgradePopUp.title")}</div>
          <div className="mt-2" style={{ fontSize: 20}}>{t("messagesScreen.upgradePopUp.titleDescription")}</div>
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className="text-primary font-weight-bold text-center">{t("messagesScreen.upgradePopUp.bodyTitle")}</div>

          <ul style={{
            color: colors.secondaryText
          }} className="mt-3">
              {premiumPerks.map(perkName => 
                  <li key={perkName}>{perkName}</li>
              )}
          </ul>

          <h4 className="text-primary text-center font-weight-bold">{t("messagesScreen.upgradePopUp.upgradeToPremium")}</h4>
          <h5 className="text-primary text-center font-weight-bold">{t("messagesScreen.upgradePopUp.startingFrom")}</h5>
          <h1 className="text-primary text-center font-weight-bold mb-0">99 €</h1>
          <div className="text-primary text-center font-weight-bold">{t("messagesScreen.upgradePopUp.aMonth")}</div>

          <div className="text-center mt-2 font-italic" style={{width: '65%', margin: '0px auto', fontSize: 14, color: colors.secondaryText}}>{t("messagesScreen.upgradePopUp.monthlyPriceBasedOn")}</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
          <Button variant="primary pl-4 pr-4" onClick={(e) => {
            onSubmitPushNotification(e)
          }}>
            <h4 style={{
              color: colors.black
            }}>{t("messagesScreen.upgradePopUp.upgrade")}</h4>
          </Button>
      </Modal.Footer>
  </Modal>

  return showThanksModal ? <ThanksForChoosingUpgradeModal/> : <UpgradeModal/>
}

export default withRouter(withTranslation()(inject("api", "userSessionStore")(observer(UpgradeToPremiumModal))))