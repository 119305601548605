import {RouteComponentProps, withRouter} from "react-router"
import {IUserSessionStore} from "../models/UserSessionStore"
import {MerchantAdminApi} from "../Api"
import {inject} from "mobx-react"
import React from "react"
import {LoginLocation} from "../Locations"
import {WithTranslation, withTranslation} from "react-i18next";

export interface LogoutScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  api?: MerchantAdminApi
}

@(withRouter as any)
@inject("userSessionStore")
@inject("api")
class LogoutScreen extends React.Component<LogoutScreenProps> {

  async componentDidMount(): Promise<void> {
    //var logoutSuccess = false
    try {

      const logoutResponse = await this.props.api!.logout()
      if (!logoutResponse.ok) {
        return
      }

      //logoutSuccess = true
    } finally {
      // FIXME: handle errors..
      this.props.userSessionStore!.logOut()
      this.props.history.push(LoginLocation.toUrl())
    }
  }

  render() {
    return (
      <div>
        <h1>{this.props.t("loggingOut")}</h1>
      </div>
    )
  }
}
export default withTranslation()(LogoutScreen)
