import {MerchantAdminApi} from "../Api"
import {inject, observer} from "mobx-react"
import React from "react"
import {IUserSessionStore} from "../models/UserSessionStore"
import {withTranslation, WithTranslation} from "react-i18next"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IOrganization} from "../models/Organization"
import {BenefitsLocation, CreateOrganizationLocation, MembersLocation} from "../Locations"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

export interface ManagePremiumScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  history?: any
}

@inject("api")
@inject("userSessionStore")
@observer
class ManagePremiumScreen extends React.Component<ManagePremiumScreenProps, {isConfirmModalShwon: boolean, selectedOrganizationToUpdatePremium: IOrganization | undefined}> {
  constructor(props: Readonly<ManagePremiumScreenProps>) {
    super(props)

    this.state = {
      isConfirmModalShwon: false,
      selectedOrganizationToUpdatePremium: undefined
    }

  }

  onClick(organization: IOrganization) {
    this.props.userSessionStore!.setSelectedOrganization(organization)
    this.props.history.push(MembersLocation.toUrl(this.props.userSessionStore!.selectedBranch!.urlFields()))
  }

  onModalCancel = () => {
    this.setState({
      isConfirmModalShwon: false,
      selectedOrganizationToUpdatePremium: undefined
    })
  }

  render() {
    const {t, userSessionStore} = this.props
    return (
      <div className="container">
        <Modal dialogClassName="imageUploadModal" show={this.state.isConfirmModalShwon} centered onHide={this.onModalCancel}>
          <Modal.Header closeButton>
            <div style={{textAlign: 'center', fontWeight: 'bold', width: '100%'}}>
              {this.state.selectedOrganizationToUpdatePremium?.name}
            </div>
          </Modal.Header>
          <Modal.Body>  
            {this.state.selectedOrganizationToUpdatePremium &&
              <div style={{textAlign: 'center'}}>
                {this.state.selectedOrganizationToUpdatePremium.isPremium ? t("superAdminScreen.managePremium.disablePremium") : t("superAdminScreen.managePremium.enablePremium")}?       
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.onModalCancel}>
              {t("manageVenuesScreen.cancel")}
            </Button>
            <Button variant="primary" onClick={async () => {
              if (this.state.selectedOrganizationToUpdatePremium?.isPremium === undefined) {
                return
              }
              const isPremium = this.state.selectedOrganizationToUpdatePremium.isPremium === undefined ? false : this.state.selectedOrganizationToUpdatePremium.isPremium
              const organizationId = this.state.selectedOrganizationToUpdatePremium.id
              const response: any = await this.props.api!.updateIsPremium(organizationId, !isPremium)

              if (response.status === 200) {
                this.state.selectedOrganizationToUpdatePremium.setIsPremium(!isPremium)
                this.onModalCancel()
              }
            }}>
              {t("manageVenuesScreen.save")}
            </Button>
          </Modal.Footer>
        </Modal>
        <>
        <Row className="row no-gutters justify-content-end">
          <Col className="col-lg-12 mt-4">            
          </Col>
        </Row>
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-md-5">
            <div className="card">
              <h5 className="card-header">{t("superAdminScreen.addNewOrganization.managePremium")}</h5>
              <div className="card-body">
                {userSessionStore!.organizations.map((organization) => {
                  let isCurrent = userSessionStore!.currentOrganization!.id === organization.id
                  return <div style={{height: 50}}>
                    <Button className="btn-block" variant={"outline-primary"} style={{opacity: 1}} disabled onClick={() => console.log('wee')} key={organization.name}>
                      {organization.name}
                    </Button>
                    <div style={{top: -33, left: 370, position: 'relative'}}>
                      <Form>
                        <Form.Check 
                          type="switch"
                          id={`premium-switch-${organization.id}`}
                          label={organization.isPremium ? t("superAdminScreen.managePremium.premium") : t("superAdminScreen.managePremium.free")}
                          checked={organization.isPremium}
                          onChange={async (e) => {
                            this.setState({
                              isConfirmModalShwon: true,
                              selectedOrganizationToUpdatePremium: organization
                            })
                          }}
                        />
                      </Form>
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
        </>
      </div>
    )
  }
}

export default withTranslation()(ManagePremiumScreen)
