import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import React from "react"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import AlertMessage from "../components/AlertMessage"
import EventTable from "../components/EventTable"
import { SummaryHeader, SummaryItem } from "../components/SummaryHeader"
import i18n from "../i18n"
import { EventLocation, EventsModifyLocation } from "../Locations"
import { IEventReportStore } from "../models/EventReportStore"
import { IEventStore } from "../models/EventStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import { IVenue } from "../models/Venue"
import { trackEvent } from "../tracking"
import trackingConstants from "../tracking/trackingConstants"
import { getEventsOrganizationSummary } from "./HomeScreen/utils"

const EVENT_SCREEN_ERROR = "eventScreen"

export interface EventsScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  eventStore?: IEventStore
  eventReportStore?: IEventReportStore
}

@(withRouter as any)
@inject("userSessionStore")
@inject("eventReportStore")
@inject("eventStore")
@observer
class EventsScreen extends React.Component<EventsScreenProps> {

  componentDidMount(): void {
    trackEvent(trackingConstants.screens.events, {selectedBranch: this.props.userSessionStore!.selectedBranch!.name})
  }

  forwardToAddScreen(venue: IVenue) {
    this.props.history.push(EventsModifyLocation.toUrl(venue.urlFields()))
  }

  forwardToModifyScreen(venue: IVenue, eventId: string, createBasedOn: boolean = false) {
    this.props.history.push(EventsModifyLocation.toUrl({...venue.urlFields(), eventId: eventId, createBasedOn: createBasedOn}))
  }

   navigateToEvent = ({venue, eventId}: {venue: IVenue, eventId: string}) =>{
    this.props.history.push(EventLocation.toUrl({...venue.urlFields(), eventId: eventId}))
   }

  render() {
    const {userSessionStore, eventStore, eventReportStore, t} = this.props
    const headerButtons = userSessionStore!.isOrganizationContext() ?
      <Dropdown
        /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
        onClick={(e: any) => e.stopPropagation()}
      >
        <Dropdown.Toggle variant="success" id="dropdown-edit-events-org">
          <FontAwesomeIcon className="mr-2" icon={["fal", "calendar-plus"]}/>{t("eventScreen.addButton")}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
            return (
              <Dropdown.Item key={venue.id}
                             onClick={this.forwardToAddScreen.bind(this, venue)}
              >{venue.name}</Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
      :
      <Button
        onClick={this.forwardToAddScreen.bind(this, userSessionStore!.selectedBranch! as IVenue)}
        variant="success"><FontAwesomeIcon className="mr-2"
                                           icon={["fal", "calendar-plus"]}/>{t("eventScreen.addButton")}
      </Button>

    const summaryLocale = i18n.languages[0] === "fi" ? "fi-FI" : "en-US"


    

    // eslint-disable-next-line
    const summaries: Array<SummaryItem> = (!userSessionStore!.isOrganizationContext() && eventStore!.summaries.has(userSessionStore!.selectedBranch!.id)) || userSessionStore!.isOrganizationContext() && eventStore!.summaries.size > 0 ?
      [
        {
          title: t("eventScreen.summary.ticketsSoldHeader"),
          value: userSessionStore!.isOrganizationContext() ? getEventsOrganizationSummary({eventStore: eventStore!, field: "visitors"}) : eventStore!.summaries.get(userSessionStore!.selectedBranch!.id)!.visitors.toString(),
          footer: t("eventScreen.summary.ticketsSoldFooter"),
        },
        {
          title: t("eventScreen.summary.ticketsSoldRecentlyHeader"),
          value: userSessionStore!.isOrganizationContext() ? getEventsOrganizationSummary({eventStore: eventStore!, field: "visitors30Days"}) : eventStore!.summaries.get(userSessionStore!.selectedBranch!.id)!.visitors30Days.toString(),
          footer: t("eventScreen.summary.ticketsSoldRecentlyFooter"),
        },
        {
          title: t("eventScreen.summary.revenueHeader"),
          value: ((userSessionStore!.isOrganizationContext() ? getEventsOrganizationSummary({eventStore: eventStore!, field: "revenue"}) : eventStore!.summaries.get(userSessionStore!.selectedBranch!.id)!.revenue) / 100).toLocaleString(summaryLocale, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          footer: t("eventScreen.summary.revenueFooter"),
        },
        {
          title: t("eventScreen.summary.revenueRecentlyHeader"),
          value: ((userSessionStore!.isOrganizationContext() ? getEventsOrganizationSummary({eventStore: eventStore!, field: "revenue30Days"}) : eventStore!.summaries.get(userSessionStore!.selectedBranch!.id)!.revenue30Days) / 100).toLocaleString(summaryLocale, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          footer: t("eventScreen.summary.revenueRecentlyFooter"),
        },
      ] : []

    return (
      <div>
        <AlertMessage source={EVENT_SCREEN_ERROR}/>
        {eventStore!.events.length === 0 &&
        <div className="text-center mt-5 mb-5">
          <h2
            className="p-0 m-0 text-primary font-weight-bold">{userSessionStore!.isOrganizationContext() ? t("eventScreen.header.title") : userSessionStore!.selectedBranch!.name}</h2>
          <p className="p-0 m-0 font-size-medium">{t("eventScreen.header.promotionText")}</p>
        </div>
        }
        <SummaryHeader summaries={summaries} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} buttons={headerButtons}/>
        <EventTable data={eventReportStore!} forwardToModify={this.forwardToModifyScreen.bind(this)}
                    eventStore={eventStore!}
                    navigateToEvent={this.navigateToEvent}
                    isOrganizationContext={userSessionStore!.isOrganizationContext()}/>
      </div>
    )
  }
}

export default withTranslation()(EventsScreen)
