import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, Form, Formik } from 'formik'
import { inject, observer } from "mobx-react"
import React, { useState } from "react"
import { Container, Form as BootstrapForm, InputGroup } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Card from 'react-bootstrap/Card'
import Col from "react-bootstrap/Col"
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Modal from 'react-bootstrap/Modal'
import Row from "react-bootstrap/Row"
import { withTranslation, WithTranslation } from "react-i18next"
import * as Yup from 'yup'
import { MerchantAdminApi } from "../Api"
import AlertMessage from "../components/AlertMessage"
import ImageUploadModal from "../components/ImageUploadModal"
import { ProductsLocation } from "../Locations"
import { IProductStore } from "../models/ProductStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import {fullCurrencyUnitsToCents, urlToFile} from "../utils"
import Skeleton from 'react-loading-skeleton'
import { colors } from "../theme/colors"
import { vatFinnishPercentage } from "../utils/constants"

const MODIFY_PRODUCT_ERROR = "modifyProductError"
const CREATE_UPDATE_PRODUCT_ERROR = "createUpdateProductError"
export interface ProductModifyScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  productStore?: IProductStore
  history?: any
}

const CreateUpdateProductScreen = (props: ProductModifyScreenProps) => {
  const [croppedProductPhoto, setCroppedProductPhoto] = useState('')
  const [showHideProductModal, setShowHideProductModal] = useState(false);
  const [showUploadProductPhotoModal, setShowUploadProductPhotoModal] = useState(false);
  const [showGoBackModal, setShowGoBackModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);

  const getProductIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    return urlVars[urlVars.length - 1]
  }

  const selectedProductId = getProductIDFromURL()
  const selectedProduct = props.productStore?.products?.find(product => product.id === selectedProductId)
  const [uploadedImage, setUploadedImage] = useState(selectedProduct?.images?.mainImage || "");
  
  const validationSchema = Yup.object().shape({
    id: Yup.string().required(),
    restaurantId: Yup.string().required(),
    title: Yup.object({ 
      en: Yup.string().required('Required'),
      fi: Yup.string().required('Required')
    }),
    description: Yup.object({ 
      en: Yup.string().required('Required'),
      fi: Yup.string().required('Required')
    }),
    validity: Yup.object({ 
      duration: Yup.number().required('Required'),
      durationUnit: Yup.string().required('Required')
    }),
    rules: Yup.object({
      type: Yup.string().required('Required'),
      quantity: Yup.number().required('Required'),
      activation: Yup.number().required('Required')
    }),
    pricing: Yup.object({
      amount: Yup.number().required('Required'),
      currency: Yup.string().required('Required'),
      vatPercentage: Yup.number().required('Required'),
    }),
    images: Yup.object({           
      mainImage: Yup.string(),
    }),
    visible: Yup.boolean().required('Required')
  });

  const {t, userSessionStore, productStore, history, api} = props

  const getVenueIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    if (urlVars.length >= 5 && urlVars[3] === 'venue') {
      return urlVars[4]
    } else {
      return ''
    }
  }

  

  const venueId = getVenueIDFromURL()

  const initValues = selectedProduct ? {
    id: selectedProduct.id,
    restaurantId: selectedProduct.restaurantId.id,
    description: { 
      "en": selectedProduct.description.en,
      "fi": selectedProduct.description.fi
    },
    validity: { 
      duration: selectedProduct.validity.duration,
      durationUnit: selectedProduct.validity.durationUnit 
    },
    rules: {
      type: selectedProduct.rules.type,
      quantity: selectedProduct.rules.quantity,
      activation: selectedProduct.rules.activation
    },
    title: {
      en: selectedProduct.title.en,
      fi: selectedProduct.title.fi
    },
    pricing: {
      amount: selectedProduct?.pricing?.amount ? selectedProduct.pricing.amount / 100 : 0,
      currency: selectedProduct.pricing.currency,
      vatPercentage: selectedProduct.pricing.vatPercentage
    },
    images: {           
      mainImage: selectedProduct.images?.mainImage || ''
    },
    visible: selectedProduct.visible
  } :{
    id: "NEW__",
    restaurantId: venueId,
    description: { 
      "en": "",
      "fi": ""
    },
    validity: { 
      duration: 730,
      durationUnit: "day" 
    },
    rules: {
      type: "PRODUCT",
      quantity: 10,
      activation: 300
    },
    title: {
      en: "",
      fi: ""
    },
    pricing: {
      amount: 1,
      currency: "eur",
      vatPercentage: vatFinnishPercentage
    },
    images: {           
      mainImage: ""
    },
    visible: true
  }

  const handleSaveAndPublish = async (products: any[], newProduct: boolean) => {
    const response = await api!.postProducts(products, undefined, CREATE_UPDATE_PRODUCT_ERROR)
    if (response && response.products.length > 0) {
      if (newProduct) {
        productStore?.addProduct(response.products[0])
      } else {
        productStore?.updateProduct(response.products[0])
      }
      history.push(ProductsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }
  }

  const getObjChangedValues = (values: any, initialValues: any) => {
    return Object
      .entries(values)
      .reduce((acc: any, [key, value]) => {
        const hasChanged = initialValues[key] !== value

        if (hasChanged) {
          acc[key] = value
        }

        return acc
      }, {})
  }

  const IMAGE_UPLOAD_STATUS = {
    UPLOADING: "UPLOADING",
    ERROR: "ERROR"
  }

  const handleUploadImage = async (imageBlob: any) => {
    setUploadedImage(IMAGE_UPLOAD_STATUS.UPLOADING)
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await api!.uploadImage('venue/product/main', imageFile)
    if (imageURL) {
      setUploadedImage(imageURL)
    } else {
      setUploadedImage(IMAGE_UPLOAD_STATUS.ERROR)
    }
  }

  const MainImage = () => {
    if (uploadedImage === "") {
      return <Card className="croppedCover" style={{ border: `1px solid ${colors.stroke}`, backgroundColor: 'red'}}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton height={198} width={330}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return <Card className="croppedCover">
        <div className="alert alert-danger mt-5" role="alert">
          {t('manageVenuesScreen.imageUploadFailed')}
        </div>
      </Card>
    } else {
      return <img className="croppedCover" src={uploadedImage} alt="logo"/>
    }
  }

  const onBackToProducts = (isFormEdited: boolean) => {
    if (isFormEdited)
      setShowGoBackModal(true)
    else
      history.push(ProductsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
  }
  
  return (
    <Container>
      <AlertMessage source={MODIFY_PRODUCT_ERROR} />

      <Modal show={showGoBackModal} centered onHide={() => {
        setShowGoBackModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{t("productModifyScreen.goBackPopup.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("productModifyScreen.goBackPopup.description")}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => {
            setShowGoBackModal(false)
          }}>
            {t("productModifyScreen.goBackPopup.cancel")}
          </Button>
          <Button variant="primary" onClick={() => {
            history.push(ProductsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
          }}>
            {t("productModifyScreen.goBackPopup.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          // const updatedFields = getObjChangedValues(values, initValues)
          const productData = {
            ...values,
            images: {           
              mainImage: uploadedImage || null
            },
            pricing: {
              ...values.pricing,
              amount: fullCurrencyUnitsToCents(values.pricing.amount)
            }
          }
          // if (!selectedVenue) venueData.country = "Finland"
          const isNewProduct = values.id === 'NEW__'
          handleSaveAndPublish([productData], isNewProduct)
        }}
      >
        {({ errors, touched, values, dirty, handleChange, resetForm, setFieldValue }) => {
          console.log("FORM", errors)
          return (
          <Form>
            <Modal show={showRevertModal} centered onHide={() => {
              setShowRevertModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.revertChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.revertChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  resetForm()
                  // setUploadedImage(selectedVenue?.images?.logoImage || "")
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>
            <Row>
              <Col md={4}>
                <Button 
                  variant="secondary" 
                  className="font-weight-bold" 
                  onClick={() => onBackToProducts(dirty)}
                  >
                  <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
                    {t("productModifyScreen.backToProducts")}
                </Button>
              </Col>
              <Col md={8}>
                <div className="float-right">
                  <Button variant="outline-primary font-weight-bold" className="mr-3" onClick={() => setShowRevertModal(true)}>
                    <FontAwesomeIcon className="mr-2" icon={["fas", "trash-alt"]}/>
                      {t("manageVenuesScreen.revert")}
                  </Button>
                  <Button variant="outline-success greenInput" type="submit" className="font-weight-bold">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                    {t("manageVenuesScreen.saveAndPublish")}
                  </Button>
                </div>
                
              </Col>
            
            </Row>

            <Container>
              <Row className="justify-content-md-center mt-5 mb-4">
                {t(`productModifyScreen.formDescription`)}
              </Row>
            </Container>

            <Container className="formContainer">
              <Row>
                <Col md={6}>
                  <Field 
                    name="title.en"
                    render={() => (
                      <FormGroup controlId="title.en">
                        <FormLabel 
                          className={`textInputLabel ${(errors.title?.en && touched.title?.en) && (errors.title?.fi && touched.title?.fi) ? 'text-danger' : ''}`}>
                            {t(`productModifyScreen.productName`)}
                        </FormLabel>
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="pr-2">EN</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl 
                            className="textInput" 
                            placeholder={t(`productModifyScreen.productNameExampleEN`)} 
                            type={'text'} 
                            isInvalid={errors.title?.en && touched.title?.en ? true : false} 
                            value={values.title.en} 
                            onChange={handleChange} />
                        </InputGroup>
                        
                        {errors.title?.en && touched.title?.en? (
                          <small className="text-danger">{errors.title?.en}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field 
                    name="title.fi"
                    render={() => (
                      <FormGroup controlId="title.fi">
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="pr-3">FI</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl 
                            className="textInput" 
                            placeholder={t(`productModifyScreen.productNameExampleFI`)} 
                            type={'text'} 
                            isInvalid={errors.title?.fi && touched.title?.fi ? true : false} 
                            value={values.title?.fi} 
                            onChange={handleChange} />
                        </InputGroup>
                        
                        {errors.title?.fi && touched.title?.fi ? (
                          <small className="text-danger">{errors.title?.fi}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field 
                    name="description.en"
                    render={() => (
                      <FormGroup controlId="description.en">
                        <FormLabel className={`textInputLabel ${errors.description?.en && touched.description?.en ? 'text-danger' : ''}`}>{t(`productModifyScreen.descriptionInEN`)}</FormLabel>
                        <FormControl 
                          className="textInput" 
                          as='textarea' 
                          rows={4} 
                          isInvalid={errors.description?.en && touched.description?.en ? true : false} 
                          placeholder={t(`productModifyScreen.descriptionInEN`)}
                          value={values.description?.en} 
                          onChange={handleChange}/>
                        {errors.description?.en && touched.description?.en ? (
                          <small className="text-danger">{errors.description?.en}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field 
                    name="description.fi"
                    render={() => (
                      <FormGroup controlId="description.fi">
                        <FormLabel className={`textInputLabel ${errors.description?.fi && touched.description?.fi ? 'text-danger' : ''}`}>{t(`productModifyScreen.descriptionInFI`)}</FormLabel>
                        <FormControl 
                          className="textInput" 
                          as='textarea' 
                          rows={4} 
                          isInvalid={errors.description?.fi && touched.description?.fi ? true : false} 
                          placeholder={t(`productModifyScreen.descriptionInFI`)}
                          value={values.description?.fi} 
                          onChange={handleChange}/>
                        {errors.description?.fi && touched.description?.fi ? (
                          <small className="text-danger">{errors.description?.fi}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <ImageUploadModal 
                show={showUploadProductPhotoModal}
                imageURL={values.images.mainImage}
                aspectRatio={1500 / 900}
                cropShape="rect"
                onCroppedImage={(croppedImage) => {
                  setCroppedProductPhoto(croppedImage)
                }}
                onCancel={() => {
                  setShowUploadProductPhotoModal(false)
                }}
                onSave={() => {
                  setShowUploadProductPhotoModal(false)
                  handleUploadImage(croppedProductPhoto)
                }}/>

              <Row>
                <Col md={6}>
                  <Field 
                    name="images.mainImage"
                    render={() => (
                      <FormGroup controlId="images.mainImage" >
                        <h6 className={`mt-3 mb-0 ${errors.images?.mainImage && touched.images?.mainImage ? 'text-danger' : ''}`}>{t(`productModifyScreen.productPhoto`)}</h6>
                        <div className="mb-2">
                          <small>{t('productModifyScreen.productPhotoDescription')}</small>
                        </div>

                        <label className="btn btn-secondary">
                            {t(`manageVenuesScreen.uploadPhoto`)}
                            <input 
                              type="file" 
                              id="images.mainImage"
                              hidden
                              name="file"
                              onChange={(event) => {
                                  if (event.currentTarget && event.currentTarget.files) {
                                    setFieldValue("images.mainImage", URL.createObjectURL(event.currentTarget.files[0]));
                                    setShowUploadProductPhotoModal(true)
                                  }
                              }} />
                        </label>
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row style={{height: 200}}>
                <Col md={4}>
                  <MainImage/>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                <h6 className={`mt-3 mb-0 ${errors.images?.mainImage && touched.images?.mainImage ? 'text-danger' : ''}`}>{t(`productModifyScreen.numberOfProducts`)}</h6>
                    <div className="mb-2">
                      <small>{t('productModifyScreen.numberOfProductsDescription')}</small>
                    </div>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <Field 
                    name="rules.quantity"
                    render={() => (
                      <FormGroup controlId="rules.quantity" >
                        <FormControl className="textInput text-center primaryInput" type={'number'} isInvalid={errors.rules?.quantity && touched.rules?.quantity ? true : false} value={values.rules?.quantity || 0} onChange={(e) => setFieldValue('rules.quantity', parseInt(e.target.value).toFixed())} />
                        {errors.rules?.quantity && touched.rules?.quantity ? (
                          <small className="text-danger">{errors.rules?.quantity}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col md={4}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                      <h6 className={`mt-3 mb-0 ${errors.pricing?.amount && touched.pricing?.amount ? 'text-danger' : ''}`}>{t(`productModifyScreen.totalPrice`)}</h6>
                          <div className="mb-2">
                            <small>{t('productModifyScreen.totalPriceDescription')}</small>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                      <Field 
                        name="pricing.amount"
                        render={() => (
                          <FormGroup controlId="pricing.amount" >
                            <InputGroup>
                              <FormControl className="textInput text-center primaryInput" type={'number'} isInvalid={errors.pricing?.amount && touched.pricing?.amount ? true : false} value={values.pricing?.amount} onChange={handleChange} />
                                {errors.pricing?.amount && touched.pricing?.amount ? (
                                  <small className="text-danger">{errors.pricing?.amount}</small>
                                ) : null}
                              <InputGroup.Append>
                                <InputGroup.Text className="primaryInputGroupText">€</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            
                          </FormGroup>
                        )}
                      />
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col md={3}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                      <h6 className={`invisible mt-3 mb-0 ${errors.pricing?.vatPercentage && touched.pricing?.vatPercentage ? 'text-danger' : ''}`}>{t(`productModifyScreen.vat`)}</h6>
                          <div className="mb-2">
                            <small>{t('productModifyScreen.vat')}</small>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={8}>
                        <Field 
                          name="pricing.vatPercentage"
                          render={() => (
                            <FormGroup controlId="pricing.vatPercentage" >
                              <InputGroup>
                                <FormControl className="textInput text-center primaryInput" type={'number'} isInvalid={errors.pricing?.vatPercentage && touched.pricing?.vatPercentage ? true : false} value={values.pricing?.vatPercentage || 0} onChange={handleChange} />
                                {errors.pricing?.vatPercentage && touched.pricing?.vatPercentage ? (
                                  <small className="text-danger">{errors.pricing?.vatPercentage}</small>
                                ) : null}

                                <InputGroup.Append>
                                  <InputGroup.Text className="primaryInputGroupText">%</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </FormGroup>
                          )}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col md={5}>
                  <FormGroup controlId="visible">
                  <BootstrapForm.Check 
                      type="switch"
                      id="visible"
                      className="mt-5"
                      checked={!values.visible}
                      onChange={() => {
                        if (!values.visible) {
                          setFieldValue('visible', true)
                        } else {
                          setShowHideProductModal(true)
                        }
                      }}
                      label={t("productModifyScreen.hideProductTitle")}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                <h6 className={`mb-0 ${errors.validity?.duration && touched.validity?.duration ? 'text-danger' : ''}`}>{t(`productModifyScreen.expiration`)}</h6>
                    <div className="mb-2">
                      <small>{t('productModifyScreen.expirationDescription')}</small>
                    </div> 
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <Field 
                    name="validity.duration"
                    render={() => (
                      <FormGroup controlId="validity.duration">
                        <FormControl className="textInput text-center primaryInput" as='select' isInvalid={errors.validity?.duration && touched.validity?.duration ? true : false} value={values.validity?.duration || 0} onChange={handleChange}>
                          <option value="180">6</option>
                          <option value="365">12</option>
                          <option value="730">24</option>
                        </FormControl>
                        {errors.validity?.duration && touched.validity?.duration ? (
                          <small className="text-danger">{errors.validity?.duration}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Modal show={showHideProductModal} centered onHide={() => {
                  setShowHideProductModal(false)
              }}>
                <Modal.Header closeButton>
                  <Modal.Title>{t("productModifyScreen.hideProductTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("productModifyScreen.hideProductDescription")}</Modal.Body>
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={() => {
                    setShowHideProductModal(false)
                    setFieldValue('visible', true)
                  }}>
                    {t("manageVenuesScreen.cancel")}
                  </Button>
                  <Button variant="primary" onClick={() => {
                    setShowHideProductModal(false)
                    setFieldValue('visible', false)
                  }}>
                    {t("manageVenuesScreen.hide")}
                  </Button>
                </Modal.Footer>
              </Modal>
              
              <Row>
                <Col md={6}>
                  <Button variant="outline-success greenInput mt-4" type="submit" className="font-weight-bold">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                    {t("manageVenuesScreen.saveAndPublish")}
                  </Button>
                </Col>
              </Row>

            </Container>
          </Form>
        )}}
      </Formik>
    </Container>
  )
}

export default withTranslation()(inject("userSessionStore", "api", "productStore")(observer(CreateUpdateProductScreen)))
