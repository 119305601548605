import { Instance, types } from "mobx-state-tree"


export const Invitation = types.model("Invitation")
  .props({
    id: types.identifier,
    email: types.string,
    name: types.string,
    created: types.string,
    expiry: types.maybeNull(types.string),
  }).views(self => ({

  })).actions(self => ({

  }))

export type IInvitation = Instance<typeof Invitation>
