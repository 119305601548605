import log from "loglevelnext"
import { inject, observer } from "mobx-react"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from 'react-bootstrap/Modal'
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MerchantAdminApi } from "../../Api"
import { EmailOctopusApiKeyLocation } from "../../Locations"
import { colors } from "../../theme/colors"

export interface ConnectEmailOctopusModalModalProps extends RouteComponentProps, WithTranslation {
  api?: MerchantAdminApi
  showModal?: boolean
  setShowModal?: any
  userSessionStore?: any
}

const UPGRADE_TO_PREMIUM_MODAL_ERROR = 'UPGRADE_TO_PREMIUM_MODAL_ERROR'

// @ts-ignore
const ConnectEmailOctopusModal = (props: ConnectEmailOctopusModalModalProps) => {
    const {t, showModal, setShowModal, api, userSessionStore} = props
    const premiumPerks = [
        t("emailOctopus.connectModal.feature1"),
        t("emailOctopus.connectModal.feature2"),
        t("emailOctopus.connectModal.feature3"),
    ]
    const [showThanksModal, setShowThanksModal] = useState(false);

  const onSubmitPushNotification = async (e: any) => {
    e.preventDefault()

    const currentVenue = userSessionStore!.selectedBranch
    const text = `Venue: ${currentVenue.name} clicked on the Upgrade to Premium`

    try {
      
      const requestSuccess = await api!.sendSimplePushNotificationRequest(
        text,
        undefined,
        UPGRADE_TO_PREMIUM_MODAL_ERROR,
      )

      if (requestSuccess) {
        setShowThanksModal(true)
        return
      }

    } finally {

    }

    log.debug("UpgradeToPremiumModal: Error while sending push notification")
  }




  return <Modal show={showModal} centered onHide={() => {
    setShowModal(false)
  }}>
      <Modal.Header closeButton>
      <Modal.Title className="text-center">
          <div className="font-weight-bold">{t("emailOctopus.connectModal.title")}</div>
          <div className="mt-3 font-weight-bold" style={{color: colors.secondaryText, fontSize: 18}}>{t("emailOctopus.connectModal.description")}</div>
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className="text-primary font-weight-bold text-center">{t("emailOctopus.connectModal.featuresTitle")}</div>

          <ul className="mt-3 mb-4">
              {premiumPerks.map(perkName => 
                  <li key={perkName}>{perkName}</li>
              )}
          </ul>

          <h4 className="text-primary text-center font-weight-bold">{t("emailOctopus.connectModal.pricing")}</h4>

          <div className="text-center mt-4 font-italic" style={{margin: '0px auto', fontSize: 14}}>{t("emailOctopus.connectModal.recommendation")}</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
          <Button variant="primary" className="pl-4 pr-4" onClick={(e) => {
            props.history.push(EmailOctopusApiKeyLocation.toUrl({...userSessionStore!.selectedBranch!.urlFields()}))
          }}>
            <h4 style={{
              color: colors.black,
            }}>{t("emailOctopus.connectModal.button")}</h4>
          </Button>
      </Modal.Footer>
  </Modal>
}

export default withRouter(withTranslation()(inject("api", "userSessionStore")(observer(ConnectEmailOctopusModal))))