import React, {CSSProperties, ReactNode, useEffect, useRef, useState} from "react"
import Modal from "react-bootstrap/Modal"

type ModalBoxProps = {
  title: string | ReactNode
  renderContent: (action: () => void, cancel: () => void, metadata?: any) => ReactNode
  getRef: any
  hideCloseButton?: boolean
  titleStyle?: CSSProperties
}

export function ModalBox(props: ModalBoxProps) {
  const [show, setShow] = useState(false)
  const [okAction, setOkAction] = useState()
  const [renderMetadata, setRenderMetadata] = useState({})


  const thisRef = useRef(null)

  const handleClose = () => {
    setShow(false)
    // @ts-ignore
    setOkAction(() => {
    })
  }

  const handleOk = () => {
    setShow(false)
    // @ts-ignore
    okAction()
  }

  useEffect(() => {

    function handleShow(action: () => {}, metadata: any = {}) {
      setRenderMetadata(metadata)
      setShow(true)
      // @ts-ignore
      setOkAction(() => action)
    }

    // @ts-ignore
    thisRef.current = {show: handleShow}
    props.getRef(thisRef)
  }, [okAction, props])

  return (
    <>
      <Modal show={show}
             onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        {/*
        // @ts-ignore */}
        <Modal.Header closeButton={props.hideCloseButton ? false : true}>
          <Modal.Title style={{
           ...props.titleStyle
          }}>{props.title}</Modal.Title>
        </Modal.Header>
        {props.renderContent(handleOk, handleClose, renderMetadata)}
      </Modal>
    </>
  )
}
