import {destroy, flow, getEnv, Instance, types} from "mobx-state-tree"
import {Product, IProduct} from "./Product"
import {ApiResult} from "../ApiResult";

export const ProductStore = types.model("ProductStore")
  .props({
    products: types.optional(types.array(Product), []),


  })
  .views(self => ({
    nonRewardProducts() {
      return self.products.filter((product: IProduct) => !product.rules?.isReward)
    }
  }))
  .actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {
      const response: ApiResult = yield getEnv(self).api.fetchProducts(undefined, requestSource)


      if (response.modified) {
        self.products.replace(response?.payload?.data)
      }
      return response.etag
    }),
    resetData() {
      self.products.clear()
    },
    addProduct(product: IProduct) {
      self.products.push(product)
    },
    updateProduct(product: any) {
      const productToUpdateIndex = self.products.findIndex(p => p.id === product.id)
      self.products[productToUpdateIndex] = product
    },
    removeProduct(product: IProduct) {
      destroy(product)
    }
  }))

export type IProductStore = Instance<typeof ProductStore>
