import {Instance, types} from "mobx-state-tree"


export const Country = types.model("Country")
  .props({
    id: types.identifier,
    name: types.string,
    is_active: types.boolean,
    code: types.string,
  })

export type ICountry = Instance<typeof Country>
