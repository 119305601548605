import { useFormik } from 'formik'
import { inject, observer } from "mobx-react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import * as Yup from 'yup'
import { MerchantAdminApi } from "../../Api"
import AlertMessage from "../../components/AlertMessage"
import { InvitationsTable } from "../../components/InvitationsTable"
import { IBenefitReportStore } from "../../models/BenefitReportStore"
import { IInvitationStore } from "../../models/InvitationsStore"
import { IRootStore } from "../../models/RootStore"
import { IUserSessionStore } from "../../models/UserSessionStore"
const BENEFIT_SCREEN_ERROR = "benefitScreen"

export interface InvitationsScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  benefitReportStore?: IBenefitReportStore
  rootStore?: IRootStore
  api?: MerchantAdminApi
  invitationsStore?: IInvitationStore
  memberStore?: any
}


const InvitationsScreen = (props: Readonly<InvitationsScreenProps>) => {
    const {userSessionStore, invitationsStore, t} = props

    const invitationValidationSchema = Yup.object().shape({
        email: Yup.string().email().required(),
        fullName: Yup.string().required('Name is required'),
      });
    
      const formik = useFormik({
        initialValues: {
          email: '',
          fullName: '',
        },
        validationSchema: invitationValidationSchema,
        onSubmit: async (values) => {
            const response = await props.api!.sendInvitation(values.email, values.fullName, undefined, 'SEND_INVITATION_ERROR')
    
            if (response.result === 'OK') {
                invitationsStore?.addInvitation({
                    id: 'NEW',
                    email: values.email,
                    name: values.fullName,
                    created: '',
                    expiry: 'yes',

                })
            }

        }
      });


      const invitedUsers = invitationsStore?.invited || []
      const users = invitationsStore?.users || []
      const sortedUsers = [...users].sort(function(a, b) {
        return new Date(a.created).getTime() - new Date(b.created).getTime();
      });

      const invitationsData = [...sortedUsers, ...invitedUsers]

    return (
      <div>
        <AlertMessage source={BENEFIT_SCREEN_ERROR}/>

        <div className="text-primary pb-2 pt-3 font-weight-bold" style={{fontSize: 20}}>
            {t("invitationsScreen.invitePartnerHubUsers")}
        </div>

        <Card className="pt-3 pb-4  m-0" style={{borderWidth: 1, borderColor: "#ddd"}}>
        <Row className="form-group" style={{margin: 0}}>
            <Col md={12} className="text-left">
                <form onSubmit={formik.handleSubmit}>
                    <Row className="form-group"  style={{margin: 0}}>
                        <Col md={5}>
                            <label className={formik.errors.email && formik.touched.email ? "text-primary" : ""} htmlFor="email">
                                Email
                            </label>
                            <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className={`form-control ${
                                formik.errors.email && formik.touched.email ? 'is-invalid' : ''
                            }`}
                            />
                            {formik.errors.email && formik.touched.email && (
                            <div style={{
                                position: 'absolute',
                                bottom: - 20,
                                left: 16,
                            }} className="invalid-feedback">{formik.errors.email}</div>
                            )}
                        </Col>
                        <Col md={5}>
                            <label htmlFor="fullName" className={formik.errors.fullName && formik.touched.fullName ? "text-primary" : ""}>Full Name</label>
                            <input
                            id="fullName"
                            name="fullName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.fullName}
                            className={`form-control ${
                                formik.errors.fullName && formik.touched.fullName ? 'is-invalid' : ''
                            }`}
                            />
                            {formik.errors.fullName && formik.touched.fullName && (
                            <div style={{
                                position: 'absolute',
                                bottom: - 20,
                                left: 16,
                            }}  className="invalid-feedback">{formik.errors.fullName}</div>
                            )}
                        </Col>
                        <Col md={2} className="d-flex align-items-end" style={{
                            justifyContent: 'center'
                        }}>
                            <button type="submit" disabled={formik.isSubmitting} className="btn btn-primary">
                            {t("invitationsScreen.sendInvite")}
                            </button>
                        </Col>
                    </Row>
                </form>
            </Col>
        </Row>

        </Card>

        <div className="text-primary pb-2 pt-4 mt-3 font-weight-bold" style={{fontSize: 20}}>
            {t("invitationsScreen.managePartnerHubUsers")}
        </div>
     
        <InvitationsTable data={invitationsData}
                      isOrganizationContext={userSessionStore!.isOrganizationContext()}/>

        <div style={{height: 50}}/>

      </div>
    )
}

export default withRouter(withTranslation()(inject("userSessionStore", "benefitReportStore", "api", "memberStore", "invitationsStore")(observer(InvitationsScreen))))