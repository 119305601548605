import {MerchantAdminApi} from "../Api"
import {inject, observer} from "mobx-react"
import React from "react"
import {TextInput, ValidationForm} from "react-bootstrap4-form-validation"
import {IUserSessionStore} from "../models/UserSessionStore"
import {RouteComponentProps} from "react-router"
import AlertMessage from "../components/AlertMessage"
import {WithTranslation, withTranslation} from "react-i18next"
import NavLink from "react-bootstrap/NavLink";
import {LoginLocation} from "../Locations";


type SignupScreenState = {
  buttonPressed: boolean
  signUpCompleted: boolean
  formData: { [index: string]: string }
}

interface MatchParams {
  invitationKey: string,
}

export interface SignupScreenProps extends RouteComponentProps<MatchParams>, WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
}

const SIGNUP_SCREEN_ERROR = "signupScreen"


@inject("api")
@inject("userSessionStore")
@observer
class SignupScreen extends React.Component<SignupScreenProps, SignupScreenState> {
  redirectTimeout: any

  constructor(props: SignupScreenProps) {
    super(props)
    this.state = {
      buttonPressed: false,
      signUpCompleted: false,
      formData: {
        invitationKey: "",
        passphrase: "",
        passphraseConfirm: "",
      },
    }
  }

  componentDidMount(): void {
    const invitationKey = this.props.match.params["invitationKey"]
    this.setState({
      formData: {
        invitationKey: invitationKey,
        passphrase: "",
        passphraseConfirm: "",
      },
    })
  }

  async onFormSubmit(e: any, formData: any, inputs: any) {
    e.preventDefault()
    this.setState({buttonPressed: true})

    const {formData: {passphrase, invitationKey}} = this.state
    const {api} = this.props

    try {

      const signupResponse: any = await api!.signup(passphrase, invitationKey, SIGNUP_SCREEN_ERROR)
      if (!signupResponse.ok) {
        return
      }

      this.setState({signUpCompleted: true})
      // @ts-ignore

      const userEmail = signupResponse.data.data.email

      this.redirectTimeout = setTimeout(async () => {
        const loginSuccessful = await api!.login(userEmail, passphrase)
        if (!loginSuccessful) {
          return
        }

        await api!.refreshLoginStatus(true)
      }, 2000)

    } finally {
      this.setState({buttonPressed: false})
    }
  }

  handleChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [target.name]: target.value,
      },
    }))
  }

  matchPassword = (value: any) => {
    return value && value === this.state.formData.passphrase
  }

  renderCompletedMessage() {
    const {t} = this.props
    return (
      <>
        <div className="alert alert-success" role="alert">
          <h5>{t("accountCreated")}</h5>
          {t("loggingIn")}
        </div>
      </>
    )
  }

  renderForm() {
    const {t} = this.props
    const {buttonPressed, formData: {passphrase, passphraseConfirm}} = this.state
    return (
      <>
        <AlertMessage source={SIGNUP_SCREEN_ERROR} />

        <ValidationForm onSubmit={this.onFormSubmit.bind(this)}>
          <fieldset disabled={buttonPressed}>
            <div className="form-group row">
              <div className="col-md-12 text-left">
                <label htmlFor="passphrase">{t("createPassphrase")}</label>
                <TextInput name="passphrase" id="passphrase" required
                           minLength="8"
                           type="password"
                           autoComplete="off"
                           value={passphrase}
                           errorMessage={{
                             validator: t("validationErrorPassphrase"),
                             minLength: t("passwordMinLength"),
                             required: t("fieldRequired")
                           }}
                           onChange={this.handleChange}
                           successMessage={t("validationSuccess")}/>
              </div>
              <div className="col-md-12 text-left">
                <label htmlFor="passphraseConfirm">{t("createPassphraseConfirm")}</label>
                <TextInput name="passphraseConfirm" id="passphraseConfirm" required
                           minLength="8"
                           type="password"
                           autoComplete="off"
                           value={passphraseConfirm}
                           validator={this.matchPassword}
                           errorMessage={{
                             required: t("confirmPassPhraseRequired"),
                             validator: t("passphraseNotMatch"),
                             minLength: t("passwordMinLength"),
                           }}
                           onChange={this.handleChange}
                           successMessage={t("validationSuccess")}/>
              </div>
              <div className="col-md-12 text-left mt-4">
                <button ref="loginButton" className="btn-block btn btn-primary">{t("signup")}
                </button>
              </div>
              <div className="pt-2 col-md-12 justify-content-end d-flex">
                <NavLink className="p-0 d-flex" href={LoginLocation.toUrl()}><small>{t("alreadyHaveAccount")}</small></NavLink>
              </div>
            </div>
          </fieldset>
        </ValidationForm>
      </>
    )
  }

  render() {
    const {t} = this.props
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-md-5">
            <div className="card">
              <h4 className="card-header">{t("signup")}</h4>
              <div className="card-body">
                {this.state.signUpCompleted ?
                  this.renderCompletedMessage()
                  :
                  this.renderForm()
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default withTranslation()(SignupScreen)
