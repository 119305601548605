import {Instance, types} from "mobx-state-tree"
import {Venue} from "./Venue"

export const EVENT_PROPERTIES_TICKET_QUOTA = 'ticketQuotaVisitors'

export enum EventStatus {
  NORMAL = "NORMAL",
  HIDDEN = "HIDDEN",
  NORMAL_SOLDOUT = "NORMAL_SOLDOUT",
}

export const EventPricing = types.model("EventPricing")
  .props({
    amount: types.number,
    vat_percentage: types.maybe(types.number)
  })

export type IEventPricing = Instance<typeof EventPricing>

const Images = types.model("Images")
  .props({
    mainImage: types.maybeNull(types.string)
  })


export const Event = types
  .model("Event")
  .props({
    id: types.identifier,
    status: types.enumeration("status", Object["values"](EventStatus)),
    restaurantId: types.reference(Venue),
    paymentEnabled: types.boolean,
    title: types.string,
    description: types.string,
    startTime: types.string,
    endTime: types.string,
    images: types.maybeNull(Images),
    pricing: types.map(EventPricing),
    properties: types.optional(types.map(types.maybeNull(types.union(types.string, types.integer))), {}),
    created_at: types.string,
    edited_at: types.string,
    _computed_publicUrls: types.map(types.string)
  }).views(self => ({
    isFree() {
      return (self.status === EventStatus.NORMAL && self.paymentEnabled && self.pricing.size === 0)
    },
    isSoldOut() {
      return (self.status === EventStatus.NORMAL_SOLDOUT)
    },
    isPaymentDisabled() {
      return (self.status === EventStatus.NORMAL && !self.paymentEnabled)
    },
    isHidden() {
      return self.status === EventStatus.HIDDEN
    },
    hasTicketQuota() {
      return self.properties.get(EVENT_PROPERTIES_TICKET_QUOTA) !== undefined
    },
    getTicketQuota() {
      return self.properties.get(EVENT_PROPERTIES_TICKET_QUOTA)
    }
  })).actions(self => ({
    removePricingObjects(keys: Array<string>) {
      keys.map((item) => {
        self.pricing.delete(item)
        return true
      })
      if (self.pricing.size === 0) {
        // @ts-ignore
        self.setTicketQuota(undefined)
      }
    },
    setIsFree() {
      self.status = EventStatus.NORMAL
      self.paymentEnabled = true
      self.pricing.replace({})
      // @ts-ignore
      self.setTicketQuota(undefined)
    },
    setIsSoldOut() {
      self.status = EventStatus.NORMAL_SOLDOUT
    },
    setIsPaymentDisabled() {
      self.status = EventStatus.NORMAL
      self.paymentEnabled = false
    },
    setIsHidden() {
      self.status = EventStatus.HIDDEN
    },
    setStatus(status: EventStatus) {
      self.status = status
    },
    setPaymentEnabled(paymentEnabled: boolean) {
      self.paymentEnabled = paymentEnabled
    },
    setTitle(title: string) {
      self.title = title
    },
    setDescription(description: string) {
      self.description = description
    },
    setStartTime(startTime: string) {
      self.startTime = startTime
    },
    setEndTime(endTime: string) {
      self.endTime = endTime
    },
    setPricing(pricing: any) {
      self.pricing = pricing
    },
    setProperties(properties: any) {
      self.properties.replace(properties)
    },
    setMainImage(imgURL: string) {
      self.images = {...self.images, mainImage: imgURL}
    },
    setTicketQuota(visitors: number | undefined) {
      if (visitors) {
        self.properties.set(EVENT_PROPERTIES_TICKET_QUOTA, visitors)
      } else {
        self.properties.delete(EVENT_PROPERTIES_TICKET_QUOTA)
      }
    }
  }))

export type IEvent = Instance<typeof Event>
