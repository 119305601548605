import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, Form, Formik } from 'formik'
import { inject, observer } from "mobx-react"
import { ReactNode, useState } from "react"
import { Container, InputGroup } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import Modal from 'react-bootstrap/Modal'
import Row from "react-bootstrap/Row"
import { withTranslation, WithTranslation } from "react-i18next"
import * as Yup from 'yup'
import { MerchantAdminApi } from "../../Api"
import AlertMessage from "../../components/AlertMessage"
import { ManageEmailOctopusApiKeyLocation, MessagesLocation } from "../../Locations"
import { IMemberStore } from "../../models/MemberStore"
import { IMessageStore } from "../../models/MessageStore"
import { IUserSessionStore } from "../../models/UserSessionStore"
import "./styles.scss"
const ADD_VENUE_ERROR = "addVenueError"

export interface EmailOctopusApiKeyScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  messageStore?: IMessageStore
  memberStore?: IMemberStore
  history?: any
}

const SEND_STATUS = {
    DRAFT: 'DRAFT',
    READY: 'READY',
    SENT: 'SENT'
  }

interface InstructionStepProps {
step: number;
title: ReactNode;
description: ReactNode;
}

const InstructionStep: React.FC<InstructionStepProps> = ({ step, title, description }) => (
    <div>
        <div className="text-primary font-weight-bold" style={{fontSize: 18}}>
            {`${step}. ${title}`}
        </div>
        <div className="mt-2 mb-3" style={{fontSize: 16}}>
            {description}
        </div>
    </div>
);

const EmailOctopusApiKeyScreen = (props: EmailOctopusApiKeyScreenProps) => {
  const {t, userSessionStore, api, history, messageStore, memberStore} = props
  
  const [showGoBackModal, setShowGoBackModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const selectedMessage = messageStore?.messages.find(message => message.id === history.location?.state?.messageId)
  const isMessageSent = selectedMessage?.status === SEND_STATUS.SENT

  const navigateToManageEmailOctopus = () => props.history.push(
    ManageEmailOctopusApiKeyLocation.toUrl({...userSessionStore!.selectedBranch!.urlFields()})
  )


  const PLAN_TYPE = {
    FREE: "FREE",
    PRO: "PRO"
  }

  const validationSchema = Yup.object().shape({
    apiKey: Yup.string(),
    plan: Yup.string(),
  });

  const newMessageValues = {
    apiKey: '',
    plan: PLAN_TYPE.FREE
  }

  const initValues = selectedMessage ? {
    apiKey: '',
    plan: PLAN_TYPE.FREE
  } : {
    ...newMessageValues
  }


  const onBackToVenues = (isFormEdited: boolean) => {
    if (isFormEdited)
      setShowGoBackModal(true)
    else
      history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
  }




  return (
    <Container>
      <AlertMessage source={ADD_VENUE_ERROR}/>

      {/* <Modal show={showGoBackModal} centered onHide={() => {
        setShowGoBackModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{t("messagesScreen.goBackPopup.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("messagesScreen.goBackPopup.description")}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => {
            setShowGoBackModal(false)
          }}>
            {t("messagesScreen.goBackPopup.cancel")}
          </Button>
          <Button variant="primary" onClick={() => {
            history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
          }}>
            {t("messagesScreen.goBackPopup.confirm")}
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
            const isProPlan = values.plan === PLAN_TYPE.PRO
            const response = await memberStore!.postEmailOctopusIntegration(values.apiKey, isProPlan)
            memberStore!.addEmailOctopusIntegration(response?.id, response?.integration_id, response?.status, isProPlan)
            if (response?.id) {
              navigateToManageEmailOctopus()
            }
        }}
      >
        {({dirty, errors, touched, values, handleChange, handleSubmit, handleBlur, resetForm, setFieldValue}) => (
          <Form>
            <Modal show={showRevertModal} centered onHide={() => {
              setShowRevertModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.revertChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.revertChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  resetForm()
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showSaveModal} centered onHide={() => {
              setShowSaveModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.saveChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.saveChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowSaveModal(false)
                }}>
                  {t("manageVenuesScreen.saveChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  handleSubmit()
                  setShowSaveModal(false)
                }}>
                  {t("manageVenuesScreen.saveChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Row>
              <Col md={4}>
                <Button
                  variant="outline-primary"
                  className="font-weight-bold"
                  onClick={() => onBackToVenues(dirty)}>
                  <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
                  {t("sendPushNotificationsScreen.backToMessages")}
                </Button>
              </Col>
              <Col md={8}>

              </Col>

            </Row>
            <fieldset disabled={isMessageSent}>
            <h4 className="text-primary text-center mt-4 font-weight-bold">{t("emailOctopus.connectScreen.title")}</h4>

            <p className="mb-4 text-center" style={{width: '65%', margin: '0 auto'}}>{t("emailOctopus.connectScreen.description")}</p>

            <Container className="formContainer">

            <InstructionStep 
                step={1} 
                title={t("emailOctopus.connectScreen.createAccountTitle")} 
                description={
                <>
                    {t("emailOctopus.connectScreen.goTo")}
                    {' '}
                    <a target="_blank" rel="noopener noreferrer" href={'https://www.emailoctopus.com/'}>EmailOctopus</a>
                    {' '}
                    {t("emailOctopus.connectScreen.andCreateAccount")}
                </>
                }
            />
            <InstructionStep 
                step={2} 
                title={t("emailOctopus.connectScreen.createApiKeyTitle")} 
                description={t("emailOctopus.connectScreen.createApiKeyDescription")}
            />
            <InstructionStep 
                step={3} 
                title={t("emailOctopus.connectScreen.enterApiKeyTitle")} 
                description={t("emailOctopus.connectScreen.enterApiKeyDescription")}
            />

            <Row>
                <Col md={8}>
                    <Field 
                    name="apiKey"
                    render={() => (
                        <FormGroup controlId="apiKey">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                            <InputGroup.Text className="pr-2">{t("emailOctopus.connectScreen.apiKey")}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl 
                            className="textInput" 
                            placeholder="e.g. xx012345-xx01-0xx0-x00x-xx0123xx0123"
                            type={'text'} 
                            isInvalid={errors.apiKey && touched.apiKey ? true : false} 
                            value={values.apiKey} 
                            onChange={handleChange} />
                        </InputGroup>
                        
                        {errors.apiKey && touched.apiKey? (
                            <small className="text-danger">{errors.apiKey}</small>
                        ) : null}
                        </FormGroup>
                    )}
                    />
                </Col>
            </Row>

            {/* <div className="text-primary">
                {t("emailOctopus.connectScreen.youAreNowConnected")}
            </div> */}

            <Row>
                <Col md={6}>
                <h6 className={`mb-0 mt-4 ${errors.plan && touched.plan ? 'text-danger' : ''}`}>{t(`emailOctopus.connectScreen.plan`)}</h6>
                <div className="mb-2">
                    <small>{t('emailOctopus.connectScreen.planDescription')}</small>
                </div> 
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <Field 
                    name="plan"
                    render={() => (
                      <FormGroup controlId="plan">
                        <FormControl className="textInput text-center primaryInput" as='select' isInvalid={errors.plan && touched.plan ? true : false} value={values.plan} onChange={handleChange}>
                          <option value={PLAN_TYPE.FREE}>FREE</option>
                          <option value={PLAN_TYPE.PRO}>PRO</option>
                        </FormControl>
                        {errors.plan && touched.plan ? (
                          <small className="text-danger">{errors.plan}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

            <div className="font-weight-bold" style={{fontSize: 14}}>
                {t(`emailOctopus.connectScreen.note`)}
            </div>


            <Button variant="outline-success greenInput mt-3" type="submit" className="font-weight-bold">
            <FontAwesomeIcon className="mr-2" icon={["far", "cloud-upload"]}/>
                {t("manageVenuesScreen.saveAndPublish")}
            </Button>

            </Container>

            <div style={{height: 40}}></div>

           </fieldset>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default withTranslation()(inject("userSessionStore", "api", "messageStore", "memberStore")(observer(EmailOctopusApiKeyScreen)))
