import * as _ from "lodash"
import log from "loglevelnext"
import { applySnapshot, flow, getEnv, getRoot, getSnapshot, Instance, resolveIdentifier, types } from "mobx-state-tree"
import moment from "moment-timezone"
import { FETCH_MEMBERS_ORDERING, FETCH_MEMBERS_SORTING } from "../Api"
import { IMember, IMemberVenueMembership, IMemberVenueStatus, Member } from "./Member"
import { IRootStore } from "./RootStore"
import { IVenue, Venue } from "./Venue"
import { AGE_RANGE_DEMOGRAPHY_ORDER, formatPercentagesForDemographics, GENDER_DEMOGRAPHY_ORDER } from "./MemberStore/utils"

export const VENUE_STATUS_TYPE = {
  SOLD: 'PURCHASE',
  GIVEN: 'PRIVATE'
}
export const VENUE_STATUS_THEME = {
  GOLD: 'DEFAULT_GOLD',
  BLACK: 'DEFAULT_BLACK'
}

export const MEMBER_STATUS_PAYMENT_VALIDITY_TYPE = {
  FIXED: 'FIXED',
  FLEXIBLE: 'FLEXIBLE'
}

export enum FETCH_MEMBERS_COLUMNS {
  STAMP_CARDS_LIST = 'stampCardsList',
  STAMP_CARDS_TOTAL_STAMPS = 'stampCardsTotalStamps',
  STAMP_CARDS_TOTAL_REWARDS = 'stampCardsTotalRewards',
  BENEFITS_ACTIVATIONS_TOTAL = 'benefitsActivationsTotal',
  PURCHASES_TOTAL_AMOUNT = 'purchasesTotalAmount'
}

export const VENUE_STATUS_TYPE_PURCHASE = VENUE_STATUS_TYPE.SOLD
export const VENUE_STATUS_TYPE_PRIVATE = VENUE_STATUS_TYPE.GIVEN
export const VENUE_STATUS_TYPE_REQUEST = "REQUEST"
export const VENUE_STATUS_TYPE_JOIN = "JOIN"

export const VENUE_STATUS_PAYMENT_TYPE_FIXED = "FIXED"
export const VENUE_STATUS_PAYMENT_TYPE_FLEXIBLE = "FLEXIBLE"

export const VENUE_THEME_GOLD = VENUE_STATUS_THEME.GOLD
export const VENUE_THEME_BLACK = VENUE_STATUS_THEME.BLACK


function mergeVenueMemberships(response: any): any[] {
  return response.payload.data.timeline_data.map((item: any) => item.venueMemberships).flat();
}



export const VenueStatusPayments = types.model("VenueStatusPayments")
  .props({
    enabled: types.optional(types.boolean, false),
    price: types.maybe(types.model({amount: types.number, currency: types.string})),
    type: types.maybe(types.string),
    validFrom: types.maybe(types.string),
    validThrough: types.maybe(types.string),
    vatPercentage: types.maybe(types.number),
    duration: types.maybe(types.number),
  })

export const VenueStatusSubscriptionPayments = types.compose(
  "VenueStatusSubscriptionPayments",
  VenueStatusPayments,
  types.model({
    period: types.maybe(types.string),
  })
);

export const VenueStatusSubscriptionReport = types.model("VenueStatusSubscriptionReport")
  .props({
    activeMembers: types.maybe(types.number),
    inactiveMembers: types.maybe(types.number),
    revenueTotal: types.maybe(types.number),
    restaurantId: types.maybe(types.string),
    venueStatusId: types.maybe(types.string),
  })

const Images = types.model("Images")
  .props({
    mainImage: types.maybeNull(types.string)
  })

  const ExternalIdentifierFormat = types.model("ExternalIdentifierFormat", {
    pad: types.maybe(types.string),
    length: types.maybe(types.integer),
    prefix: types.maybe(types.string),
  });
  

export const VenueStatus = types.model("VenueStatus")
  .props({
    id: types.identifier,
    restaurantId: types.reference(Venue),
    payments: VenueStatusPayments,
    type: types.enumeration([VENUE_STATUS_TYPE_PURCHASE, VENUE_STATUS_TYPE_PRIVATE, VENUE_STATUS_TYPE_REQUEST, VENUE_STATUS_TYPE_JOIN]),
    text: types.map(
      types.map(
        types.model({
          title: types.string,
          description: types.maybe(types.string),
          shortDescription: types.string,
        }))),
    style: types.model({
      theme: types.maybe(types.enumeration([VENUE_THEME_GOLD, VENUE_THEME_BLACK])),
    }),
    code: types.maybeNull(types.string),
    images: types.maybeNull(Images),
    flags: types.model({
      grantEntrance: types.maybe(types.boolean),
      grantCloakroom: types.maybe(types.boolean),
      showExternalIdentifier: types.maybe(types.boolean),
      autoGenerateExternalIdentifier: types.maybe(types.boolean),
      externalIdentifierFormat: types.maybe(ExternalIdentifierFormat),
    }),
    visible: types.boolean
  }).views(self => ({
    get localeTitle() {
      // @ts-ignore
      return self.text.get("active").get("en").title + (self.type === VENUE_STATUS_TYPE_PURCHASE ? " " + self.payments.price.amount / 100 + " €": "")
    },
    get localeVenueTitle(): any{
      const userSessionStore = (getRoot(self) as IRootStore).userSessionStore
      // @ts-ignore
      return (userSessionStore.currentOrganization.isSingleVenue() ? "" : self.restaurantId.name + " ") + self.text.get("active").get("en").title + (self.type === VENUE_STATUS_TYPE_PURCHASE ? " " + self.payments.price.amount / 100 + " €": "")
    },
  }))

  export const VenueReferralCode = types.model("VenueReferralCode")
  .props({
    id: types.identifier,
    editedAt: types.maybeNull(types.string),
    code: types.string,
    codeType: types.model({
      type: types.enumeration("CodeType", ["ONE_SIDE"]) 
    }),
    validFrom: types.maybeNull(types.string),
    appliesTo: types.model({
      target_id: types.string,
      target_type: types.enumeration("TargetType", ["STATUS"]) 
    }),
    createdAt: types.string,
    status: types.maybeNull(types.string),
    restaurantId: types.reference(Venue),
    codeState: types.enumeration("CodeState", ["ACTIVE", "INACTIVE"]), 
    limit: types.number,
    validThrough: types.maybeNull(types.string),
    created_at: types.maybeNull(types.string),
    edited_at: types.maybeNull(types.string)
  })

export const VenueStatusSubscription = types.compose(
  "VenueStatusSubscription",
  VenueStatus,
  types.model({
    subscriptionState: types.maybe(types.string),
    payments: VenueStatusSubscriptionPayments,
  })
);

const EmailOctopus = types.model("EmailOctopus")
.props({
  id: types.maybeNull(types.string),
  integrationId: types.maybeNull(types.string),
  apiKey: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  isProPlan: types.maybeNull(types.boolean)
})

const Integration = types.model("Integration")
.props({
  id: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
  edited_at: types.maybeNull(types.string),
  credentials_type: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  name: types.maybeNull(types.string)
})



export type IVenueStatus = Instance<typeof VenueStatus>
export type IEmailOctopus = Instance<typeof EmailOctopus>
export type IIntegration = Instance<typeof Integration>
export type IVenueStatusSubscription = Instance<typeof VenueStatusSubscription>
export type IVenueStatusSubscriptionReport = Instance<typeof VenueStatusSubscriptionReport>
export type IVenueReferralCode = Instance<typeof VenueReferralCode>
export type IVenueStatusesStats = Instance<typeof VenueStatusesStats>

const VenueStatusesStats = types.model("VenueStatusesStats")
.props({
  id: types.reference(VenueStatus),
  membersCount: types.number,
})

const MembersStats = types.model("Stats")
.props({
  membersCount: types.maybeNull(types.number),
  membersLastMonth: types.maybeNull(types.number),
  venueStatuses: types.maybeNull(types.array(VenueStatusesStats))
})

const MembersFilter = types.model("Filter")
.props({
  totalPages: types.optional(types.late(() => types.number), 1),
  totalMembers: types.optional(types.late(() => types.number), 0),
  sorting: types.optional(types.late(() => types.union(
    ...Object.values(FETCH_MEMBERS_SORTING).flatMap(value => [
      types.literal(value),
      types.literal(`-${value}`)
    ])
  )), FETCH_MEMBERS_SORTING.JOINED),
  ordering: types.optional(types.late(() => types.union(
    types.literal(FETCH_MEMBERS_ORDERING.ASC),
    types.literal(FETCH_MEMBERS_ORDERING.DESC),
    types.undefined
  )), FETCH_MEMBERS_ORDERING.DESC),
  venueStatuses: types.optional(types.late(() => types.array(types.string)), []),
  stampCardsIds: types.optional(types.late(() => types.array(types.string)), []),
  currentPaginationPage: types.optional(types.late(() => types.number), 1),
  searchByName: types.optional(types.late(() => types.string), ""),
  columns: types.optional(types.late(() => types.array(types.string)), []),
  venueEvent: types.maybe(types.string),
})

const MemberDemographics = types.model({
  venue_id: types.string,
  genders: types.map(types.number),
  age_ranges: types.map(types.number),
  age_gender: types.map(types.map(types.number)),
})

const MemberDemographicsTotals = types.model({
  genders: types.map(types.number),
  age_ranges: types.map(types.number),
  age_gender: types.map(types.map(types.number)),
})

const MemberTotalCountTimeline = types.model({
  venue_id: types.string,
  timeline: types.array(types.model({
    day: types.string,
    cumulative_count: types.number,
  })),
})

const MemberTotalCountTimelineTotals = types.model({
  day: types.string,
  cumulative_count: types.number,
})

export type IMembersStats = Instance<typeof MembersStats>
export type IMembersFilter = Instance<typeof MembersFilter>
export type IMemberDemographics = Instance<typeof MemberDemographics>
export type IMemberDemographicsTotals = Instance<typeof MemberDemographicsTotals>
export type IMemberTotalCountTimeline = Instance<typeof MemberTotalCountTimeline>
export type IMemberTotalCountTimelineTotals = Instance<typeof MemberTotalCountTimelineTotals>

export const MemberStore = types.model("BenefitStore")
  .props({
    stats: types.optional(types.late(() => MembersStats), {}),
    members: types.optional(types.late(() => types.array(Member)), []),
    filter: types.optional(types.late(() => MembersFilter), {}),
    statuses: types.optional(types.array(VenueStatus), []),
    referralCodes: types.optional(types.array(VenueReferralCode), []),
    statusSubscriptions: types.optional(types.array(VenueStatusSubscription), []),
    statusSubscriptionsReports: types.optional(types.array(VenueStatusSubscriptionReport), []),
    emailOctopus: types.optional(EmailOctopus, {}),
    availableIntegrations: types.optional(types.late(() => types.array(Integration)), []),
    reporting: types.optional(types.model({
      newMembersLast30Days: types.maybe(types.number),
      statusMemberCount: types.optional(types.array(types.model({
        id: types.reference(VenueStatus),
        count: types.number,
      })), []),
    }), {}),
    membersTotalCountTimeline: types.optional(types.array(MemberTotalCountTimeline), []),
    membersTotalCountTimelineTotals: types.optional(types.array(MemberTotalCountTimelineTotals), []),
    memberDemographics: types.optional(types.array(MemberDemographics), []),
    memberDemographicsTotals: types.optional(MemberDemographicsTotals, {}),
  }).views(self => ({
    get filteredReport() {
      log.debug("MemberStore: filteredReport updated")

      return (self.members.map((member: IMember) => {
        const filteredVenueStatuses = member.venueStatuses.filter((venueStatus: IMemberVenueStatus) => {
          return getEnv(self).userSessionStore.selectedBranchVenueIds.includes(venueStatus.id.restaurantId.id)
        })
        const filteredVenueMemberships = member.venueMemberships.filter((membership: IMemberVenueMembership) => {
          return getEnv(self).userSessionStore.selectedBranchVenueIds.includes(membership.id.id)
        })

        return {...member, venueStatuses: filteredVenueStatuses, venueMemberships: filteredVenueMemberships}
      }) as Array<IMember>).filter((member: IMember) => {
        return member.venueMemberships.length > 0 || member.venueStatuses.length > 0
      })
    },
    get shouldShowMoreMembersButton() {
      return self.filter.currentPaginationPage < self.filter.totalPages
    },
    get filteredMembers() {
      return self.members
    },
    get venuesWithStatuses() {
      log.debug("MemberStore: venuesWithStatuses")
      return Array.from(new Set(self.statuses.map((status: IVenueStatus) => status.restaurantId)))
    },
    getMemberById(id: string) {
      const member =  resolveIdentifier(Member, self.members, id)
  
      return member;
    },
    venueStatuses(venue: IVenue) {
      log.debug("MemberStore: venuesStatuses")
      return self.statuses.filter((status: IVenueStatus) => status.restaurantId === venue)
    },
    venueStatusSubscriptions(venue?: IVenue) {
      log.debug("MemberStore: venuesStatuses")
      if (venue) {
        return self.statusSubscriptions.filter((status: IVenueStatusSubscription) => status.restaurantId === venue)
      }

      return []
    },
    
  }))
  .views(self => ({
    passiveVenueStatuses(venue: IVenue, member: IMember) {
      return self.venueStatuses(venue).filter((status: IVenueStatus) => {
        return !member.activeStatuses(venue).map((memberVenueStatus: IMemberVenueStatus) => memberVenueStatus.id).includes(status)
      }).filter((status: IVenueStatus) => {
        return !(status.payments.type === VENUE_STATUS_PAYMENT_TYPE_FIXED && moment.utc(status.payments.validThrough) <= moment.utc())
      })
    },
    getMembersTotalCountTimeline() {
      const userSessionStore = getEnv(self).userSessionStore;
      if (userSessionStore.isOrganizationContext()) {
        return self.membersTotalCountTimelineTotals;
      } else {
        const venueId = userSessionStore.selectedBranch?.id;
        return self.membersTotalCountTimeline.find(item => item.venue_id === venueId)?.timeline || [];
      }
    },
    getMemberDemographics() {
      const userSessionStore = getEnv(self).userSessionStore;
      let demographicsData;
    
      if (userSessionStore.isOrganizationContext()) {
        demographicsData = self.memberDemographicsTotals;
      } else {
        const venueId = userSessionStore.selectedBranch?.id;
        demographicsData = self.memberDemographics.find(item => item.venue_id === venueId);
      }
    
      if (!demographicsData) {
        return null;
      }
    
      const genders = formatPercentagesForDemographics({
        data: demographicsData.genders?.toJSON(),
        keyOrder: GENDER_DEMOGRAPHY_ORDER
      });
      const age_ranges = formatPercentagesForDemographics({
        data: demographicsData.age_ranges?.toJSON(),
        keyOrder: AGE_RANGE_DEMOGRAPHY_ORDER
      });
      return { genders, age_ranges };
    }
  }))
  .actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {
      log.debug("MemberStore: fetchData")
      const userSessionStore = getEnv(self).userSessionStore

      const venueDataResponse = yield getEnv(self).api.fetchVenue(undefined, requestSource)
      const venueStatsResponse = yield getEnv(self).api.fetchVenueStats(undefined, requestSource)
      const venueReferralCodesResponse = yield getEnv(self).api.fetchVenueReferralCodes(undefined, requestSource)

      function mergeOrganizationVenueStatuses(arr: any[]) {
        return arr.reduce((acc, cur) => {
          return acc.concat(cur.venueStatuses);
        }, []);
      }

      if (venueDataResponse.modified) {
        self.statuses.replace(venueDataResponse.payload.data.statuses)
      }

      if (venueReferralCodesResponse.modified) {
        self.referralCodes.replace(venueReferralCodesResponse.payload.data)
      }


      if (venueStatsResponse.modified) {
       
      
        const venueTotalsSpecificVenue = venueStatsResponse.payload.data.venuetotals.find((venue: any) => venue.id === userSessionStore.selectedBranch.id)

        if (venueTotalsSpecificVenue) {
          self.stats.membersCount = venueTotalsSpecificVenue.memberscount;
          self.stats.venueStatuses = venueTotalsSpecificVenue.venueStatuses.map((venueStatus: any) => ({
            ...venueStatus.VenueStatus
          }))
          self.stats.membersLastMonth = venueTotalsSpecificVenue.memberslastmonth;   
        } else {
          self.stats.membersCount = venueStatsResponse.payload.data.totals.membersCount;
          self.stats.membersLastMonth = venueStatsResponse.payload.data.totals.membersLastMonth;
          const allOrganizationVenueStatuses = mergeOrganizationVenueStatuses(venueStatsResponse.payload.data.venuetotals)

          self.stats.venueStatuses = allOrganizationVenueStatuses.map((venueStatus: any) => ({
            ...venueStatus.VenueStatus
          }))
        }

        self.membersTotalCountTimeline.replace(venueStatsResponse.payload.data.membersTotalCountTimeline)
        self.membersTotalCountTimelineTotals.replace(venueStatsResponse.payload.data.membersTotalCountTimelineTotals)
        
        self.memberDemographics.replace(venueStatsResponse.payload.data.memberDemographics.map((demographic: any) => ({
          venue_id: demographic.venue_id,
          genders: demographic.genders,
          age_ranges: demographic.age_ranges,
          age_gender: demographic.age_gender,
        })))
        
        self.memberDemographicsTotals = {
          genders: venueStatsResponse.payload.data.memberDemographicsTotals.genders,
          age_ranges: venueStatsResponse.payload.data.memberDemographicsTotals.age_ranges,
          age_gender: venueStatsResponse.payload.data.memberDemographicsTotals.age_gender,
        }
      }

      const availableEmailOctopusIntegrationsResponse = yield getEnv(self).api.fetchAvailableEmailOctopusIntegrations(undefined, requestSource)
      const emailOctopuusResponse = yield getEnv(self).api.fetchEmailOctopusIntegrations(undefined, requestSource)
    
      if (availableEmailOctopusIntegrationsResponse?.modified) {
        self.availableIntegrations = availableEmailOctopusIntegrationsResponse.payload.data.integrations.filter((integration: IIntegration) => integration.status === "ENABLED")
      }
  
      if (emailOctopuusResponse?.modified && emailOctopuusResponse.payload.data.integrations?.length > 0) {
        self.emailOctopus.id = emailOctopuusResponse.payload.data.integrations[0]?.id
        self.emailOctopus.apiKey = emailOctopuusResponse.payload.data.integrations[0]?.credentials?.ct?.apikey
        self.emailOctopus.status = emailOctopuusResponse.payload.data.integrations[0]?.status
        self.emailOctopus.isProPlan = emailOctopuusResponse.payload.data.integrations[0]?.advanced ? true : false
      }

      try {
        const response = yield getEnv(self).api.fetchMembers({requestSource})

        if (response.modified) {
          self.members.replace(response.payload.data.members)
          self.filter.currentPaginationPage = 1
          self.filter.totalPages = response.payload.data.pagination.total_pages
          self.filter.totalMembers = response.payload.data.pagination.total_members
        }
  
        const fetchedSubscriptionsResponse = yield getEnv(self).api.fetchSubscriptions(undefined, 'init')
        if (fetchedSubscriptionsResponse.modified) {
          self.statusSubscriptions.replace(fetchedSubscriptionsResponse.payload.data)
        }
  
        const fetchedSubscriptionsReportResponse = yield getEnv(self).api.fetchSubscriptionsReport(undefined, 'init')
  
        if (fetchedSubscriptionsReportResponse.modified) {
          self.statusSubscriptionsReports.replace(fetchedSubscriptionsReportResponse.payload.data)
        }
      
        return response.etag

      } catch (error) {
        console.log('members/list error ', error)
      }

    }),

    fetchMoreMembers: flow(function* fetchMembers(
      requestSource: string = "init"
    ) {
      log.debug("MemberStore: fetchMembers")

    
      const NEXT_MEMBERS_PAGE_PARAM = self.filter.currentPaginationPage + 1
      const response = yield getEnv(self).api.fetchMembers({
        requestSource: requestSource,
        page: NEXT_MEMBERS_PAGE_PARAM,
        sorting: self.filter.sorting,
        name: self.filter.searchByName,
        venueStatuses: self.filter.venueStatuses,
        stampCardsIds: self.filter.stampCardsIds,
        columns: self.filter.columns,
        venueEvent: self.filter.venueEvent
      })
    
      if (response.modified) {
        self.members.push(...response.payload.data.members)
        self.filter.currentPaginationPage = NEXT_MEMBERS_PAGE_PARAM
      }
    
    }),

    fetchInvitationCodes: flow(function* fetchInvitationCodes(
      requestSource: string = "init"
    ) {
      log.debug("MemberStore: fetchMembers")
      const venueReferralCodesResponse = yield getEnv(self).api.fetchVenueReferralCodes(undefined, requestSource)

      if (venueReferralCodesResponse.modified) {
        self.referralCodes.replace(venueReferralCodesResponse.payload.data)
      } 
    }),

    getMessageRecipientCount: flow(function* getMessageRecipientCount({selectedVenues, selectedStatuses}: {selectedVenues: string[], selectedStatuses: string[]}) {
      const configuration = {
        venues: selectedVenues.length > 0 ? selectedVenues.map(venueId => ({
          id: venueId,
          statuses: selectedStatuses.filter(statusId => {
            const status = self.statuses.find(s => s.id === statusId);
            return status?.restaurantId?.id === venueId;
          }).map(statusId => ({ id: statusId }))
        })) : self.statuses.filter(status => selectedStatuses.includes(status.id)).map(status => ({
          id: status.restaurantId.id,
          statuses: [{ id: status.id }]
        }))
      };
    
      const response = yield getEnv(self).api.fetchMessageRecipientCount({
        configuration: configuration
      });
      const totalCount = response.payload.data?.total_user_count
      return totalCount;
    }),
    fetchMembers: flow(function* ({
      requestSource = "init",
      page = self.filter.currentPaginationPage,
      sorting = self.filter.sorting,
      venueStatuses = self.filter.venueStatuses.slice(),
      stampCardsIds = self.filter.stampCardsIds.slice(),
      name = self.filter.searchByName,
      columns = self.filter.columns,
      venueEvent = self.filter.venueEvent,
      resetFilters = false
    }: {
      requestSource?: string,
      page?: number,
      sorting?: string,
      venueStatuses?: string[],
      stampCardsIds?: string[],
      columns?: string[],
      name?: string,
      venueEvent?: string,
      resetFilters?: boolean
    } = {}) {
      log.debug("MemberStore: fetchMembers", { requestSource, page, sorting, venueStatuses, name, venueEvent });

      const shouldRestartPagination = resetFilters || sorting !== self.filter.sorting ||
        JSON.stringify(venueStatuses) !== JSON.stringify(self.filter.venueStatuses) ||
        JSON.stringify(stampCardsIds) !== JSON.stringify(self.filter.stampCardsIds) ||
        name !== self.filter.searchByName ||
        columns !== self.filter.columns ||
        venueEvent !== self.filter.venueEvent;

    
      const currentPaginationPage = shouldRestartPagination ? 1 : page;
    
      try {
        const fetchParams = { 
          requestSource: "init", 
          page: currentPaginationPage, 
          sorting: resetFilters ? FETCH_MEMBERS_SORTING.JOINED : sorting, 
          venueStatuses: resetFilters ? [] : venueStatuses, 
          stampCardsIds: resetFilters ? [] : stampCardsIds, 
          name: resetFilters ? '' : name, 
          columns: resetFilters ? [] : columns,
          venueEvent: resetFilters ? undefined : venueEvent
        };
    
        const response = yield getEnv(self).api.fetchMembers(fetchParams);
    
        if (response && response.modified) {
          if (currentPaginationPage === 1) {
            applySnapshot(self.members, response.payload.data.members);
          } else {
            self.members.push(...response.payload.data.members);
          }
    
          self.filter.currentPaginationPage = currentPaginationPage;
          self.filter.totalPages = response.payload.data.pagination.total_pages;
          self.filter.totalMembers = response.payload.data.pagination.total_members;
          self.filter.sorting = sorting;
          self.filter.venueStatuses.replace(venueStatuses);
          self.filter.stampCardsIds.replace(stampCardsIds);
          self.filter.columns.replace(columns);
          self.filter.searchByName = name;
          self.filter.venueEvent = venueEvent;
        }
      } catch (error) {
        log.error("Failed to fetch members", error);
      }
    }),



    fetchMembersByVenueStatuses: flow(function* fetchMembers(
      venueStatuses: string[] = [],
      requestSource: string = "init"
    ) {
      log.debug("MemberStore: fetchMembers")
      
      const NEXT_MEMBERS_PAGE_PARAM = 1
    
      const response = yield getEnv(self).api.fetchMembers({
        requestSource: requestSource,
        page: NEXT_MEMBERS_PAGE_PARAM,
        sorting: self.filter.sorting,
        name: self.filter.searchByName, 
        venueStatuses: venueStatuses
      });
      

      if (response.modified) {
        self.members.replace(response.payload.data.members)
        self.filter.currentPaginationPage = NEXT_MEMBERS_PAGE_PARAM
        self.filter.totalPages = response.payload.data.pagination.total_pages
        self.filter.totalMembers = response.payload.data.pagination.total_members
        self.filter.venueStatuses.replace(venueStatuses)
      }
    }),


    fetchMembersByName: flow(function* fetchMembers(
      name: string | undefined = undefined,
      requestSource: string = "init"
    ) {
      log.debug("MemberStore: fetchMembers")
      // const isNewSorting = sorting !== undefined
   
      const NEXT_MEMBERS_PAGE_PARAM = self.filter.currentPaginationPage 
      const SORTING_PARAM = self.filter.sorting
      const NAME_PARAM = name

      if (!NAME_PARAM) {
        self.filter.searchByName = ''
        self.filter.currentPaginationPage = 1
        self.filter.totalPages = 1
      }
    
      const response = yield getEnv(self).api.fetchMembers({
        requestSource: requestSource,
        page: NEXT_MEMBERS_PAGE_PARAM,
        sorting: SORTING_PARAM,
        name: NAME_PARAM, 
        venueStatuses: self.filter.venueStatuses
      });
      

      if (response.modified) {
        self.filter.searchByName = NAME_PARAM ? NAME_PARAM : ''
        self.members.replace(response.payload.data.members)
        self.filter.currentPaginationPage = NEXT_MEMBERS_PAGE_PARAM
        self.filter.totalPages = response.payload.data.pagination.total_pages
        self.filter.totalMembers = response.payload.data.pagination.total_members
        // if (isNewSorting && SORTING_PARAM) {
        //   self.filter.sorting = SORTING_PARAM
        // }
      }
    }),

    
    fetchMemberWithTimeline: flow(function* fetchMembersWithTimeline(memberId: string, requestSource: string = "init") {
      const membersList = self.members;
    
      let member = membersList.find((member: any) => member.id === memberId);
      if (!member) {
        return;
      }
    
      const responseMemberTimeline = yield getEnv(self).api.fetchMemberTimeline(undefined, member.id, 'init');

      const mergedVenueMemberships = mergeVenueMemberships(responseMemberTimeline);

      const memberWithTimeline = {
        ...member,
        timeline: {...responseMemberTimeline.payload.data.timeline_data[0], venueMemberships: mergedVenueMemberships }
      };
    
      const memberIndex = membersList.findIndex((m: any) => m.id === memberId);
      if (memberIndex !== -1) {
        membersList.splice(memberIndex, 1, memberWithTimeline);
      } else {
        membersList.push(memberWithTimeline);
      }
    }),
    
    
    fetchMember: flow(function* fetchMember(members: Array<string>, requestSource: string = "init") {
      log.debug("MemberStore: fetchMember:", members)
      const response = yield getEnv(self).api.fetchMembers({members, requestSource, disableCache: true})

      response.payload.data.members.forEach((member: IMember) => {
        const currentMember = resolveIdentifier(Member, self.members, member.id) as IMember
        if (currentMember) {
          // @ts-ignore
          applySnapshot(currentMember, getSnapshot(Member.create(member)))
        } else {
          // @ts-ignore
          self.members.push(Member.create(member))
        }
      })

      log.debug(self.members.find((member: IMember) => member.id === members[0]))
    }),
    generateVenueStatusInvitationCode: flow(function* generateVenueStatusInvitationCode(statusId: string | null | undefined, venueId: string | null | undefined, codeId: string | null | undefined, requestSource: string = "init") {
      log.debug("MemberStore: generateVenueStatusInvitationCode")
    
      const userSessionStore = getEnv(self).userSessionStore;
      const requestOrganization = userSessionStore.currentOrganization?.id || '';

      try {
        const response = yield getEnv(self).api.generateInvitationCode(statusId, venueId, codeId, requestOrganization, requestSource);

        if (response?.result === "OK") {
          const venueReferralCodesResponse = yield getEnv(self).api.fetchVenueReferralCodes(undefined, requestSource)
          if (venueReferralCodesResponse.modified) {
            self.referralCodes.replace(venueReferralCodesResponse.payload.data)
          }
        }
        return response;
      } catch (error) {
        log.error("Error generating venue status invitation code:", error);
        throw error;
      }
    }),    
    addEmailOctopusIntegration(id: string, integrationId: string, status: string, advanced: boolean | undefined) {
      self.emailOctopus.id = id
      self.emailOctopus.integrationId = integrationId
      self.emailOctopus.status = status
      self.emailOctopus.isProPlan = advanced ? true : false
    },
    updateReport() {
      log.debug("MemberStore: updateReport")

      const timestampMinus30DaysUTC = moment.utc().tz("Europe/Helsinki").endOf("day").subtract(30, "d").tz("UTC")
      self.reporting.newMembersLast30Days = self.filteredReport.filter((member: IMember) => {
        const earliestJoinMembership: IMemberVenueMembership = _.minBy(member.venueMemberships, "memberSince")
        if (!earliestJoinMembership) return false
        return moment.utc(earliestJoinMembership.memberSince) >= timestampMinus30DaysUTC
      }).length

      const statusMemberCount = Object.entries(
        _.reduce(self.filteredReport, function (result: any, value: IMember) {
          value.venueStatuses.forEach((memberVenueStatus: IMemberVenueStatus) => {
            const statusId = memberVenueStatus.id.id
            result[statusId] = (statusId in result) ? result[statusId] + 1 : 1
          })
          return result
        }, {}))
        .map(([key, value]) => {
          return {id: key, count: value}
        })

      // @ts-ignore
      self.reporting.statusMemberCount.replace(statusMemberCount)
    },
    postEmailOctopusIntegration: flow(function* postEmailOctopusIntegration(apiKey: string, advanced: boolean, requestSource: string = "init") {
      log.debug("MemberStore: postEmailOctopusIntegration:", apiKey)
      const emailOctopusIntegration = self.availableIntegrations.find(integration => integration.name === 'emailoctopus')

      if (emailOctopusIntegration) {
        const response = yield getEnv(self).api.postEmailOctopusIntegration(apiKey, emailOctopusIntegration.id, advanced, undefined, requestSource)
   
        return response
      }
 
    }),
    addStatus(status: IVenueStatus) {
      self.statuses.push(status)
    },
    updateStatus(status: any) {
      const statusToUpdateIndex = self.statuses.findIndex(s => s.id === status.id)
      self.statuses[statusToUpdateIndex] = status
    },
    addStatusSubscription(status: IVenueStatusSubscription) {
      self.statusSubscriptions.push(status)
    },
    updateStatusSubscription(status: IVenueStatusSubscription) {
      const statusToUpdateIndex = self.statusSubscriptions.findIndex(s => s.id === status.id)
      self.statusSubscriptions[statusToUpdateIndex] = status
    },
    resetFilterSortingAndOrdering() {
      self.filter.sorting = FETCH_MEMBERS_SORTING.JOINED
    },
    resetData() {
      log.debug("MemberStore: resetData")
      self.members.clear()
      self.statuses.clear()
      self.statusSubscriptions.clear()
    },
    resetFilters() {
      self.filter.sorting = FETCH_MEMBERS_SORTING.JOINED
      self.filter.venueStatuses.clear()
      self.filter.stampCardsIds.clear()
      self.filter.columns.clear()
      self.filter.searchByName = ''
      self.filter.venueEvent = undefined
    }
  }))

export type IMemberStore = Instance<typeof MemberStore>
