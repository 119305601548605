import * as _ from "lodash";

function getObjectModifications(modifiedObject: any, originalObject: any) {
  var keys = _.union(_.keys(modifiedObject), _.keys(originalObject))
  const modifications: any = {}
  keys.forEach((key: any) => {
    if (originalObject && !_.isEqual(modifiedObject[key], originalObject[key])) {
      // filter out undefined fields (mobx models have optional fields etc)
      modifications[key] = _.isObject(modifiedObject[key]) ?
        _.pickBy(modifiedObject[key], (v: any) => v !== undefined) :
        modifiedObject[key]
    }
  })
  // add id-field to modified objects
  if (!_.isEmpty(modifications)) modifications["id"] = modifiedObject["id"]
  return modifications
}

function getModifications(modifications: Array<any>, original: Array<any>) {
  return modifications.map((item: any, index: number) => {
    return _.omitBy(original[index] !== undefined ? getObjectModifications(item, original[index]) : JSON.parse(JSON.stringify(item)), (value: any, key: string) => ["editedAt", "createdAt", "edited_at", "created_at", "_computed_"].some((omitKey: string) => key.includes(omitKey)))
  }).filter(item => !_.isEmpty(item))
}

export {
  getObjectModifications,
  getModifications
}