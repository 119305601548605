import { inject, observer } from "mobx-react"
import Button from "react-bootstrap/Button"
import Modal from 'react-bootstrap/Modal'
import { WithTranslation, withTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MerchantAdminApi } from "../../Api"
import { useState } from "react"

export interface RemoveUserProps extends RouteComponentProps, WithTranslation {
  api?: MerchantAdminApi
  showModal?: boolean
  setShowModal?: any
  userSessionStore?: any
  user: any
  onConfirm: (userId: string) => void
}

const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR'

// @ts-ignore
const RemoveUserModal = (props: RemoveUserProps) => {
    const {t, showModal, setShowModal, api, userSessionStore} = props

    const RemoveModal = () => {
      const [isRemoving, setIsRemoving] = useState(false)

      return (
        <Modal show={showModal} centered onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100">
              <div className="font-weight-bold">{t("invitationsScreen.removeUserPopUp.title")}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="mt-2" style={{fontSize: 18}}>
              {t("invitationsScreen.removeUserPopUp.description", { userName: props.user?.name })}?
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="outline-primary" onClick={() => setShowModal(false)}>
              {t("invitationsScreen.removeUserPopUp.cancel")}
            </Button>
            <Button 
              variant="danger" 
              disabled={isRemoving}
              onClick={() => {
                setIsRemoving(true)
                props.onConfirm(props.user.id)
              }}
            >
              {t("invitationsScreen.removeUserPopUp.confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

    return <RemoveModal />
}

export default withRouter(withTranslation()(inject("api", "userSessionStore")(observer(RemoveUserModal))))