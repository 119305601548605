import { types, flow, getEnv, Instance, SnapshotIn, getRoot } from "mobx-state-tree";
import { LoyaltyCard, ILoyaltyCard } from "./LoyaltyCard";
import { ApiResult } from "../ApiResult";
import { IRootStore } from "./RootStore";

type VenueData = SnapshotIn<typeof VenueDataModel>;
const VenueDataModel = types.model({
  id: types.string,
  check_balances: types.integer,
  register_purchases: types.integer,
  cards: types.array(LoyaltyCard)
});

export const LoyaltyCardStore = types.model("LoyaltyCardStore")
  .props({
    loyaltyCards: types.optional(types.array(LoyaltyCard), []),
  })
  .views(self => ({
    getRewardsSummaries() {
      const rootStore = getRoot<IRootStore>(self);
      const userSessionStore = rootStore.userSessionStore;
      
      const relevantCards = userSessionStore.isOrganizationContext()
        ? self.loyaltyCards
        : self.loyaltyCards.filter(card => card.restaurantId.id === userSessionStore.selectedBranch?.id);
  
      let totalMembersWithStamps = 0;
      let newMembersLast30Days = 0;
      let totalRewardsRedeemed = 0;
      let rewardsRedeemedLast30Days = 0;
      let totalRewardsCollected = 0;
      let rewardsCollectedLast30Days = 0;
      let totalStampsCollected = 0;
      let stampsCollectedLast30Days = 0;
  
      relevantCards.forEach(card => {
        totalMembersWithStamps += card.total_unique_users || 0;
        newMembersLast30Days += card.total_unique_users_30 || 0;
        totalRewardsRedeemed += card.rewards_redeemed || 0;
        rewardsRedeemedLast30Days += card.rewards_redeemed_30 || 0;
        totalRewardsCollected += card.total_rewards || 0;
        rewardsCollectedLast30Days += card.total_rewards_30 || 0;
        totalStampsCollected += card.total_stamps || 0;
        stampsCollectedLast30Days += card.total_stamps_30 || 0;
      });
  
      return {
        totalMembersWithStamps,
        newMembersLast30Days,
        totalRewardsRedeemed,
        rewardsRedeemedLast30Days,
        totalRewardsCollected,
        rewardsCollectedLast30Days,
        totalStampsCollected,
        stampsCollectedLast30Days
      };
    }
  }))
  .actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {

      try {
        const response: ApiResult = yield getEnv(self).api.fetchLoyaltyCardStats(undefined, requestSource);
        console.log('fetchLoyaltyCardStats response', response)
        if (response.modified) {
          const transformedCards: ILoyaltyCard[] = response.payload.data.data.reduce(
            (acc: any[], venueData: VenueData) => {
              const venueCards = venueData?.cards?.map(card => ({
                ...card,
                restaurantId: venueData.id
              }));
              return acc.concat(venueCards);
            }, []);
          self.loyaltyCards.replace(transformedCards);
        }
        return response.etag;
  
      } catch (error) {
        console.error('Error fetching loyalty card data:', error);
      }
    }),
    resetData() {
      self.loyaltyCards.clear();
    },
    addLoyaltyCard(loyaltyCard: ILoyaltyCard) {
      self.loyaltyCards.push(loyaltyCard);
    },
    updateLoyaltyCard(loyaltyCard: ILoyaltyCard) {
      const index = self.loyaltyCards.findIndex(lc => lc.id === loyaltyCard.id);
      if (index !== -1) {
        self.loyaltyCards[index] = loyaltyCard;
      }
    },
    removeLoyaltyCard(loyaltyCard: ILoyaltyCard) {
      self.loyaltyCards.remove(loyaltyCard);
    }
  }));

export type ILoyaltyCardStore = Instance<typeof LoyaltyCardStore>;
