import { Instance, types } from "mobx-state-tree";

const LocalizedString = types.model({
  en: types.string,
  fi: types.string
});

const RewardTriggerDescription = types.model("RewardTriggerDescription").props({
  name: LocalizedString,
  description: LocalizedString
});

export const RewardTrigger = types.model("RewardTrigger").props({
  status: types.string,
  createdAt: types.string,
  type: types.string,
  description: RewardTriggerDescription,
  active: types.boolean,
  id: types.identifier,
  editedAt: types.string,
  configuration: types.frozen(),
  availability: types.frozen(),
  created_at: types.string,
  edited_at: types.string
});

export type IRewardTrigger = Instance<typeof RewardTrigger>;
