import { inject, observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { SummaryHeader, SummaryItem } from "../../components/SummaryHeader";
import { IBenefitReportStore } from "../../models/BenefitReportStore";
import { ILoyaltyCardStore } from "../../models/LoyaltyCardStore";
import { IMemberStore } from "../../models/MemberStore";
import { IMessageStore } from "../../models/MessageStore";
import { IRewardStore } from "../../models/RewardStore";
import { IUserSessionStore } from "../../models/UserSessionStore";
import MemberDemography from "./MemberDemography";
import MemberGrowthTimeline from "./MemberGrowthTimeline";
import NewMembersList from "./NewMembersList";
import { useNewMembersFetch } from "./NewMembersList/useNewMembersFetch";
import { getEventsOrganizationSummary, isSummaryShowable, sortSummariesByValueDescending } from "./utils";
import { IEventStore } from "../../models/EventStore";


interface HomeScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore;
  memberStore?: IMemberStore;
  messageStore?: IMessageStore;
  benefitReportStore?: IBenefitReportStore;
  rewardStore?: IRewardStore;
  loyaltyCardStore?: ILoyaltyCardStore;
  eventStore?: IEventStore;
}
 



const HomeScreen = inject(
  "userSessionStore",
  "memberStore",
  "messageStore",
  "benefitReportStore",
  "loyaltyCardStore",
  "rewardStore",
  "eventStore"
)(
  observer((props: HomeScreenProps) => {
    const { t, userSessionStore, memberStore, messageStore, benefitReportStore, loyaltyCardStore, rewardStore, eventStore } = props;

    const { newMembers, loading: isNewMembersLoading } = useNewMembersFetch()



    const rewardsSummariesData = loyaltyCardStore?.getRewardsSummaries()

    const venueStatusesStats = memberStore!.stats?.venueStatuses?.slice() || []

    const pushNotificationsSentLast30Days = messageStore?.getPushNotificationsSentLast30Days() || '0'

 

    const membersSummaries: Array<SummaryItem> = [
      {
        title: t("memberScreen.summary.totalMembersTitle"),
        value: memberStore?.stats?.membersCount?.toString() || '0',
        footer: t("messagesScreen.summary.total"),
        // footer: memberStore?.stats?.membersLastMonth === 1 ? 
        // t("homeScreen.oneNewMemberLast30Days") : 
        // `${memberStore?.stats?.membersLastMonth || '0'} ${t("homeScreen.newMembersLast30Days")}`,
      },
      {
        title: t("memberScreen.summary.newMembersTitle"),
        value: memberStore?.stats?.membersLastMonth?.toString() || '0',
        footer: t("memberScreen.summary.newMembersFooter"),
      },
    ]

    const benefitsSummaries: Array<SummaryItem> = [
  
      {
        title: t("benefits"),
        value: benefitReportStore!.reporting.benefitsUsedTotal.toString(),
        footer: t("messagesScreen.summary.total"),
        // footer: Number(benefitReportStore!.reporting.benefitsUsedLast30Days) === 1 ? 
        // t("homeScreen.oneBenefitUsedLast30Days") : 
        // `${benefitReportStore!.reporting.benefitsUsedLast30Days} ${t("homeScreen.benefitsUsedLast30Days")}`,
      },
    ] 

    const messagesSummaries: Array<SummaryItem> = [
      {
        title: t("homeScreen.messages"),
        value: messageStore?.getTotalPushNotificationsSent().toString() || '0',
        footer: t("messagesScreen.summary.total"),
        // footer: Number(pushNotificationsSentLast30Days) === 1 ? 
        // t("homeScreen.oneMessageSentLast30Days") : 
        // `${pushNotificationsSentLast30Days} ${t("homeScreen.messagesSentLast30Days")}`,
      },
    ]

    const rewardsSummaries: Array<SummaryItem> = [
      ...(Boolean(rewardsSummariesData?.totalRewardsCollected) ? [     {
        title: t("homeScreen.rewards"),
        value: String(rewardsSummariesData?.totalRewardsCollected),
        footer: t("messagesScreen.summary.total"),
 
    },]: []),
    ]

  const eventsSummaries: Array<SummaryItem> = eventStore
  ? [
      {
        title: t("eventScreen.summary.ticketsSoldHeader"),
        value: (() => {
          if (userSessionStore?.isOrganizationContext()) {
            return getEventsOrganizationSummary({eventStore, field: "visitors"}).toString();
          } else {
            const branchId = userSessionStore?.selectedBranch?.id;
            const branchSummary = branchId ? eventStore.summaries.get(branchId) : undefined;
            return branchSummary?.visitors?.toString() || '0';
          }
        })(),
        footer: t("eventScreen.summary.ticketsSoldFooter"),
      },
    ]
  : [];

    const venueStatusSummaries: Array<SummaryItem> = venueStatusesStats?.map((venueStatusStat) => {
      return {
        venue: userSessionStore!.currentOrganization!.isSingleVenue() ? undefined : venueStatusStat.id?.restaurantId?.name,
        title: venueStatusStat.id.localeTitle,
        value: venueStatusStat.membersCount?.toString() || '0',
        footer: t("memberScreen.summary.statusMembersFooter"),
        // TODO: use this once we have the data from the backend
        //
        // footer: Number(venueStatusStat.membersCountLast30Days) === 1 ? 
        // t("homeScreen.oneCardGivenLast30Days") : 
        // `${venueStatusStat.membersCountLast30Days} ${t("homeScreen.cardsGivenLast30Days")}`,
      }
    }) || []
   

      const sortedSummaries = [
        ...membersSummaries,
        ...benefitsSummaries,
        ...rewardsSummaries,
        ...eventsSummaries,
        ...messagesSummaries,
        ...sortSummariesByValueDescending(venueStatusSummaries)
      ].filter(isSummaryShowable)
  
  

const membersTotalCountTimelineTotals = memberStore!.getMembersTotalCountTimeline()
const memberDemographicsTotals = memberStore!.getMemberDemographics()
    return (
      <div>
        <SummaryHeader 
          // summaries={sortedSummaries} 
          summaries={sortedSummaries}
          isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} 
          noContainerMargin
        />
        
        <div className="mt-2" style={{
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: 270,
      height: 270,
      maxHeight: 500,
 
    }}>
      
      <MemberGrowthTimeline data={membersTotalCountTimelineTotals} showTooltip={true} />
      <MemberDemography data={memberDemographicsTotals} />
    </div>
    <NewMembersList newMembers={newMembers} loading={isNewMembersLoading} />
      </div>
    );
  })
);

export default withTranslation()(withRouter(HomeScreen));