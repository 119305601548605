import {flow, getEnv, getParent, Instance, types} from "mobx-state-tree"
import {EventReport, IEventReport} from "./EventReport"
import moment from "moment-timezone"
import log from "loglevelnext"
import * as _ from "lodash"
import {IDataStore} from "./RootStore";
import {IEventStore} from "./EventStore";
import {IEvent} from "./Event";

export const EventReportStore = types.model("EventReportStore")
  .props({
    currentTimeUpdateHandle: types.maybe(types.number),
    currentTime: types.optional(types.Date, moment().toDate()),
    EventReports: types.optional(types.array(EventReport), [])
  }).views(self => ({
    get filteredReportSpotlight() {
      log.debug("EventReportStore: filteredReportSpotlight updated")
      let spotlightEvents: any = {}
      spotlightEvents.upcoming = this.filteredReportUpcoming.slice(0,4)
      spotlightEvents.past = this.filteredReportPast.slice(0,4)
      return spotlightEvents
    },
      get filteredReportUpcoming() {
        log.debug("EventReportStore: filteredReportUpcoming updated")
        return _.sortBy(self.EventReports.filter((EventReport: IEventReport) => {
          return getEnv(self).userSessionStore.selectedBranchVenueIds.includes(EventReport.eventId.restaurantId.id)
        }).filter((EventReport: IEventReport) => {
          return moment.utc(EventReport.eventId.endTime).tz("Europe/Helsinki").isAfter(self.currentTime)
        }), ['eventId.startTime'])
      },
      get filteredReportPast() {
        log.debug("EventReportStore: filteredReportPast updated")
        return _.orderBy(self.EventReports.filter((EventReport: IEventReport) => {
          return getEnv(self).userSessionStore.selectedBranchVenueIds.includes(EventReport.eventId.restaurantId.id)
        }).filter((EventReport: IEventReport) => {
          return moment.utc(EventReport.eventId.endTime).tz("Europe/Helsinki").isBefore(self.currentTime)
        }), ['eventId.startTime'], ['desc'])
      }

  })).actions(self => ({
    updateCurrentTime() {
      log.debug("EventReportStore: current time updated", self)
      self.currentTime = moment().toDate()
    },
  })).actions(self => ({
    afterCreate() {
      log.debug("EventReportStore: afterCreate", self)
      self.currentTimeUpdateHandle = window.setInterval(self.updateCurrentTime, 1000 * 90)
    },
    beforeDestroy() {
      log.debug("EventReportStore: beforeDestroy", self)
      window.clearInterval(self.currentTimeUpdateHandle)
    },
    fetchData: flow(function* fetchData(ids: Array<string> | undefined = undefined, requestSource: string = "init") {
      log.debug("EventReportStore: fetchData")

      const eventStore = (getParent(self, 1) as IDataStore).eventStore as IEventStore
      const allEventIds = eventStore.events.map((event: IEvent) => {
        return event.id
      })

      const response = yield getEnv(self).api.fetchEventsReports(undefined, ids || allEventIds, requestSource)
      if (response.modified) {
        self.EventReports.replace(response.payload.data)
      }
      return response.etag
    }),
    resetData() {
      log.debug("EventReportStore: resetData")
      self.EventReports.clear()
    }
  }))

export type IEventReportStore = Instance<typeof EventReportStore>
