
interface ThemeColorsProps {
    blueCluby: string;
    blueLightText: string;
    green: string;
    white: string;
    black: string;
    red: string;
    grey: string;
    lightGrey: string;
    background: string;
    boxFill: string;
    primaryText: string;
    secondaryText: string;
    stroke: string;
}


const commonTheme = {
    blueCluby: '#1775F3',
    blueLightText: '#5798EF',
    green: '#2E6E56',
    white: '#FFFFFF',
    black: '#000000',
    red: '#EB5757',

};

const darkTheme: ThemeColorsProps = {
  ...commonTheme,
  grey: '#555555',
  lightGrey: '#BDBDBD',
  background: '#202020',
  boxFill: '#252525',
  primaryText: '#FFFFFF',
  secondaryText: '#AAAAAA',
  stroke: '#353535',
};

const lightTheme: ThemeColorsProps = {
  ...darkTheme,
};



export const colors = darkTheme;
