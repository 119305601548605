import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import moment from "moment-timezone"
import React from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Table from "react-bootstrap/Table"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { useTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { useFilters, useSortBy, useTable } from "react-table"
import { SendEmailLocation, SendPushNotificationLocation } from "../Locations"
import { IMessage } from "../models/Message"
import { IUserSessionStore } from "../models/UserSessionStore"
import {resolveIdentifier} from "mobx-state-tree"
import { IVenue, Venue } from "../models/Venue"
import { colors } from "../theme/colors"

// @ts-ignore
function MessagingTitleCell(cellData) {
  const value = cellData.cell.value
  return <div className="font-heading text-left font-weight-bold" style={{color: colors.secondaryText}}>{value}</div>
}

// @ts-ignore
function MessagingVenueNameCell(cellData) {
  const value = cellData.cell.value
  return <div className="font-heading text-center" style={{color: colors.secondaryText, fontSize: 14}}>
    {value}
  </div>
}


// @ts-ignore
function MessagingDateCell(cellData) {
    const value = cellData.cell.value
    return <div className="font-heading center font-weight-bold text-primary" style={{color: colors.secondaryText, fontSize: 14}}>{value}</div>
}

const MESSAGE_TYPE = {
  EMAIL: 'EMAIL',
  PUSH: 'PUSH'
}

const MESSAGE_STATUS = {
  DRAFT: 'DRAFT',
  READY: 'READY',   
  SENT: 'SENT',
  PROCESSING: 'PROCESSING'
}

// @ts-ignore
function RenderMessagingTable({columns, data, onDateFilterChange}) {
  const {t} = useTranslation()
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{id: "usedTotal", desc: true}],
        hiddenColumns: ["uniqueUsers", "lastUsed"],
      },
      autoResetFilters: false,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
  )


  // @ts-ignore
  return (
    <>
      <Card className="m-0" style={{borderWidth: 0, borderColor: colors.boxFill}}>
        <div className="rounded-sm">
          <Table className="benefitTable p-0 m-0" striped hover {...getTableProps()}>
            <thead className="text-primary" style={{backgroundColor: ""}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} style={{
                MozUserSelect: "none",
                WebkitUserSelect: "none",
              }}>
                {headerGroup.headers.map(column => {
                  // @ts-ignore
                  const {style} = column.getHeaderProps(column.getSortByToggleProps())

                  return (
                    // @ts-ignore
                    <th 
                        // {...column.getHeaderProps(column.getSortByToggleProps())} 
                        style={{
                        MozUserSelect: "none",
                        WebkitUserSelect: "none",
                        whiteSpace: "nowrap",
                        width: column.width,
                        borderWidth: 0,
                        ...style,
                    }}>
                      {column.render("Header")}
                      <span>
                  </span>
                    </th>
                  )
                })}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      )
                    })}
                  </tr>
                )
              },
            )}
            </tbody>
          </Table>
        </div>
      </Card>
    </>
  )
}


interface MessagesTableProps extends RouteComponentProps {
  data: any
  onDateFilterChange?: (selection: { startDate: Date, endDate: Date } | null) => void
  isOrganizationContext: boolean
  userSessionStore?: IUserSessionStore
}

function MessagesTableComponent(props: MessagesTableProps) {
  const {t} = useTranslation()

  const MessagingTypeCell = (cellData: any) => <div className="text-center" style={{color: colors.primaryText}}>
      {cellData.cell.value === 'EMAIL' &&
        <>
          <FontAwesomeIcon style={{color: colors.primaryText}} className="mr-2" icon={["fal", "envelope"]}/>
          <span className="mb-2 medium ml-1" style={{position: 'relative', bottom: 2, fontSize: 16}}>{t("messagesScreen.email")}</span>
        </>
      }
      {cellData.cell.value === 'PUSH' &&
        <>
          <FontAwesomeIcon style={{color: colors.primaryText}} className="mr-2" icon={["fal", "mobile"]}/>
          <span className="mb-2 ml-1" style={{position: 'relative', bottom: 2, fontSize: 16}}>{t("messagesScreen.push")}</span>
        </>
      }
    </div>

  const MessagingEditCell = (cellData: any) => {
    const messageData = cellData.cell.value

    const onClick = () => {
      if (messageData.messageType === MESSAGE_TYPE.EMAIL) {
        props.history.push(
          SendEmailLocation.toUrl({...props.userSessionStore!.selectedBranch!.urlFields(), messageId: messageData.id}),
          {
            messageId: messageData.id
          }
        )
      } else {
        props.history.push(
          SendPushNotificationLocation.toUrl({...props.userSessionStore!.selectedBranch!.urlFields(), messageId: messageData.id}),
          {
            messageId: messageData.id
          }
        )
      }
    }

    return <div className="text-center" style={{color: colors.primaryText}}>
      <FontAwesomeIcon 
        onClick={onClick} 
        style={{color: colors.primaryText, cursor: 'pointer'}} 
        className="mr-2" 
        icon={["fal", "pen-to-square"]}/>
    </div>
  }

    // @ts-ignore
    function MessagingStatusCell(cellData) {
        const messageStatus = cellData.cell.value.status
        const messageSendTime = cellData.cell.value.sendTime
        const isDraft = messageStatus === MESSAGE_STATUS.DRAFT
        const draftStyles = isDraft ? {
            backgroundColor: colors.secondaryText,
            borderColor:  colors.secondaryText,
            color: colors.black
        } : {}

        const inReviewStyles = messageStatus === MESSAGE_STATUS.READY ? {
          borderColor: colors.black,
          color: colors.secondaryText,
          opacity: 1,
          backgroundColor: colors.boxFill,
          paddingLeft: 16,
          paddingRight: 16,
          borderWidth: 3
        } : {}

        const sentStyles =  messageStatus === MESSAGE_STATUS.SENT ? {
          borderColor: colors.primaryText,
          backgroundColor: colors.primaryText,
          color: colors.black,
          opacity: 0.75
        } : {}

        const processingStyles =  messageStatus === MESSAGE_STATUS.PROCESSING ? {
          borderColor: colors.primaryText,
          backgroundColor: colors.primaryText,
          color: colors.black,
          opacity: 0.6
        } : {}

        const text = () => {
          if (messageStatus === MESSAGE_STATUS.DRAFT) {
            return t("messagesScreen.draft")
          } else if (messageStatus === MESSAGE_STATUS.READY) {
            return t("messagesScreen.inReview")
          } else if (messageStatus === MESSAGE_STATUS.PROCESSING) {
            return t("messagesScreen.processing")
          } else if (messageStatus === MESSAGE_STATUS.SENT) {
            const nowTime = moment().utc()
            const isSendTimeInThePast = moment(messageSendTime).utc().isBefore(nowTime)
            if (isSendTimeInThePast) {
              return t("messagesScreen.sent")
            } else {
              return t("messagesScreen.scheduled")
            }
          }
        }
        return <div
        style={{
            display: 'inline-block',
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 16,
            ...draftStyles,
            ...inReviewStyles,
            ...sentStyles,
            ...processingStyles
        }}>
        {text()}
    </div>
    }

  const dateColumnValue = (row: any) => {
    if (row.status === MESSAGE_STATUS.DRAFT) {
      return '-'
    } else {
      return row.sendTime ? moment(row.sendTime).format("DD.MM.YYYY") : '-'
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: <span className="text-left d-inline-block" style={{width: '100%'}}>{t("messagesScreen.tableHeaders.title")}</span>,
        accessor: (row: any): any => row.messageType === 'EMAIL' ? row.payload.emailTitle : row.payload.message,
        sortType: "",
        id: "title",
        width: 350,
        sortInverted: false,
        canSort: false,
        Cell: MessagingTitleCell,
      },
      {
        Header: <div className="text-center">{t("messagesScreen.tableHeaders.venue")}</div>,
        accessor: (row: any): any =>  {
          const venueNames = row.configuration.venues
          .map((venueInfo: any) => resolveIdentifier(Venue, props.userSessionStore!.organizations, venueInfo.id) as IVenue)
          .map((venue: IVenue) => venue.name)
          .join(" ・ ");
           return venueNames
        },
        id: "venueName",
        canSort: false,
        sortInverted: false,
        disableSortBy: true,

        Cell: MessagingVenueNameCell,
      },
      {
        Header: <div className="text-center">{t("messagesScreen.tableHeaders.type")}</div>,
        accessor: (row: any): any =>  row.messageType,
        id: "image",
        canSort: false,
        sortInverted: false,
        disableSortBy: true,

        Cell: MessagingTypeCell,
      },
      {
        Header: t("messagesScreen.tableHeaders.date"),
        accessor: (row: any): any => dateColumnValue(row),
        sortType: "",
        id: "date",
        sortInverted: false,
        canSort: false,
        Cell: MessagingDateCell,
      },
      {
        Header: t("messagesScreen.tableHeaders.sentTo"),
        accessor: (row: any): any => <div>
          {row.status === MESSAGE_STATUS.DRAFT 
            ?
            <p className="mb-0 font-weight-normal" style={{color: colors.secondaryText, fontSize: 12}}>-</p>
            :
            <div className="mb-0 font-weight-normal" style={{color: colors.secondaryText, fontSize: 12}}><h5 className="text-primary mb-0">{row.sentToMembersCount}</h5>{t("messagesScreen.summary.totalMembersTitle")}</div>
          }
          
        </div>,
        sortType: "",
        id: "sentTo",
        sortInverted: false,
        canSort: false,
        Cell: MessagingDateCell,
      },
      {
        Header: <div className="text-center">{t("messagesScreen.tableHeaders.status")}</div>,
        accessor: (row: any): any => {
          console.log('row', row)
          return {status: row.status, sendTime: row.sendTime}
        },
        id: 'status',
        sortType: "basic",
        Cell: MessagingStatusCell,
        sortDescFirst: true,
      },
      {
        Header: <span className="text-left"></span>,
        accessor: (row: any): any =>  row,
        id: "edit",
        width: 60,
        canSort: false,
        sortInverted: false,
        disableSortBy: true,

        Cell: MessagingEditCell,
      },
    ],
    [t],
  )

  const isOrganizationPremium = props.userSessionStore?.currentOrganization?.isPremium

  const filteredColumnsByOrganization = (!props.isOrganizationContext ?
    columns.filter(column => column.id !== "venueName") :
    columns)

  const filteredColumnsByPremium = (!isOrganizationPremium ?
    filteredColumnsByOrganization.filter(column => column.id !== "edit") :
    filteredColumnsByOrganization)

  const filteredColumns = filteredColumnsByPremium

  const sortedByDateAsc = (arr: IMessage[]) => arr.slice().sort(function(a,b){
    // @ts-ignore
    return new Date(b.sendTime) - new Date(a.sendTime);
  });

  const sortedByDateDesc = (arr: IMessage[]) => arr.slice().sort(function(a,b){
    // @ts-ignore
    return new Date(a.sendTime) - new Date(b.sendTime);
  });

  const sortedMessages = (messages :IMessage[]) => {
    const processingMessages = messages.filter(message => message.status === MESSAGE_STATUS.PROCESSING)
    const draftMessages = messages.filter(message => message.status === MESSAGE_STATUS.DRAFT)
    const readyMessages = messages.filter(message => message.status === MESSAGE_STATUS.READY)
    const sentMessages = messages.filter(message => message.status === MESSAGE_STATUS.SENT)

    return [
      
      ...sortedByDateDesc(sentMessages),
      ...sortedByDateDesc(readyMessages),
      ...draftMessages.reverse(),
      ...sortedByDateDesc(processingMessages),
    ]
  }

  return (
    <RenderMessagingTable columns={filteredColumns} data={sortedMessages(props.data)} onDateFilterChange={props.onDateFilterChange}/>
  )
}

const MessagesTable = withRouter(inject("userSessionStore")(observer(MessagesTableComponent)))
export { MessagesTable }
