export const fetchAsBlob = (url: any) => fetch(url).then(response => response.blob());

export const convertBlobToBase64 = (blob: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onerror = reject;
  reader.onload = () => {
      resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});

// EXAMPLE USAGE:
// fetchAsBlob(imageBlob).then(convertBlobToBase64).then(async (response) => {
// })

export const urlToFile = (url: any, filename: any, mimeType: any) => {
  return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename,{type:mimeType});})
  );
}

export const centsToFullCurrencyUnits = (cents: number | undefined) => cents ? cents / 100 : 0
export const fullCurrencyUnitsToCents = (moneyUnits: number | undefined) => moneyUnits ? (moneyUnits * 100).toFixed() : 0

export const truncateText = (text: string | undefined, amountLettersToShow: number) => {
  if (text && text.length > amountLettersToShow) {
    return text.substring(0, amountLettersToShow) + "...";
  }

  return text
}