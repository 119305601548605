import { Instance, types } from "mobx-state-tree"

const Images = types.model("Images")
    .props({
      mainImage: types.maybeNull(types.string)
    })

export const Message = types.model("Message")
  .props({
    id: types.identifier,
    messageType: types.string,
    sendTime: types.string,
    status: types.string,
    configuration: types.maybe(types.model({  
      venues: types.array(types.model({
          id: types.string,
          statuses: types.array(types.model({
            id: types.maybe(types.string)
          }))
      }))
    })),
    isCustomTime: types.boolean,
    sentToMembersCount: types.number,
    payload: types.optional(types.model({
        message: types.maybe(types.string),
        images: types.maybeNull(Images),
        isCustomTime: types.maybe(types.boolean),
        toMembersInVenue: types.maybe(types.string),
        emailSubject: types.maybe(types.string),
        emailTitle: types.maybe(types.string),
        emailContent: types.maybe(types.string),
        logoURL: types.maybe(types.string),
        sentToMembersCount: types.maybe(types.number),
        topics: types.maybe(
            types.array(types.model({
                topicTitle: types.maybe(types.string),
                topicContent: types.maybe(types.string),
                imgURL: types.maybe(types.string),
                url: types.maybe(types.string)
            }))
        ),
    }), {}),
  }).views(self => ({

  })).actions(self => ({

  }))

export type IMessage = Instance<typeof Message>
