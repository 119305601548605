import {IUserSessionStore} from "../models/UserSessionStore"
import {inject, observer} from "mobx-react"
import React from "react"
import {WithTranslation, withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

export interface AlertMessageProps extends WithTranslation {
  userSessionStore?: IUserSessionStore
  source?: string
}

@inject("userSessionStore")
@observer
class AlertMessage extends React.Component<AlertMessageProps, {}> {
  render() {
    const {t, userSessionStore} = this.props
    const source = this.props.source

    if (userSessionStore!.alertMessage && (!source || source === userSessionStore!.alertSource)) {
      return (
        <div className="alert alert-danger mt-3 mb-3" role="alert">
          <FontAwesomeIcon className="mr-2" icon={["fal", "exclamation-circle"]}/>{t(userSessionStore!.alertMessage)}
        </div>
      )
    } else {
      return null
    }
  }
}
export default withTranslation()(AlertMessage)
