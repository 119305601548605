import {useEffect, useState} from 'react'
import {useHistory, useLocation} from "react-router";
import queryString from "query-string";

interface parsedFilters {
  [key: string]: any
}

export function useUrlFilter (field: string) {
  const [selected, setSelected] = useState()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    let queryStrings = queryString.parse(location.search)
    let parsedFilters: parsedFilters = {}
    if (queryStrings.filters) {
      parsedFilters = JSON.parse(queryStrings.filters as string)
    }
    setSelected(parsedFilters[field])
  }, [location.search, field])

  const setUrlFilter = (value: any) => {
    let queryStrings = queryString.parse(location.search)
    let parsedFilters: parsedFilters = queryStrings.filters ? JSON.parse(queryStrings.filters as string) : {}
    value ? parsedFilters[field] = value : delete parsedFilters[field]
    let modifiedQueryStrings = {
      ...queryStrings,
      filters: JSON.stringify(parsedFilters)
    }
    setSelected(JSON.parse(modifiedQueryStrings.filters)[field])
    // @ts-ignore
    if (modifiedQueryStrings.filters === "{}") delete modifiedQueryStrings.filters
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({...modifiedQueryStrings},{ encode: false })
    })
  }

  return {
    urlFilter: selected,
    setUrlFilter
  }
}
