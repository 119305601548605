import moment from 'moment-timezone';
import i18n from '../i18n';

export function timeAgo(dateString: string): string {
  const date = moment(dateString);
  const now = moment();
  const diffSeconds = now.diff(date, 'seconds');
  const diffMinutes = now.diff(date, 'minutes');
  const diffHours = now.diff(date, 'hours');
  const diffDays = now.diff(date, 'days');
  const diffWeeks = now.diff(date, 'weeks');
  const diffMonths = now.diff(date, 'months');
  const diffYears = now.diff(date, 'years');

  const t = i18n.t.bind(i18n);

  if (diffSeconds < 30) {
    return t('timeAgo.justNow');
  } else if (diffMinutes < 1) {
    return t('timeAgo.lessThanAMinute');
  } else if (diffMinutes === 1) {
    return t('timeAgo.aMinute');
  } else if (diffMinutes < 60) {
    return t('timeAgo.minutes', { count: diffMinutes });
  } else if (diffHours === 1) {
    return t('timeAgo.anHour');
  } else if (diffHours < 24) {
    return t('timeAgo.hours', { count: diffHours });
  } else if (diffDays === 1) {
    return t('timeAgo.yesterday');
  } else if (diffDays < 7) {
    return t('timeAgo.days', { count: diffDays });
  } else if (diffWeeks === 1) {
    return t('timeAgo.aWeek');
  } else if (diffWeeks < 4) {
    return t('timeAgo.weeks', { count: diffWeeks });
  } else if (diffMonths === 1) {
    return t('timeAgo.aMonth');
  } else if (diffMonths < 12) {
    return t('timeAgo.months', { count: diffMonths });
  } else if (diffYears === 1) {
    return t('timeAgo.aYear');
  } else {
    return t('timeAgo.years', { count: diffYears });
  }
}