import {Instance, getIdentifier, getRoot, getSnapshot, resolveIdentifier, types} from "mobx-state-tree"
import {Venue} from "./Venue"
import i18n from "../i18n"
import moment from "moment-timezone"
import { IVenueStatus, VenueStatus } from "./MemberStore"
import { IRootStore } from "./RootStore"

export enum BenefitFormat {
  OFFER = "OFFER",
  OFFER_WITH_PRICE = "OFFER_WITH_PRICE",
  undefined = "undefined",
}

export enum BenefitKind {
  ONE_TIME = "ONE_TIME",
  RECURRING = "RECURRING",
}

export enum BenefitActiveStatus {
  ACTIVE = "ACTIVE",
  INACTIVE_BENEFIT = "INACTIVE_BENEFIT",
  INACTIVE_VENUE = "INACTIVE_VENUE",
}

export enum BenefitInterval {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  ONE_TIME = "millennium",
}

export const DEFAULT_BENEFIT_ACTIVE_PERIOD_SECONDS = 5 * 60

const Images = types.model("Images")
  .props({
    mainImage: types.maybeNull(types.string)
  })
export const Benefit = types
  .model("Benefit")
  .props({
    id: types.identifier,
    restaurantId: types.reference(Venue),
    createdAt: types.string,
    editedAt: types.string,
    isHidden: types.optional(types.boolean, false),
    linkedVenueStatuses: types.optional(types.array(types.string), []),
    visibility: types.maybeNull(types.enumeration("Visibility", ["PERSONAL", "PUBLIC"])),
    kind: types.enumeration("kind", Object["values"](BenefitKind)),
    images: types.maybeNull(Images),
    format: types.enumeration("format", Object["values"](BenefitFormat)),
    content: types.optional(types.model({
      text: types.maybe(types.map(types.string)),
      price: types.maybe(types.map(types.string)),
    }), {}),
    style: types.optional(types.model({
      icon: types.optional(types.model({
        name: types.string,
        style: types.string,
      }), {name: "star", style: "solid"}),
    }), {}),
    rules: types.optional(types.model({
      interval: types.maybe(types.string),
      offset: types.maybe(types.string),
      activation: types.optional(types.number, DEFAULT_BENEFIT_ACTIVE_PERIOD_SECONDS),
    }), {
      interval: "day",
      offset: "5 hours",
      activation: DEFAULT_BENEFIT_ACTIVE_PERIOD_SECONDS,
    }),
    sortIndex: types.number,
  })
  .views(self => ({
    get sortScore() {
      if (self.kind === BenefitKind.RECURRING) return 0

      if (self.kind === BenefitKind.ONE_TIME) {
        if (self.rules.interval === BenefitInterval.DAY) return 1
        if (self.rules.interval === BenefitInterval.WEEK) return 2
        if (self.rules.interval === BenefitInterval.MONTH) return 3
        if (self.rules.interval === BenefitInterval.ONE_TIME) return 4
      }

      return 0
    },
    localeText(language: string | undefined = undefined) {
      const languageCode = language ? language : i18n.languages[0]
      const fallbackLanguage = self.content.text!.keys().next().value
      return self.content.text!.has(languageCode) ? self.content.text!.get(languageCode) : self.content.text!.get(fallbackLanguage)
    },
    localePrice(language: string | undefined = undefined) {
      const languageCode = language ? language : i18n.languages[0]
      const fallbackLanguage = self.content.price!.keys().next().value
      return self.content.price!.has(languageCode) ? self.content.price!.get(languageCode) : self.content.price!.get(fallbackLanguage)
    },
    getActiveStatus() {
      if (self.isHidden) return BenefitActiveStatus.INACTIVE_BENEFIT
      if (self.restaurantId.isHidden) return BenefitActiveStatus.INACTIVE_VENUE
      return BenefitActiveStatus.ACTIVE
    },
    getInterval(): string {
      if (self.kind === BenefitKind.RECURRING) {
        return BenefitKind.RECURRING
      } else {
        return self.rules.interval!
      }
    },
  })).views(self => ({
    localeTitle(language: string | undefined = undefined) {
      const languageCode = language ? language : i18n.languages[0]
      const text = self.localeText(languageCode)
      const price = self.format === BenefitFormat.OFFER_WITH_PRICE ? self.localePrice(languageCode) : ""
      return text + (price ? " " + price : "")
    },
  })).actions(self => ({
    setText(locale: string, newText: string) {
      self.content!.text!.set(locale, newText)
    },
    setMainImage(imgURL: string) {
      self.images = {...self.images, mainImage: imgURL}
    },
    setPrice(locale: string, newPrice: string) {
      if (newPrice.length > 0) {
        if (!self.content!.price) {
          // @ts-ignore
          self.content!.price = {}
        }
        self.content!.price!.set(locale, newPrice)
        self.format = BenefitFormat.OFFER_WITH_PRICE
      } else {
        self.format = BenefitFormat.OFFER
        self.content!.price = undefined
      }
    },
    setIcon(iconName: string) {
      self.style.icon.name = iconName
    },
    setInterval(interval: string) {
      if (interval === BenefitKind.RECURRING) {
        self.kind = BenefitKind.RECURRING
        self.rules.interval = undefined
      } else {
        self.kind = BenefitKind.ONE_TIME
        self.rules.interval = interval
      }
      self.editedAt = moment.utc().toISOString()
    },
    setIsActive(active: boolean) {
      self.isHidden = !active
      self.editedAt = moment.utc().toISOString()
    },
    // setLinkedVenueStatuses(newVenueStatuses: IVenueStatus[]) {
    //   // Clear the current array
    //   self.linkedVenueStatuses.clear();

    //   // Iterate over the newVenueStatuses and push their identifiers
    //   newVenueStatuses.forEach(venueStatus => {
    //     const venueStatusId = getIdentifier(venueStatus);
    //     if (venueStatusId) {
    //       self.linkedVenueStatuses.push(venueStatusId);
    //     }
    //   });
    // },
    setLinkedVenueStatuses(statusIds: string[]) {
      self.linkedVenueStatuses.replace(statusIds);
    },
    // setLinkedVenueStatuses(statuses: IVenueStatus[]) {
    //   self.linkedVenueStatuses.clear();
    //   console.log('statuses', statuses)
    //   console.log('self.linkedVenueStatuses BEFORE', self.linkedVenueStatuses)
    //   statuses.forEach(status => {
    //     self.linkedVenueStatuses.push(status);
    //   });
    // },
    // setLinkedVenueStatuses(statuses: IVenueStatus[]) {
    //   const venueStatuses = (getRoot(self) as IRootStore).dataStore.memberStore.venueStatuses
    //   self.linkedVenueStatuses.replace(statuses);
    // },
  //   setLinkedVenueStatuses(statusIds: string[]) {
  //     console.log('self.linkedVenueStatuses BEFORE', self.linkedVenueStatuses)
  //     console.log('statusIds MOBX', statusIds)
  //     const resolvedStatuses = statusIds
  //         .map(id => resolveIdentifier(VenueStatus, self, id))
  //         .filter((status): status is IVenueStatus => status !== undefined);

  //         console.log('resolvedStatuses', resolvedStatuses)
  //     self.linkedVenueStatuses.replace(resolvedStatuses);
  //     console.log('self.linkedVenueStatuses AFTER', self.linkedVenueStatuses)
  // },
  }))

export type IBenefit = Instance<typeof Benefit>
