import log from "loglevelnext"
import { inject, observer } from "mobx-react"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from 'react-bootstrap/Modal'
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MerchantAdminApi } from "../../Api"
import { EmailOctopusApiKeyLocation } from "../../Locations"

export interface ChangeEmailOctopusPlanModalProps extends RouteComponentProps, WithTranslation {
  api?: MerchantAdminApi
  showModal?: boolean
  setShowModal?: any
  userSessionStore?: any
}

const UPGRADE_TO_PREMIUM_MODAL_ERROR = 'UPGRADE_TO_PREMIUM_MODAL_ERROR'

// @ts-ignore
const ChangeEmailOctopusPlanModal = (props: ChangeEmailOctopusPlanModalProps) => {
    const {t, showModal, setShowModal, api, userSessionStore} = props
    const premiumPerks = [
        t("emailOctopus.connectModal.feature1"),
        t("emailOctopus.connectModal.feature2"),
        t("emailOctopus.connectModal.feature3"),
    ]
    const [showThanksModal, setShowThanksModal] = useState(false);

  const onSubmitPushNotification = async (e: any) => {
    e.preventDefault()

    const currentVenue = userSessionStore!.selectedBranch
    const text = `Venue: ${currentVenue.name} clicked on the Upgrade to Premium`

    try {
      
      const requestSuccess = await api!.sendSimplePushNotificationRequest(
        text,
        undefined,
        UPGRADE_TO_PREMIUM_MODAL_ERROR,
      )

      if (requestSuccess) {
        setShowThanksModal(true)
        return
      }

    } finally {

    }

    log.debug("UpgradeToPremiumModal: Error while sending push notification")
  }


  return <Modal show={showModal} centered onHide={() => {
    setShowModal(false)
  }}>
      <Modal.Header closeButton>
      <Modal.Title className="text-center" style={{width: '100%'}}>
          <div className="font-weight-bold text-center">{t("emailOctopusManageScreen.changePlanModal.title")}</div>
      </Modal.Title>
      </Modal.Header>
      <Modal.Body  className="text-center">
        <div className="mt-3 font-weight-bold" style={{color: "#333", fontSize: 18, textAlign: 'center'}}>{t("emailOctopusManageScreen.changePlanModal.freeOrPro")}</div>

        <div className="mt-3" style={{color: "#333", fontSize: 18, textAlign: 'center'}}>{t("emailOctopusManageScreen.changePlanModal.changePlanDescription")}</div>


        <div className="text-center mt-4 font-italic" style={{margin: '0px auto', fontSize: 14}}>{t("emailOctopus.connectModal.recommendation")}</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
          <Button variant="primary pl-4 pr-4" onClick={(e) => {
            window.open("mailto:info@cluby.com")
          }}>
            <h4>{t("emailOctopusManageScreen.changePlanModal.button")}</h4>
          </Button>
      </Modal.Footer>
  </Modal>
}

export default withRouter(withTranslation()(inject("api", "userSessionStore")(observer(ChangeEmailOctopusPlanModal))))