import {inject} from "mobx-react"
import React from "react"

export interface SuccessMessageProps {
  message?: string
  show?: boolean
}

@inject("userSessionStore")
export class SuccessMessage extends React.Component<SuccessMessageProps, {}> {
  render() {
    const message = this.props.message
    const show = this.props.show

    return (
      <>
        {message && show ?
          <div className="alert alert-success" role="alert">
            {message}
          </div>
          :
          null
        }
      </>
    )
  }
}
