import { SummaryItem } from "../../components/SummaryHeader";
import { IEventStore } from "../../models/EventStore";
import { IVenueStatusesStats } from "../../models/MemberStore";
import { toJS } from 'mobx';

const parseNumericValue = (value: any): number => {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    const cleanedValue = value.replace(/[^\d.-]/g, '');
    const parsedValue = parseFloat(cleanedValue);
    return isNaN(parsedValue) ? 0 : parsedValue;
  }
  return 0;
};

export const sortSummariesByValueDescending = (summaries: SummaryItem[]): SummaryItem[] => {
  return [...summaries].sort((a, b) => {
    const aValue = parseNumericValue(a.value);
    const bValue = parseNumericValue(b.value);
    return bValue - aValue;
  });
};

export const isVenueStatusStatArray = (arr: any): arr is IVenueStatusesStats[] => {
    return Array.isArray(arr) && arr.every(item => 
      item && typeof item === 'object' && 'id' in item && 'membersCount' in item
    );
  }


  export const isSummaryShowable = (summary: SummaryItem): boolean => {
    const value = parseFloat(summary.value);
    return !isNaN(value) && value !== 0;
  };


  export const getEventsOrganizationSummary = ({eventStore, field}: {eventStore: IEventStore, field: string}) => {
    const summaries = eventStore?.summaries ? toJS(eventStore.summaries) : new Map();
    
    return Array.from(summaries.values()).reduce((sum, item) => 
    sum + (typeof item[field] === 'number' ? item[field] : 0), 0);
  };