import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import "./i18n"
import * as serviceWorker from "./serviceWorker"
import "./theme.scss"
import {MerchantAdminApi} from "./Api"
import {Provider} from "mobx-react"
import {Route, Router, Switch} from "react-router-dom"
import {createBrowserHistory} from "history"
import {RootStore} from "./models/RootStore"
import {UserSessionStore} from "./models/UserSessionStore"
import {BenefitStore} from "./models/BenefitStore"
import {ProductStore} from "./models/ProductStore"
import {BenefitReportStore} from "./models/BenefitReportStore"
import {ProductReportStore} from "./models/ProductReportStore"

import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react"

import {MemberStore} from "./models/MemberStore"
import {autorun} from "mobx"
import log from "loglevelnext"
import {EventStore} from "./models/EventStore"
import {EventReportStore} from "./models/EventReportStore"
import { MessageStore } from "./models/MessageStore"
import { InvitationsStore } from "./models/InvitationsStore"

import makeInspectable from 'mobx-devtools-mst';
import { RewardStore } from "./models/RewardStore"
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || "",
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
  appVersion: process.env.REACT_APP_VERSION,
})

let API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:5000/partner"
// const API_URL = 'https://api.alexander.developer.cluby.dev/partners'
// const API_URL = 'https://api.nikita.developer.cluby.dev/partners'
// const API_URL = 'https://api.joel.developer.cluby.dev/partners'
// const API_URL = 'https://api.cluby.dev/partners'
// const API_URL = 'https://pr-426.deployment.cluby.dev/partners'

async function init() {
  log.level = LOG_LEVEL
  console.log("Current LOG_LEVEL is " + log.level)

  if (process.env.REACT_APP_RELEASE_STAGE !== "production") {
    document.title = "DEVELOPMENT! Cluby Partner Hub"
  }

  const userSessionStore = UserSessionStore.create()
  const merchantAdminApi = new MerchantAdminApi(API_URL, userSessionStore)
  const apiInjection = {api: merchantAdminApi}
  const genericStoreInjection = {...apiInjection, userSessionStore: userSessionStore}

  // @ts-ignore
  const rootStore = RootStore.create({
    // @ts-ignore
    userSessionStore: userSessionStore,
    dataStore: {
      // @ts-ignore
      productStore: ProductStore.create({}, genericStoreInjection),
      // @ts-ignore
      rewardStore: RewardStore.create({}, genericStoreInjection),
      // @ts-ignore
      benefitStore: BenefitStore.create({}, genericStoreInjection),
      // @ts-ignore
      benefitReportStore: BenefitReportStore.create({}, genericStoreInjection),
      // @ts-ignore
      productReportStore: ProductReportStore.create({}, genericStoreInjection),
      // @ts-ignore
      memberStore: MemberStore.create({}, genericStoreInjection),
      // @ts-ignore
      messageStore: MessageStore.create({}, genericStoreInjection),
      // @ts-ignore
      invitationsStore: InvitationsStore.create({}, genericStoreInjection),
      // @ts-ignore
      eventStore: EventStore.create({}, genericStoreInjection),
      // @ts-ignore
      eventReportStore: EventReportStore.create({}, genericStoreInjection),
    },
  }, genericStoreInjection)

  makeInspectable(rootStore);

  autorun(() => {
    // Dummy observers to keep report data in cache
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dummyMemberStoreObserver = rootStore.dataStore.memberStore.filteredReport

    log.debug("Member report update observer triggered")
    // update stats when report data changes
    rootStore.dataStore.memberStore.updateReport()
  })

  autorun(() => {
    // Dummy observers to keep report data in cache
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dummyBenefitReportStoreObserver = rootStore.dataStore.benefitReportStore.filteredReportDefault
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dummyBenefitReportStoreObserverAnother = rootStore.dataStore.benefitReportStore.filteredReport

    log.debug("Benefit report update observer triggered")
    // update stats when report data changes
    rootStore.dataStore.benefitReportStore.updateReport()
  })

  autorun(() => {
    // Dummy observers to keep report data in cache
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dummyProductReportStoreObserver = rootStore.dataStore.productReportStore.filteredReportDefault
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dummyProductReportStoreObserverAnother = rootStore.dataStore.productReportStore.filteredReport

    log.debug("Product report update observer triggered")
    // update stats when report data changes
    rootStore.dataStore.productReportStore.updateReport()
  })


  const customHistory = createBrowserHistory({
    getUserConfirmation: (message, callback) => {
      if (message === "REPLACE") {
        log.debug("Router REPLACE action (getUserConfirmation)")

        if (rootStore!.appStatus.hasPreloadedData) {
          rootStore!.loadPreloadData()
        }
      }
      callback(true)
    }
  })

  let releaseHistoryBlock: any

  customHistory.listen((location, action) => {
    if (action === "PUSH") {
      log.debug("Router PUSH action:", location)

      if (rootStore!.appStatus.hasPreloadedData) {
        rootStore!.loadPreloadData()
      }

      rootStore.dataStore.benefitReportStore.setDateFilter(null)
      rootStore.dataStore.productReportStore.setDateFilter(null)
      rootStore.userSessionStore.clearAlertMessage()
      initHistoryBlock()
    }
  })

  const initHistoryBlock = () => {
    if (releaseHistoryBlock) releaseHistoryBlock()
    releaseHistoryBlock = customHistory.block((location, action) => {
      return action
    })
  }
  initHistoryBlock()

  const otherStores = {
    api: merchantAdminApi,
    userSessionStore: rootStore.userSessionStore,
    benefitStore: rootStore.dataStore.benefitStore,
    productStore: rootStore.dataStore.productStore,
    rewardStore: rootStore.dataStore.rewardStore,
    loyaltyCardStore: rootStore.dataStore.loyaltyCardStore,
    benefitReportStore: rootStore.dataStore.benefitReportStore,
    productReportStore: rootStore.dataStore.productReportStore,
    memberStore: rootStore.dataStore.memberStore,
    messageStore: rootStore.dataStore.messageStore,
    invitationsStore: rootStore.dataStore.invitationsStore,
    eventStore: rootStore.dataStore.eventStore,
    eventReportStore: rootStore.dataStore.eventReportStore,
  }

  const plugin = Bugsnag.getPlugin("react") as BugsnagPluginReactResult
  const ErrorBoundary = plugin.createErrorBoundary(React)

  ReactDOM.render(
    <ErrorBoundary>
      <Provider rootStore={rootStore} {...otherStores}>
        <Router history={customHistory}>
          <Switch>
            <Route path="/manage/*/org/:organizationId(\w+)" component={App}/>
            <Route path="/manage/*/venue/:venueId(\w+)" component={App}/>
            <Route path="/manage/*/group/:groupId(\w+)" component={App}/>
            <Route path="/" component={App}/>
          </Switch>
        </Router>
      </Provider>
    </ErrorBoundary>,
    document.getElementById("root"),
  )
}

//const LOG_LEVEL = "INFO"
const LOG_LEVEL = process.env.REACT_APP_BRANCH === "master" ? "INFO" : "DEBUG"

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
init()
serviceWorker.unregister()
