import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import React from "react"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import AlertMessage from "../components/AlertMessage"
import { ProductsTable } from "../components/ProductsTable"
import { SummaryHeader, SummaryItem } from "../components/SummaryHeader"
import { ProductModifyLocation, ProductsModifyLocation } from "../Locations"
import { IBenefitReportStore } from "../models/BenefitReportStore"
import { IProductReportStore } from "../models/ProductReportStore"
import { IProductStore } from "../models/ProductStore"
import { IRootStore } from "../models/RootStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import { IVenue } from "../models/Venue"
import { trackEvent } from "../tracking"
import trackingConstants from "../tracking/trackingConstants"

const PRODUCTS_SCREEN_ERROR = "productsScreen"

export interface ProductsScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  benefitReportStore?: IBenefitReportStore
  productStore?: IProductStore
  productReportStore?: IProductReportStore
  rootStore?: IRootStore
}

@(withRouter as any)
@inject("userSessionStore")
@inject("productStore")
@inject("productReportStore")
@inject("benefitReportStore") // TODO: replace it with productsReportStore
@observer
class ProductsScreen extends React.Component<ProductsScreenProps> {

  handleDateFilter(this: this, selection: { startDate: Date, endDate: Date } | null) {
    this.props.benefitReportStore!.setDateFilter(selection)
  }

  componentDidMount(): void {
    trackEvent(trackingConstants.screens.benefits, {selectedBranch: this.props.userSessionStore!.selectedBranch!.name})
  }

  navToProductModifyScreen(venue: IVenue) {
    this.props.history.push(ProductModifyLocation.toUrl({...venue.urlFields(), productId: "NEW"}))
  }

  navToProductsModifyScreen(venue: IVenue) {
    this.props.history.push(ProductsModifyLocation.toUrl(venue.urlFields()))
  }

  render() {
    const {userSessionStore, productReportStore, productStore, t} = this.props

    const centsToFullCurrencyUnit = (cents: number | undefined) => cents ? cents / 100 : 0

    const summaries: Array<SummaryItem> = [
      {
        title: t("productsScreen.productsSold"),
        value: String(productReportStore?.reporting.soldTotal),
        footer: t("productsScreen.total"),
      },
      {
        title: t("productsScreen.productsSoldRecently"),
        value: String(productReportStore?.reporting.sold30Days),
        footer: t("productsScreen.last30Days"),
      },
      {
        title: t("productsScreen.sales"),
        value: `${centsToFullCurrencyUnit(productReportStore?.reporting.revenueTotal)} €`,
        footer: t("productsScreen.total"),
      },
      {
        title: t("productsScreen.recentSales"),
        value: `${centsToFullCurrencyUnit(productReportStore?.reporting.revenue30Days)} €`,
        footer: t("productsScreen.last30Days"),
      },
    ]
    const headerButtons = (
      <>
        {userSessionStore!.isOrganizationContext() ?
          <Dropdown
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
            className="mr-2"
          >
            <Dropdown.Toggle variant="secondary" id="dropdown-edit-products-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "pen-to-square"]}/>{t("productsScreen.modifyProducts")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                                 onClick={this.navToProductsModifyScreen.bind(this, venue)}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
            className="mr-2"
            onClick={this.navToProductsModifyScreen.bind(this, userSessionStore!.selectedBranch! as IVenue)}
            variant="secondary"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "pen-to-square"]}/>{t("productsScreen.modifyProducts")}
          </Button>}

        {userSessionStore!.isOrganizationContext() ?
          <Dropdown
          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
          onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown.Toggle variant="success" id="dropdown-edit-products-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "star"]}/>{t("productsScreen.addProduct")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                                 onClick={this.navToProductModifyScreen.bind(this, venue)}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
            onClick={this.navToProductModifyScreen.bind(this, userSessionStore!.selectedBranch! as IVenue)}
            variant="success"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "star"]}/>{t("productsScreen.addProduct")}
          </Button>}

      </>
    )

    const venueNames = userSessionStore!.currentOrganization!.venues.map(venue => ({
      id: venue.id,
      name: venue.name
    }))

    return (
      <div>
        <AlertMessage source={PRODUCTS_SCREEN_ERROR}/>
        <SummaryHeader summaries={summaries} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} buttons={headerButtons}/>
        <ProductsTable data={productStore?.nonRewardProducts()}
                       productReportStore={productReportStore}
                       onDateFilterChange={this.handleDateFilter.bind(this)} 
                       venueNames={venueNames} 
                       selectedVenueId={userSessionStore?.selectedBranch?.id}
                       isOrganizationContext={userSessionStore!.isOrganizationContext()}/>
      </div>
    )
  }
}

export default withTranslation()(ProductsScreen)
