import React from 'react';
import './TruncateText.css';


export function truncateString(str: string, maxLength: number) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  }

const TruncateText = ({
  text,
  numberOfLines,
  gradientColor,
}: {
  text: string;
  numberOfLines?: number;
  gradientColor?: string;
}) => {
  const truncateStyle = {
    WebkitLineClamp: numberOfLines,
  };

  const gradientStyle = {
    background: `linear-gradient(180deg, transparent, ${gradientColor})`,
  };

  return (
    <div className="truncate" style={truncateStyle}>
      {text}
      <div className="truncate::after" style={gradientStyle}></div>
    </div>
  );
};

export default TruncateText;
