const DATA_UNAVAILABLE = "N/A";

export const GENDER_DEMOGRAPHY_ORDER = ['male', 'female', 'non-binary', 'other', 'unknown'];
export const AGE_RANGE_DEMOGRAPHY_ORDER = ['<20', '20-29', '30-39', '40-49', '50+'];

export const formatPercentagesForDemographics = ({
  data,
  keyOrder
}: {
  data?: { [key: string]: number },
  keyOrder: string[]
}): { [key: string]: string } => {
  if (!data) {
    return {};
  }

  const total = Object.values(data).reduce((sum, value) => sum + value, 0);
  
  if (total === 0) {
    return Object.fromEntries(keyOrder.map(key => [key, DATA_UNAVAILABLE]));
  }

  const initialPercentages: [string, number][] = keyOrder.map(key => [
    key,
    Math.round(((data[key] || 0) / total) * 100)
  ]);

  const adjustedPercentages = adjustPercentagesToEnsureTotal100({percentages: initialPercentages});
  
  return Object.fromEntries(adjustedPercentages.map(([key, value]) => [key, `${value}%`]));
};
const adjustPercentagesToEnsureTotal100 = ({percentages}: {percentages?: [string, number][]}): [string, number][] => {
  if (!percentages) {
    return [];
  }

  let total = percentages.reduce((sum, [, value]) => sum + value, 0);
  const adjusted = [...percentages];

  while (total !== 100 && adjusted.length > 0) {
    const indexToAdjust = total < 100
      ? adjusted.findIndex(([, value]) => value > 0)
      : adjusted.findIndex(([, value]) => value > 1);

    if (indexToAdjust === -1) break;

    const adjustment = total < 100 ? 1 : -1;
    adjusted[indexToAdjust][1] += adjustment;
    total += adjustment;
  }

  return adjusted;
};