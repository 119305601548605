import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject } from "mobx-react"
import React, { RefObject } from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { SelectGroup, TextInput, ValidationForm } from "react-bootstrap4-form-validation"
import { WithTranslation, withTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MerchantAdminApi } from "../Api"
import AlertMessage from "../components/AlertMessage"
import { SuccessMessage } from "../components/SuccessMessage"
import { CreateOrganizationLocation } from "../Locations"
import { IOrganization } from "../models/Organization"
import { IUserSessionStore } from "../models/UserSessionStore"
import { trackEvent } from "../tracking"
import trackingConstants from "../tracking/trackingConstants"

export interface InviteUserFormProps {
  userSessionStore?: IUserSessionStore
  api?: MerchantAdminApi
  t: WithTranslation["t"]
}

type InviteUserFormState = {
  buttonPressed: boolean
  inviteSent: boolean
  formData: { [index: string]: string }
}


const INVITE_USER_FORM_ERROR = "inviteUserForm"


@inject("userSessionStore")
@inject("api")
class InviteUserForm extends React.Component<InviteUserFormProps, InviteUserFormState> {
  formRef: RefObject<any>

  constructor(props: InviteUserFormProps) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      buttonPressed: false,
      inviteSent: false,
      formData: {
        email: "",
        fullName: "",
        organization: "",
      },
    }
  }

  async onFormSubmit(e: any, formData: any, inputs: any) {
    e.preventDefault()
    this.setState({buttonPressed: true})
    try {
      const sendInviteResponse: any = await this.props.api!.sendAdminInvite(formData.email, formData.fullName, formData.organization, INVITE_USER_FORM_ERROR)
      if (!sendInviteResponse.ok) {
        return
      }

      this.setState({
        inviteSent: true,
        formData: {
          email: "",
          fullName: "",
          organization: "",
        },
      })
      this.formRef.current.resetValidationState(true)
    } finally {
      this.setState({buttonPressed: false})
    }
  }

  handleChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    this.setState(prevState => ({
      inviteSent: false,
      formData: {
        ...prevState.formData,
        [target.name]: target.value,
      },
    }))
  }
  

  render() {
    const {t} = this.props
    
    return (
      <>
        <SuccessMessage show={this.state.inviteSent} message={t("superAdminScreen.inviteUserForm.successMessage")}/>
        <AlertMessage source={INVITE_USER_FORM_ERROR}/>

        <ValidationForm onSubmit={this.onFormSubmit.bind(this)} ref={this.formRef}>
          <fieldset disabled={this.state.buttonPressed}>
            <div className="form-group row">
              <div className="col-md-12 text-left">
                <label htmlFor="email">{t("superAdminScreen.inviteUserForm.email")}</label>
                <TextInput name="email" id="email" required
                           type="email"
                           autoComplete="off"
                           value={this.state.formData.email}
                           errorMessage={{
                             validator: t("validationErrorPassphrase"),
                             type: t("validationErrorEmail"),
                             required: t("fieldRequired")
                           }}
                           onChange={this.handleChange}
                           successMessage={t("validationSuccess")}/>
              </div>

              <div className="col-md-12 text-left">
                <label htmlFor="fullName">{t("superAdminScreen.inviteUserForm.fullName")}</label>
                <TextInput name="fullName" id="fullName" required
                           type="text"
                           autoComplete="off"
                           value={this.state.formData.fullName}
                           errorMessage={{
                             validator: t("validationErrorPassphrase"),
                             type: t("validationErrorEmail"),
                             required: t("fieldRequired")
                           }}
                           onChange={this.handleChange}
                           successMessage={t("validationSuccess")}/>
              </div>

              <div className="col-md-12 text-left">
                <label htmlFor="organization">{t("superAdminScreen.inviteUserForm.organization")}</label>
                <SelectGroup name="organization" id="organization"
                             required>
                  <option value="">{t("superAdminScreen.inviteUserForm.selectOrganization")}</option>
                  {this.props.userSessionStore!.organizations.map((organization: IOrganization) => {
                    return (
                      <option key={organization.id} value={organization.id}>{organization.name}</option>
                    )
                  })}
                </SelectGroup>
              </div>
              <div className="col-lg-5 mt-4">
                <button ref="loginButton"
                        className="btn-block btn btn-primary">{t("superAdminScreen.inviteUserForm.sendInviteButton")}</button>
              </div>
            </div>
          </fieldset>
        </ValidationForm>
      </>
    )
  }

}


export interface SuperAdminScreenProps extends RouteComponentProps, RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
}

@(withRouter as any)
@inject("userSessionStore")
class SuperAdminScreen extends React.Component<SuperAdminScreenProps> {
  componentDidMount(): void {
    trackEvent(trackingConstants.screens.profile)
  }

  render() {
    const {t} = this.props

    return (
      <>
        <Row className="justify-content-center">
          <Col sm={6}>
            <Card className="mt-4">
              <Card.Header as="h5">{t("superAdminScreen.inviteUserForm.title")}</Card.Header>
              <Card.Body>
                <Container>
                  <InviteUserForm t={t}/>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        
      </>
    )
  }
}

export default withTranslation()(SuperAdminScreen)
