import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as _ from "lodash"
import moment from "moment-timezone"
import React, { useEffect } from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Table from "react-bootstrap/Table"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { useFilters, useSortBy, useTable } from "react-table"
import { useUrlFilter } from "../hooks/FilterHooks"
import { BenefitActiveStatus, BenefitKind, IBenefit } from "../models/Benefit"
import { IBenefitReport } from "../models/BenefitReport"
import { IVenue } from "../models/Venue"
import { DateRangeDropdown } from "./DateRangeDropdown"
import { colors } from "../theme/colors"

// @ts-ignore
function DateCell(cellData) {
  const value = cellData.cell.value
  const stringValue = value ? moment.utc(value).tz("Europe/Helsinki").format("DD.MM.YYYY") : "-"
  return <div className="text-left">{stringValue}</div>
}

// @ts-ignore
function ActivationsCell(cellData) {
  const value = cellData.cell.value
  return (
    <div className="d-flex text-center flex-column align-items-center justify-content-center p-0 m-0">
          <div className="p-0 m-0 text-primary font-heading font-size-large">{value}</div>
    </div>
  )
  //return <div className="m-0 border w-50 d-inline-block border-primary rounded border-1 p-0 pb-2 text-center font-size-large text-primary">{value}</div>
}

// @ts-ignore
function BenefitTextCell(cellData) {
  const benefit = cellData.cell.value
  return <div className="font-heading text-left" style={{
    fontWeight: benefit.getActiveStatus() === BenefitActiveStatus.ACTIVE  ? 'bold' : 'normal'
  }}>{benefit.localeTitle()}</div>
}

// TODO: Replace it with image from the backend
// @ts-ignore
const BenefitImageCell = (cellData) => cellData.cell.value ? 
  <img style={{width: 90, height: 54, objectFit: 'cover', borderRadius: '5px'}} src={cellData.cell.value}/> 
  : <div/>

// @ts-ignore
const BenefitTypeCell = (cellData) => 
  <div className="d-flex flex-column text-primary p-0 pl-1">
    <div className="pt-1 text-center small">{cellData.cell.value}</div>
  </div>

// @ts-ignore
function VenueCell(cellData) {
  const value: IVenue = cellData.cell.value
  return <div className="text-menugray">{value.name}</div>
}

// @ts-ignore
function BenefitStatusCell(cellData) {
 // const {t} = useTranslation()

  const value = cellData.cell.value
  //return t("benefit.activeStatus." + value)
  return (
    <div className="text-center">
      {value === BenefitActiveStatus.ACTIVE ?
      <div style={{
        display: 'inline-block',
        height: 23,
        width: 23,
        background: colors.green,
        borderRadius: '50%'
      }}>
          <FontAwesomeIcon style={{
            color: colors.white,
            fontSize: 18
          }} icon={["fal", "check"]}/>
        </div>
        :
        <FontAwesomeIcon className="text-hentopinkki icon-medium" icon={["fal", "minus-circle"]}/>
      }
    </div>
  )
}

// @ts-ignore
function RenderBenefitTable({columns, data, onDateFilterChange}) {
  const {t} = useTranslation()
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{id: "usedTotal", desc: true}],
        hiddenColumns: ["uniqueUsers", "lastUsed"],
      },
      autoResetFilters: false,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
  )

  const activeCount = _.sumBy(rows, function (o: any) {
    return o.original.benefitId!.getActiveStatus() === BenefitActiveStatus.ACTIVE
  })
  const inactiveCount = rows.length - activeCount

  // @ts-ignore
  return (
    <>
      <Card className="mb-4 p-0">
        {/*        <Card.Header as="h6"><FontAwesomeIcon className="mr-2"
                                              icon={["fal", "filter"]}/>{t("benefitScreen.sections.filters")}
        </Card.Header>*/}
        <Card.Body className="px-0 py-3">
          <Container>
            <Row>
              <Col className="text-left" style={{width: "33%"}}><DateRangeDropdown onSelect={onDateFilterChange}/></Col>
              {headerGroups.map(headerGroup => (
                headerGroup.headers.map((column: any) => {
                 return column.canFilter && column.Filter ?
                      <React.Fragment key={column.id}>{column.render("Filter")}</React.Fragment> : null
                })
              ))}

            </Row>
          </Container>

        </Card.Body>
      </Card>

      <div className="mb-2 text-center font-size-medium font-heading font-weight-light">
        {t("benefitScreen.tableRowSummary", {
          totalCount: rows.length,
          activeCount: activeCount,
          inactiveCount: inactiveCount,
        })}
      </div>

      <Card className="m-0" style={{borderWidth: 0, borderColor: colors.boxFill}}>
        <div className="rounded-sm">
          <Table className="benefitTable p-0 m-0" striped hover {...getTableProps()}>
            <thead className="text-primary" style={{backgroundColor: ""}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} style={{
                MozUserSelect: "none",
                WebkitUserSelect: "none",
              }}>
                {headerGroup.headers.map(column => {
                  // @ts-ignore
                  const {style} = column.getHeaderProps(column.getSortByToggleProps())
                  return (
                    // @ts-ignore
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{
                      MozUserSelect: "none",
                      WebkitUserSelect: "none",
                      whiteSpace: "nowrap",
                      width: column.width,
                      borderWidth: 0,
                      ...style,
                    }}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                    {column.canSort ?
                      column.isSorted
                      ? column.isSortedDesc
                        ? <FontAwesomeIcon className="ml-2" icon={["fas", "caret-down"]}/>
                        : <FontAwesomeIcon className="ml-2" icon={["fas", "caret-up"]}/>
                      : <FontAwesomeIcon className=" ml-2" icon={["fal", "caret-down"]}/>
                    : null}
                  </span>
                    </th>
                  )
                })}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      )
                    })}
                  </tr>
                )
              },
            )}
            </tbody>
          </Table>
        </div>
      </Card>
    </>
  )
}

// @ts-ignore
function VenueMultiselectFilter({column: {filterValue, setFilter, preFilteredRows, id}}) {
  const {t} = useTranslation()
  const {urlFilter, setUrlFilter} = useUrlFilter("venues")
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options: Array<{ value: string, label: string }> = []
    preFilteredRows.forEach((row: any) => {
      const venue: IVenue = row.values[id]
      const venueOption = {value: venue.id!, label: venue.name!}
      if (!_.some(options, (item: any) => _.isEqual(item, venueOption))) {
        options.push(venueOption)
      }
    })
    // @ts-ignore
    return [...options.values()]
  }, [id, preFilteredRows])

  let values = options.filter((option) => {
    // @ts-ignore
    return urlFilter && urlFilter!.indexOf(option.value) !== -1
  })

  useEffect(() => {
    setFilter(urlFilter)
    // eslint-disable-next-line
  }, [urlFilter])

  return (
    <Col style={{width: "33%"}}>
      <div>
        <Select
          placeholder={t("benefitScreen.filters.selectVenues")}
          onChange={(entry: any) => {
            const values = entry && entry.length > 0 ? entry.map((o: any) => {
              return o.value
            }) : undefined
            setFilter(values)
            setUrlFilter(values)
          }}
          isMulti={true}
          options={options}
          value={values}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
    </Col>
  )
}

// @ts-ignore
function StatusFilter({column: {filterValue, setFilter, preFilteredRows, id}}) {
  const {t} = useTranslation()
  const {urlFilter, setUrlFilter} = useUrlFilter("status")

  useEffect(() => {
    setFilter(urlFilter)
    // eslint-disable-next-line
  }, [urlFilter])

  return (
      <Col style={{width: "33%"}}>
        <div>
          <Nav className="justify-content-end mr-0 ml-0" variant="pills" activeKey={urlFilter || "all"}
               // @ts-ignore
               onSelect={(item: string) => {
            setFilter(item)
            setUrlFilter(item)
          }}>
            <div className="d-flex flex-row border rounded border-primary overflow-hidden ">
            <Nav.Item>
              <Nav.Link style={{borderRadius: 0, padding: "0.375rem 0.75rem"}} eventKey={"active"}>{t("benefitScreen.active")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link style={{borderRadius: 0, padding: "0.375rem 0.75rem"}} eventKey={"all" || ""}>{t("benefitScreen.all")}</Nav.Link>
            </Nav.Item>
            </div>
          </Nav>
        </div>
      </Col>
  )
}

type BenefitTableProps = {
  data: any
  onDateFilterChange?: (selection: { startDate: Date, endDate: Date } | null) => void
  isOrganizationContext: boolean
}

export function BenefitTable(props: BenefitTableProps) {
  const {t} = useTranslation()

  const columns = React.useMemo(
    () => [
      {
        Header: t("benefitScreen.tableHeaders.type"),
        accessor: (row: any): any => {
          const value: IBenefit = row.benefitId
          if (value.kind === BenefitKind.RECURRING) return t("benefit.intervals.recurring")
          return t("benefit.intervals." + value.rules.interval)
        },
        sortType: "basic",
        Cell: BenefitTypeCell,
        width: 100,
      },
      {
        Header: <span className="text-left">{t("benefitScreen.tableHeaders.benefit")}</span>,
        accessor: (row: any) => row.benefitId,
        sortType: "basic",
        id: "benefit",
        width: 350,
        sortInverted: false,
        Cell: BenefitTextCell,
      },
      {
        Header: <span className="text-left"></span>,
        accessor: (row: any) => {
          return row.benefitId.images.mainImage || ""
        },
        id: "image",
        width: 350,
        canSort: false,
        sortInverted: false,
        disableSortBy: true,

        Cell: BenefitImageCell,
      },
      {
        Header: t("benefitScreen.tableHeaders.venue"),
        accessor: (row: IBenefitReport) => {
          return row.restaurantId
        },
        Cell: VenueCell,
        sortType: "basic",
        Filter: VenueMultiselectFilter,
        id: "venue",
        filter: (rows: any, id: any, filterValue: Array<string>) => {
          return rows.filter((row: any) => {
            const rowValue: IVenue = row.values[id]
            return _.includes(filterValue, rowValue.id)
          })
        },
      },
      {
        Header: t("benefitScreen.tableHeaders.activations"),
        accessor: "usedTotal",
        sortType: "basic",
        Cell: ActivationsCell,
        sortDescFirst: true,
      },
      {
        Header: t("benefitScreen.tableHeaders.uniqueMembers"),
        accessor: "uniqueUsers",
        sortType: "basic",
        id: "uniqueUsers",
      },
      {
        Header: t("benefitScreen.tableHeaders.lastActivation"),
        accessor: "lastUsed",
        sortType: "basic",
        Cell: DateCell,
        sortDescFirst: true,
      },
      {
        Header: t("benefitScreen.tableHeaders.status"),
        id: "active",
        accessor: (row: any) => row.benefitId.getActiveStatus(),
        Cell: BenefitStatusCell,
        Filter: StatusFilter,
        filter: (rows: any, id: any, filterValue: string) => {
          let statuses: Array<BenefitActiveStatus> = []
          if (filterValue === "inactive") {
            statuses = [BenefitActiveStatus.INACTIVE_BENEFIT, BenefitActiveStatus.INACTIVE_VENUE]
          } else if (filterValue === "active") {
            statuses = [BenefitActiveStatus.ACTIVE]
          } else {
            statuses = [BenefitActiveStatus.ACTIVE, BenefitActiveStatus.INACTIVE_BENEFIT, BenefitActiveStatus.INACTIVE_VENUE]
          }
          return rows.filter((row: any) => {
            return _.includes(statuses, row.original.benefitId.getActiveStatus())
          })
        },
        width: 40,
      },
    ],
    [t],
  )

  const filteredColumns = (!props.isOrganizationContext ?
    columns.filter(column => column.id !== "venue") :
    columns)

  return (
    <RenderBenefitTable columns={filteredColumns} data={props.data} onDateFilterChange={props.onDateFilterChange}/>
  )
}
