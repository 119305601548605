import {Instance, types} from "mobx-state-tree"
import {Benefit} from "./Benefit"
import {Venue} from "./Venue"

export const BenefitReport = types.model("BenefitReport")
  .props({
    benefitId: types.reference(Benefit),
    restaurantId: types.reference(Venue),
    usedTotal: types.optional(types.number, 0),
    usedLastMonth: types.optional(types.number, 0),
    uniqueUsers: types.optional(types.number, 0),
    lastUsed: types.maybeNull(types.string),
  })

export type IBenefitReport = Instance<typeof BenefitReport>
