import { Instance, types } from "mobx-state-tree";
import i18n from "../i18n";
import { RewardTrigger } from "./RewardTrigger";
import { Venue } from "./Venue";

const RewardTitle = types.model("RewardTitle").props({
  en: types.string,
  fi: types.string
});

const RewardDescription = types.model("RewardDescription").props({
  en: types.string,
  fi: types.string
});


const DurationType = types.custom<number | string, number>({
  name: "DurationType",

  fromSnapshot(value: number | string): number {
    return Number(value);
  },

  toSnapshot(value: number): number {
    return value;
  },

  isTargetType(value: number | string): value is number {
    return typeof value === 'number';
  },

  getValidationMessage(value: number | string): string {
    if (typeof value === 'string' && isNaN(Number(value))) {
      return "Cannot convert string to number";
    }
    if (typeof value !== 'string' && typeof value !== 'number') {
      return "Not a valid string or number";
    }
    return ""; // indicates no error
  }
});

const RewardValidity = types.model("RewardValidity").props({
  duration: types.maybeNull(DurationType),
  durationUnit: types.maybeNull(types.string)
});

const TimingRules = types.model("TimingRules", {
  validity: RewardValidity,
});


const VALID_LANGUAGE_CODES = ['en', 'fi']

const getValidLanguageCode = (languageCode: string) => {
  const validLanguageCode = VALID_LANGUAGE_CODES.find(code => code === languageCode)
  if (validLanguageCode) {
    return validLanguageCode
  }

  return 'en'
}


const Images = types.model("Images")
  .props({
    mainImage: types.maybeNull(types.string)
  })

export const Reward = types.model("Reward").props({
  id: types.identifier,
  reward_trigger_id: types.reference(types.late(() => RewardTrigger)),
  timing_rules: TimingRules,
  assigned_venues: types.array(types.reference(Venue)),
  title: RewardTitle,
  description: RewardDescription,
  images: types.maybeNull(Images),
  active: types.maybeNull(types.boolean),
  rewards_given: types.maybeNull(types.integer),
  rewards_redeemed: types.maybeNull(types.integer),
  time_frame_reached: types.maybeNull(types.boolean),
}).views(self => ({
  localePrice(language: string | undefined = undefined) {
  },
})).views(self => ({
  localeTitle(language: string | undefined = undefined) {
    const languageCode = getValidLanguageCode(language ? language : i18n.languages[0])
    // @ts-ignore
    return self.title[languageCode]
  },
  localeDescription(language: string | undefined = undefined) {
    const languageCode = getValidLanguageCode(language ? language : i18n.languages[0])
    // @ts-ignore
    return self.description[languageCode]
  },
})).actions(self => ({
}))

export type IReward = Instance<typeof Reward>;
