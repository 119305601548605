import {Instance, types} from "mobx-state-tree"
import {Event} from "./Event"


export const EventReport = types.model("EventReport")
  .props({
    eventId: types.reference(Event),
    visitorCount: types.optional(types.number, 0),
    totalSalesAmount: types.optional(types.number, 0),
    entranceCount: types.optional(types.number, 0),
    cloakroomCount: types.optional(types.number, 0),
  })

export type IEventReport = Instance<typeof EventReport>
