import { Instance, destroy, flow, getEnv, types } from "mobx-state-tree";
import { ApiResult } from "../ApiResult";
import { IReward, Reward } from "./Reward";
import { RewardTrigger } from "./RewardTrigger";

export enum REWARD_TYPE {
  REWARD_BIRTHDAY = "REWARD_BIRTHDAY",
  REWARD_JOIN = "REWARD_JOIN",
}

export const RewardStore = types.model("RewardStore").props({
  rewards: types.optional(types.array(Reward), []),
  rewardTriggers: types.optional(types.array(RewardTrigger), [])
}).actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {
        // const rewardsResponse: ApiResult = yield getEnv(self).api.fetchRewards(undefined, requestSource)
        // const rewardsTriggersResponse: ApiResult = yield getEnv(self).api.fetchRewardsTriggers(undefined, requestSource)

        // if (rewardsResponse.modified) {
        //   self.rewards.replace(rewardsResponse.payload.data)
        // }
        // if (rewardsTriggersResponse.modified) {
        //   self.rewardTriggers.replace(rewardsTriggersResponse.payload.data)
        // }
        // return rewardsResponse.etag
    }),
  fetchRewards: flow(function* fetchRewards(organization = undefined, requestSource = "init") {
    const response: ApiResult = yield getEnv(self).api.fetchRewards(organization, requestSource);
    if (response.modified) {
      self.rewards.replace(response.payload.data);
    }
    return response.etag;
  }),

  postReward: flow(function* postReward(reward, organization = undefined, requestSource = "init") {
    const response: ApiResult = yield getEnv(self).api.postReward(reward, organization, requestSource);

    return response;
  }),

  fetchRewardsTriggers: flow(function* fetchRewardsTriggers(organization = undefined, requestSource = "init") {
    const response: ApiResult = yield getEnv(self).api.fetchRewardsTriggers(organization, requestSource);
    if (response.modified) {
      self.rewardTriggers.replace(response.payload.data);
    }
    return response.etag;
  }),

  addReward(reward: IReward) {
    self.rewards.push(reward);
  },

  updateReward(reward: IReward) {
    const index = self.rewards.findIndex(r => r.id === reward.id);
    if (index !== -1) {
      self.rewards[index] = reward;
    }
  },

  getRewards: ({ isOrganizationContext, selectedBranchId }: {
    isOrganizationContext: boolean,
    selectedBranchId: string | undefined,
  }) => {
    if (isOrganizationContext) {
      return self.rewards.slice() || []; 
    } else {
      return self.rewards.filter(reward => 
        reward.assigned_venues.some(venue => venue.id === selectedBranchId)
      ) || [];
    }

  },

  removeReward(reward: IReward) {
    destroy(reward);
  },

  resetData() {
    self.rewards.clear()
    self.rewardTriggers.clear()
  },
}));

export type IRewardStore = Instance<typeof RewardStore>;
