import React, {useEffect, useRef, useState} from "react"
import Card from "react-bootstrap/Card"
import {IconName, IconProp} from "@fortawesome/fontawesome-svg-core"
import {BenefitFormat, BenefitInterval, BenefitKind, IBenefit} from "../models/Benefit"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useTranslation} from "react-i18next"
import Button from "react-bootstrap/Button"
import {observer} from "mobx-react"
import i18n from "../i18n"
import {TextInput, TextInputGroup, ValidationForm} from "react-bootstrap4-form-validation"
import Form from "react-bootstrap/Form"
import {IconPickerDropdown} from "./IconPickerDropdown"
import {applySnapshot, clone, getParent, getSnapshot} from "mobx-state-tree"
import * as _ from "lodash"
import Badge from "react-bootstrap/Badge"
import Dropdown from "react-bootstrap/Dropdown"
import {IBenefitStore} from "../models/BenefitStore"
import SplitButton from "react-bootstrap/SplitButton"

const benefitIconList = [
  "coffee",
  "beer",
  "sandwich",
  "glass-champagne",
  "ice-cream",
  "wine-glass-alt",
  "pizza",
  "stroopwafel",
  "hamburger",
  "french-fries",
  "drumstick",
  "mug-hot",
  "cocktail",
  "wine-glass",
  "utensils-alt",
  "glass-cheers",
  "salad",
  "soup",
  "wine-bottle",
  "pie",
  "ticket-alt",
  "cheeseburger",
  "cheese",
  "star",
  "glass",
  "box",
].sort()

export interface BenefitModifyCardProps {
  product: any
  onEdit: any
}


export const ProductCard = observer((props: Readonly<BenefitModifyCardProps>) => {

  const {product, onEdit} = props

  const {t} = useTranslation()

  // const icon: IconProp = ["fas", benefit.style.icon.name as IconName]

  const currentLanguage = i18n.languages[0]
  const alternativeLanguage = currentLanguage === "en" ? "fi" : "en"


  console.log('product', product)
  return (
    <Card className="mb-3 bg-white">
        <Card.Body className="p-0 m-0">
          <Container>
            <Row>
              <Col
                className="col-1 py-3 text-primary d-flex align-items-center justify-content-start bg-white rounded">
                <h6 className="pl-2 m-0 mt-1 d-flex">{product.rules.quantity}x</h6>
              </Col>
              <Col className="col-5 py-2 d-flex align-items-center justify-content-start">
                <div>
                  <p
                    className="pl-1 m-0 font-size-medium font-heading font-weight-bold">{product.title[currentLanguage]}</p>
                  <p className="pl-1 m-0 mt-1">{product.description[currentLanguage]}</p>
                  <div className="font-size-small mt-1">
                    {/* TODO: Activate once link is available */}
                    {/* <a target="_blank" rel="noopener noreferrer" href=""><FontAwesomeIcon className={"mr-2"} icon={["fal", "link"]}/>{t("productsScreen.productLink")}</a> */}
                  </div>
                </div>
              </Col>
              <Col className="col-2 d-flex align-items-center justify-content-end">
                {product.images.mainImage &&
                  <img 
                    alt="product"
                    src={product.images.mainImage}
                    style={{width: 100, height: 60, objectFit: 'cover', borderRadius: '5px', marginRight: 24}}/>
                }
                
              </Col>
              <Col className="col-4 py-2 d-flex align-items-center justify-content-end">
                <Button
                  variant={"transparent"}
                  onClick={onEdit}
                >
                  <FontAwesomeIcon className="m-0 mr-2" icon={["fal", "pen-to-square"]}/>
                  {t("productsScreen.edit")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
    </Card>
  )
})
