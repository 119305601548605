import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as _ from "lodash"
import moment from "moment-timezone"
import React, { useEffect } from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Table from "react-bootstrap/Table"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { useFilters, useSortBy, useTable } from "react-table"
import { useUrlFilter } from "../hooks/FilterHooks"
import { IProduct } from "../models/Product"
import { IProductReportStore } from "../models/ProductReportStore"
import { IVenue } from "../models/Venue"
import { centsToFullCurrencyUnits } from "../utils"
import { DateRangeDropdown } from "./DateRangeDropdown"
import { colors } from "../theme/colors"

// @ts-ignore
function DateCell(cellData) {
  const value = cellData.cell.value
  const stringValue = value ? moment.utc(value).tz("Europe/Helsinki").format("DD.MM.YYYY") : "-"
  return <div className="text-left">{stringValue}</div>
}

// @ts-ignore
function NumberCell(cellData) {
  const value = cellData.cell.value
  return (
    <div className="d-flex text-center flex-column align-items-center justify-content-center p-0 m-0">
        <div className="p-0 m-0 text-primary font-heading font-size-large">{value}</div>
    </div>
  )
}

// @ts-ignore
function EurosCell(cellData) {
  const value = cellData.cell.value
  return (
    <div className="d-flex text-center flex-column align-items-center justify-content-center p-0 m-0">
        <div className="p-0 m-0 text-primary font-heading font-size-large">{value}</div>
    </div>
  )
}

// @ts-ignore
function ProductTextCell(cellData) {
  const value = cellData.cell.value
  return <div className="font-heading text-left font-weight-bold pl-2">{value}</div>
}

// @ts-ignore
const ImageCell = (cellData) => cellData.cell.value ?
  <img alt="product" src={cellData.cell.value} style={{width: 100, height: 60, objectFit: 'cover', borderRadius: '5px', marginRight: 24}}/>
 : <div/>


// @ts-ignore
function VenueCell(cellData) {
  const value: IVenue = cellData.cell.value
  return <div className="text-menugray">{value}</div>
}

// @ts-ignore
function ProductStatusCell(cellData) {
  const isActive = cellData.cell.value
  return (
    <div className="text-center">
      {isActive ?
        <FontAwesomeIcon className="text-hentovihrea icon-medium" icon={["fal", "check-circle"]}/>
        :
        <FontAwesomeIcon className="text-hentopinkki icon-medium" icon={["fal", "minus-circle"]}/>
      }
    </div>
  )
}

// @ts-ignore
function RenderProductTable({columns, data, onDateFilterChange}) {
  const {t} = useTranslation()
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{id: "usedTotal", desc: true}],
        hiddenColumns: ["uniqueUsers", "lastUsed"],
      },
      autoResetFilters: false,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
  )

  const activeCount = _.sumBy(rows, (o: any) => o.original.visible ? 1 : 0)
  const inactiveCount = rows.length - activeCount
  // @ts-ignore
  return (
    <>
      <Card className="mb-4 p-0">
        {/* <Card.Header as="h6"><FontAwesomeIcon className="mr-2"
                                      icon={["fal", "filter"]}/>{t("benefitScreen.sections.filters")}
        </Card.Header> */}
        <Card.Body className="px-0 py-3">
          <Container>
            <Row>
              {/* TODO: Figure out if date range is needed here and implemented if so */}
              {/* <Col className="text-left" style={{width: "33%"}}><DateRangeDropdown onSelect={onDateFilterChange}/></Col> */}
              {headerGroups.map(headerGroup => (
                headerGroup.headers.map((column: any) => {
                 return column.canFilter && column.Filter ?
                      <React.Fragment key={column.id}>{column.render("Filter")}</React.Fragment> : null
                })
              ))}

            </Row>
          </Container>

        </Card.Body>
      </Card>

      <div className="mb-2 text-center font-size-medium font-heading font-weight-light">
        {t("productsScreen.tableSummary", {
          totalCount: rows.length,
          activeCount: activeCount,
          inactiveCount: inactiveCount,
        })}
      </div>

      <Card className="m-0" style={{borderWidth: 0, borderColor: colors.boxFill}}>
        <div className="rounded-sm">
          <Table className="benefitTable p-0 m-0" striped hover {...getTableProps()}>
            <thead className="text-primary" style={{backgroundColor: ""}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} style={{
                MozUserSelect: "none",
                WebkitUserSelect: "none",
              }}>
                {headerGroup.headers.map(column => {
                  // @ts-ignore
                  const {style} = column.getHeaderProps(column.getSortByToggleProps())
                  return (
                    // @ts-ignore
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{
                      MozUserSelect: "none",
                      WebkitUserSelect: "none",
                      whiteSpace: "nowrap",
                      width: column.width,
                      borderWidth: 0,
                      ...style,
                    }}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                    {column.canSort ?
                      column.isSorted
                      ? column.isSortedDesc
                        ? <FontAwesomeIcon className="ml-2" icon={["fas", "caret-down"]}/>
                        : <FontAwesomeIcon className="ml-2" icon={["fas", "caret-up"]}/>
                      : <FontAwesomeIcon className=" ml-2" icon={["fal", "caret-down"]}/>
                    : null
                    }
                  </span>
                    </th>
                  )
                })}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      )
                    })}
                  </tr>
                )
              },
            )}
            </tbody>
          </Table>
        </div>
      </Card>
    </>
  )
}


// @ts-ignore
function StatusFilter({column: {filterValue, setFilter, preFilteredRows, id}}) {
  const {t} = useTranslation()
  const {urlFilter, setUrlFilter} = useUrlFilter("status")

  useEffect(() => {
    setFilter(urlFilter)
    // eslint-disable-next-line
  }, [urlFilter])

  return (
      <Col style={{width: "33%"}}>
        <div>
          <Nav className="justify-content-end mr-0 ml-0" variant="pills" activeKey={urlFilter || "all"}
               // @ts-ignore
               onSelect={(item: string) => {
            setFilter(item)
            setUrlFilter(item)
          }}>
            <div className="d-flex flex-row border rounded border-primary overflow-hidden">
            <Nav.Item>
              <Nav.Link style={{borderRadius: 0, padding: "0.375rem 0.75rem"}} eventKey={"active"}>{t("benefitScreen.active")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link style={{borderRadius: 0, padding: "0.375rem 0.75rem"}} eventKey={"all" || ""}>{t("benefitScreen.all")}</Nav.Link>
            </Nav.Item>
            </div>
          </Nav>
        </div>
      </Col>
  )
}

type ProductTableProps = {
  data: any
  onDateFilterChange?: (selection: { startDate: Date, endDate: Date } | null) => void
  isOrganizationContext: boolean
  productReportStore?: IProductReportStore
  selectedVenueId: string | undefined
  venueNames: any[]
}

export function ProductsTable(props: ProductTableProps) {
  const {t} = useTranslation()

  const getVenueNameById = (venueId: string) => props.venueNames.find(venue => venue.id === venueId)?.name || ''
  const getVenueByName = (venueName: string) => props.venueNames.find(venue => venue.name === venueName) || {}
  const getProductReportByProductId = (productId: string) => 
    props.productReportStore?.productReports?.find(productReport => productReport.venueProductId.id === productId)

  // @ts-ignore
  function VenueMultiselectFilter({column: {filterValue, setFilter, preFilteredRows, id}}) {
    const {t} = useTranslation()
    const {urlFilter, setUrlFilter} = useUrlFilter("venues")
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options: Array<{ value: string, label: string }> = []
      preFilteredRows.forEach((row: any) => {
        const venue: IVenue = getVenueByName(row.values[id])
        const venueOption = {value: venue.id!, label: venue.name!}
        if (!_.some(options, (item: any) => _.isEqual(item, venueOption))) {
          options.push(venueOption)
        }
      })
      // @ts-ignore
      return [...options.values()]
    }, [id, preFilteredRows])

    let values = options.filter((option) => {
      // @ts-ignore
      return urlFilter && urlFilter!.indexOf(option.value) !== -1
    })

    useEffect(() => {
      setFilter(urlFilter)
      // eslint-disable-next-line
    }, [urlFilter])

    return (
      <Col style={{width: "33%"}}>
        <div>
          <Select
            placeholder={t("benefitScreen.filters.selectVenues")}
            onChange={(entry: any) => {
              const values = entry && entry.length > 0 ? entry.map((o: any) => {
                return o.value
              }) : undefined
              setFilter(values)
              setUrlFilter(values)
            }}
            isMulti={true}
            options={options}
            value={values}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </Col>
    )
  }

  const columns = React.useMemo(
    () => [
      {
        Header: <span>{t("productsScreen.product")}</span>,
        accessor: (row: any) => {
          return `${row.rules.quantity} x ${row.localeTitle()} ${centsToFullCurrencyUnits(row.pricing.amount)} €`
        },
        sortType: "basic",
        id: "product",
        width: 350,
        sortInverted: false,
        Cell: ProductTextCell,
      },
      {
        Header: <span className="text-left"></span>,
        accessor: (row: any) => row.images.mainImage,
        sortType: "basic",
        id: "image",
        width: 100,
        sortInverted: false,
        canSort: false,
        disableSortBy: true,
        Cell: ImageCell,
      },
      {
        Header: t("benefitScreen.tableHeaders.venue"),
        accessor: (row: any) => {
          return getVenueNameById(row.restaurantId)
        },
        sortType: "basic",
        id: "venue",
        width: 350,
        sortInverted: false,
        Cell: VenueCell,
        Filter: VenueMultiselectFilter,
        filter: (rows: any, id: any, filterValue: Array<string>) => {
          return rows.filter((row: any) => {
            const venueName: string = row.values[id]
            const venue = getVenueByName(venueName)
            return _.includes(filterValue, venue.id)
          })
        },
      },
      {
        Header: '#',
        accessor: (row: any) => {
          const productReport = getProductReportByProductId(row.id)
          if (productReport?.soldTotal === undefined) return '0'
          return productReport?.soldTotal
        },
        sortType: "basic",
        Cell: NumberCell,
        sortDescFirst: true,
      },
      {
        Header: '€',
        accessor: (row: any) => {
          const productReport = getProductReportByProductId(row.id)
          if (productReport?.revenueTotal === undefined) return '0'
          return centsToFullCurrencyUnits(productReport?.revenueTotal)
        },
        sortType: "basic",
        Cell: EurosCell,
        sortDescFirst: true,
      },
      {
        Header: t("benefitScreen.tableHeaders.status"),
        id: "active",
        accessor: (row: any) => row.visible,
        Cell: ProductStatusCell,
        Filter: StatusFilter,
        filter: (rows: any, id: any, filterValue: string) => {
          if (filterValue === 'all') {
            return rows
          } else {
            return rows.filter((row: any) => row.original.visible)
          }
          
        },
        width: 100,
      },
    ],
    [t],
  )

  const filteredColumns = (!props.isOrganizationContext ?
    columns.filter(column => column.id !== "venue") :
    columns)

    
  const filteredRows = props.isOrganizationContext ? props.data : props.data.filter((product: IProduct) => product.restaurantId.id === props.selectedVenueId)

  return (
    <RenderProductTable columns={filteredColumns} data={filteredRows} onDateFilterChange={props.onDateFilterChange}/>
  )
}
