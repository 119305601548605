import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import { withTranslation, WithTranslation } from "react-i18next"
import { MerchantAdminApi } from "../Api"
import { CreateOrganizationLocation, HomeLocation, ManagePremiumLocation } from "../Locations"
import { IOrganization } from "../models/Organization"
import { IUserSessionStore } from "../models/UserSessionStore"

export interface ChangeOrgScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  history?: any
}

@inject("api")
@inject("userSessionStore")
@observer
class ChangeOrgScreen extends React.Component<ChangeOrgScreenProps, {}> {
  onClick(organization: IOrganization) {
    this.props.userSessionStore!.setSelectedOrganization(organization)
    this.props.history.push(HomeLocation.toUrl(this.props.userSessionStore!.selectedBranch!.urlFields()))
  }

  render() {
    const {t, userSessionStore} = this.props
    return (
      <div className="container">
        <>
        <Row className="row no-gutters justify-content-end">
          <Col className="col-lg-12 mt-4">
           <button ref="goToAddNewOrganizationButton"
                    onClick={() => this.props.history.push(CreateOrganizationLocation.toUrl())}
                    style={{float: 'right', paddingLeft: 10, paddingRight: 10}}
                    className="btn btn-primary font-weight-bold">
              {t("superAdminScreen.addNewOrganization.button")}
            </button>
            {userSessionStore!.isSuperAdmin &&
               <button ref="goToAddNewOrganizationButton"
                onClick={() => this.props.history.push(ManagePremiumLocation.toUrl())}
                style={{float: 'right', paddingLeft: 10, paddingRight: 10, marginRight: 10}}
                className="btn btn-primary font-weight-bold">
                {t("superAdminScreen.addNewOrganization.managePremium")}
              </button>
            }
           
           
            
          </Col>
        </Row>
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-md-5">
            <div className="card">
              <h5 className="card-header">{t("changeOrg")}</h5>
              <div className="card-body">
                {userSessionStore!.organizations.map((organization) => {
                  let isCurrent = userSessionStore!.currentOrganization!.id === organization.id
                  return <div key={organization.id} style={{height: 50}}>
                    <Button className="btn-block" variant={isCurrent ? "primary" : "secondary"}
                      onClick={() => this.onClick(organization)} key={organization.name}>
                      {isCurrent ? <FontAwesomeIcon
                        className="mr-2" icon={["fas", "check"]} /> : null}{organization.name}
                    </Button>
                    <span className="text-primary" style={{top: -33, left: 370, position: 'relative'}}>{organization.countryId.name}</span>
                    {organization.isPremium &&
                        <span className="text-primary"
                            style={{top: -33, left: 390, position: 'relative'}}>Premium</span>
                    }
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
        </>
      </div>
    )
  }
}

export default withTranslation()(ChangeOrgScreen)
