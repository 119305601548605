import {Instance, types} from "mobx-state-tree"
import {Product} from "./Product"
import {Venue} from "./Venue"

export const ProductReport = types.model("ProductReport")
  .props({
    venueProductId: types.reference(Product),
    restaurantId: types.reference(Venue),
    soldTotal: types.optional(types.number, 0),
    sold30Days: types.optional(types.number, 0),
    uniqueUsers: types.optional(types.number, 0),
    revenueTotal: types.optional(types.number, 0),
    revenue30Days: types.optional(types.number, 0),
  })

export type IProductReport = Instance<typeof ProductReport>
