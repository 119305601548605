import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import moment from "moment-timezone"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Table from "react-bootstrap/Table"
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { useFilters, useSortBy, useTable } from "react-table"
import { MerchantAdminApi } from "../Api"
import { SendEmailLocation, SendPushNotificationLocation } from "../Locations"
import { IInvitationStore } from "../models/InvitationsStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import RemoveUserModal from "../screens/ManageUsersScreen/RemoveUserModal"
import { colors } from "../theme/colors"

// @ts-ignore
function NameCell(cellData) {
  const value = cellData.cell.value
  return <div className="font-heading text-left font-weight-bold" style={{ color: colors.primaryText}}>{value}</div>
}

// @ts-ignore
function EmailCell(cellData) {
  const value = cellData.cell.value
  return <div className="font-heading text-left" style={{ color: colors.primaryText, fontSize: 14}}>
    {value}
  </div>
}


// @ts-ignore
function DateCell(cellData) {
    const value = cellData.cell.value
    return <div className="font-heading text-right" style={{color: colors.primaryText, fontSize: 14}}>{value}</div>
}

const MESSAGE_TYPE = {
  EMAIL: 'EMAIL',
  PUSH: 'PUSH'
}

const MESSAGE_STATUS = {
  DRAFT: 'DRAFT',
  READY: 'READY',
  SENT: 'SENT'
}

// @ts-ignore
function RenderInvitationsTable({columns, data}) {
  const {t} = useTranslation()
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{id: "usedTotal", desc: true}],
        hiddenColumns: ["uniqueUsers", "lastUsed"],
      },
      autoResetFilters: false,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
  )


  // @ts-ignore
  return (
    <>
      <Card className="pt-1 m-0" style={{borderWidth: 1, borderColor: "#ddd"}}>
        <div className="rounded-sm">
          <Table className="benefitTable p-0 m-0" striped hover {...getTableProps()}>
            <thead className="text-primary" style={{backgroundColor: ""}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} style={{
                MozUserSelect: "none",
                WebkitUserSelect: "none",
              }}>
                {headerGroup.headers.map(column => {
                  // @ts-ignore
                  const {style} = column.getHeaderProps(column.getSortByToggleProps())

                  return (
                    // @ts-ignore
                    <th 
                        // {...column.getHeaderProps(column.getSortByToggleProps())} 
                        style={{
                        MozUserSelect: "none",
                        WebkitUserSelect: "none",
                        whiteSpace: "nowrap",
                        width: column.width,
                        borderWidth: 0,
                        ...style,
                    }}>
                      {column.render("Header")}
                      <span>
                  </span>
                    </th>
                  )
                })}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      )
                    })}
                  </tr>
                )
              },
            )}
            </tbody>
          </Table>
        </div>
      </Card>
    </>
  )
}


interface MessagesTableProps extends RouteComponentProps {
  data: any
  onDateFilterChange?: (selection: { startDate: Date, endDate: Date } | null) => void
  isOrganizationContext: boolean
  userSessionStore?: IUserSessionStore
  api?: MerchantAdminApi
  invitationsStore?: IInvitationStore
}

function InvitationsTableComponent(props: MessagesTableProps) {
  const {t} = useTranslation()
  const [emailsInvitesResent, setEmailsInvitesResent] = useState<string[]>([]);
  const [isRemoveUserModalShown, setIsRemoveUserModalShown] = useState(false);
  const [userToEdit, setUserToEdit] = useState(undefined);

  const MessagingTypeCell = (cellData: any) => <div className="text-center" style={{ color: colors.primaryText}}>
      {cellData.cell.value === 'EMAIL' &&
        <>
          <FontAwesomeIcon style={{color: colors.primaryText}} className="mr-2" icon={["far", "envelope"]}/>
          <span className="mb-2 medium ml-1" style={{position: 'relative', bottom: 2, fontSize: 16}}>{t("messagesScreen.email")}</span>
        </>
      }
      {cellData.cell.value === 'PUSH' &&
        <>
          <FontAwesomeIcon style={{color: colors.primaryText}} className="mr-2" icon={["fas", "mobile-alt"]}/>
          <span className="mb-2 ml-1" style={{position: 'relative', bottom: 2, fontSize: 16}}>{t("messagesScreen.push")}</span>
        </>
      }
    </div>

  const UserEditCell = (cellData: any) => {
    const userData = cellData.cell.value

    const isInvitation = userData.expiry ? true : false

    const onClick = () => {
      setUserToEdit(userData)
      setIsRemoveUserModalShown(true)
    }

    if (isInvitation) {
      return null
    }

    return <div className="text-center" style={{color: colors.primaryText}}>
      <FontAwesomeIcon 
        onClick={onClick} 
        style={{color: colors.primaryText, cursor: 'pointer'}} 
        className="mr-2" 
        icon={["fal", "edit"]}/>
    </div>
  }

    // @ts-ignore
    function StatusCell(cellData) {
        const isInvitation = cellData.cell.value ? true : false
        const invitationStyles = isInvitation ? {
            backgroundColor: '#828282',
            borderColor: '#828282',
            color: '#000'
        } : {}

        const accessStyles = !isInvitation ? {
          borderColor: 'transparent',
          opacity: 1,
          backgroundColor: 'rgba(23, 117, 243, 0.65)',
          paddingLeft: 16,
          paddingRight: 16,
          borderWidth: 3
        } : {}


        return <Button
        onClick={() => console.log('')}
        disabled
        variant="primary"
        style={{
            paddingLeft: 24,
            paddingRight: 24,
            borderRadius: 16,
            ...invitationStyles,
            ...accessStyles
        }}>
        {isInvitation ? t("invitationsScreen.invited") : t("invitationsScreen.access")}
    </Button>
    }

  const dateColumnValue = (row: any) => {

    if (emailsInvitesResent.includes(row.email)) {
        return t("invitationsScreen.resent")
    }

    if (row.expiry) {
      return  <div onClick={ async () => {
        if (row.id !== 'NEW') {
            const response = await props.api!.resendInvitation(row.id, undefined, 'RESEND_INVITATION_ERROR')
   
            if (response?.result === 'OK') {
                setEmailsInvitesResent([...emailsInvitesResent, row.email as string])
            }
        } else {
            setEmailsInvitesResent([...emailsInvitesResent, row.email as string])
        }
       
      }}  style={{
        textDecoration: 'underline',
        cursor: 'pointer'
      }}>{t("invitationsScreen.resendInvite")}</div>
    } else {
      return `${t("invitationsScreen.created")} ${moment(row.created).format("DD.MM.YYYY") }` 
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: <span className="text-left d-inline-block" style={{width: '100%'}}>{t("invitationsScreen.user")}</span>,
        accessor: (row: any): any =>  {
            return row.name
          },
        sortType: "",
        id: "name",
        width: 350,
        sortInverted: false,
        canSort: false,
        Cell: NameCell,
      },
      {
        Header: <div className="text-left">Email</div>,
        accessor: (row: any): any =>  {
          return row.email
        },
        id: "email",
        canSort: false,
        sortInverted: false,
        disableSortBy: true,

        Cell: EmailCell,
      },
      {
        Header: "",
        accessor: (row: any): any => dateColumnValue(row),
        sortType: "",
        id: "created",
        sortInverted: false,
        canSort: false,
        Cell: DateCell,
      },
      {
        Header: <div className="text-center">{t("messagesScreen.tableHeaders.status")}</div>,
        accessor: (row: any): any => {
          return row.expiry
        },
        id: 'status',
        sortType: "basic",
        Cell: StatusCell,
        sortDescFirst: true,
      },
      {
        Header: <span className="text-left"></span>,
        accessor: (row: any): any =>  row,
        id: "edit",
        width: 60,
        canSort: false,
        sortInverted: false,
        disableSortBy: true,

        Cell: UserEditCell,
      },
    ],
    [t, setEmailsInvitesResent, emailsInvitesResent],
  )


  return (
    <>
    <RemoveUserModal showModal={isRemoveUserModalShown} onConfirm={async (userId: string) => {
      await props.invitationsStore?.removeUser(userId)
      setIsRemoveUserModalShown(false)
    }} user={userToEdit} setShowModal={(value: boolean) => setIsRemoveUserModalShown(value)}/>    
    <RenderInvitationsTable columns={columns} data={props.data} 
    // onDateFilterChange={props.onDateFilterChange}
    />
    </>

  )
}

const InvitationsTable = withRouter(inject("userSessionStore", "api", "invitationsStore")(observer(InvitationsTableComponent)))
export { InvitationsTable }
