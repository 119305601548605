import {Instance, types} from "mobx-state-tree"
import {getUrlSlug} from "../Locations"
import {Venue} from "./Venue"
import {Country} from "./Country"
// @ts-ignore
export const Organization = types.model("Organization")
  .props({
    id: types.identifier,
    name: types.maybe(types.string),
    isPremium: types.maybe(types.boolean),
    venues: types.optional(types.array(types.late(() => Venue)), []),
    countryId: types.reference(Country),
  }).views(self => ({
    isSingleVenue() {
      return self.venues.length === 1
    },
    urlFields() {
      return {
        id: self.id,
        idType: "org",
        organization: getUrlSlug(self.id!),
      }
    },
    get venueIds() {
      return self.venues.map(venue => venue.id)
    },
  })).actions(self => ({
    addVenue(venue: any) {
      self.venues.push(venue)
    },
    updateVenue(venue: any) {
      const venueToUpdateIndex = self.venues.findIndex(v => v.id === venue.id)
      self.venues[venueToUpdateIndex] = venue
    },
    setIsPremium(isPremium: any) {
      self.isPremium = isPremium
    }
  }))

export type IOrganization = Instance<typeof Organization>
