import {flow, getEnv, Instance, types} from "mobx-state-tree"
import {BenefitReport, IBenefitReport} from "./BenefitReport"
import * as _ from "lodash"
import log from "loglevelnext"

function filterBenefits(benefitReports: Array<IBenefitReport> | undefined, venueIds: Array<string>) {
  return (benefitReports === undefined) ? [] : benefitReports.filter((benefitReport: IBenefitReport) => {
      return venueIds.includes(benefitReport.restaurantId.id)
    },
  )
}

export const BenefitReportStore = types.model("BenefitReportStore")
  .props({
    benefitReports: types.optional(types.array(BenefitReport), []),
    dateFilterBenefitReports: types.maybe(types.array(BenefitReport)),
    reporting: types.optional(types.model({
      benefitsUsedTotal: types.optional(types.number, 0),
      benefitsUsedLast30Days: types.optional(types.number, 0),
    }), {}),
  }).views(self => ({
    get filteredReportDefault() {
      log.debug("BenefitReportStore: filteredReportDefault updated")
      return filterBenefits(self.benefitReports, getEnv(self).userSessionStore.selectedBranchVenueIds)
    },
    get filteredReportDateFilter() {
      log.debug("BenefitReportStore: filteredReportDateFilter updated")
      return filterBenefits(self.dateFilterBenefitReports, getEnv(self).userSessionStore.selectedBranchVenueIds)
    },
  })).views(self => ({
    get filteredReport() {
      log.debug("BenefitReportStore: filteredReport updated")
      return self.dateFilterBenefitReports ? self.filteredReportDateFilter : self.filteredReportDefault
    },
  })).actions(self => ({
    fetchData: flow(function* fetchData(startDate = undefined, endDate = undefined, requestSource: string = "init") {
      log.debug("BenefitReportStore: fetchData")
      const dateRange = startDate === undefined || endDate === undefined ? undefined : { startDate: startDate, endDate: endDate } 
      const response = yield getEnv(self).api.fetchBenefitReports(undefined, dateRange, requestSource)
      
      if (response.modified) {
        self.benefitReports.replace(response.payload.data)
      }
      return response.etag
    }),
    setDateFilter: flow(function* setBenefitReportDateFilter(selection: { startDate: Date, endDate: Date } | null, requestSource: string = "benefitsScreen") {
      if (!selection) {
        self.dateFilterBenefitReports = undefined
      } else {
        log.debug("BenefitReportStore: setDateFilter, fetching data from the backend")
        const response = yield getEnv(self).api.fetchBenefitReports(undefined, selection, requestSource, true)
        self.dateFilterBenefitReports = response.payload.data
      }
    }),
    updateReport() {
      log.debug("BenefitReportStore: updateReport")
      self.reporting.benefitsUsedTotal = _.sumBy(self.filteredReportDefault, "usedTotal")
      self.reporting.benefitsUsedLast30Days = _.sumBy(self.filteredReportDefault, "usedLastMonth")

    },
    resetData() {
      log.debug("BenefitReportStore: resetData")
      self.benefitReports.clear()
      if (self.dateFilterBenefitReports) {
        self.dateFilterBenefitReports = undefined
      }
    },
  }))

export type IBenefitReportStore = Instance<typeof BenefitReportStore>
