import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, FieldArray, Form, Formik } from 'formik'
import { inject, observer } from "mobx-react"
import moment from "moment-timezone"
import React, { useState } from "react"
import { Container, InputGroup } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Card from 'react-bootstrap/Card'
import Col from "react-bootstrap/Col"
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Modal from 'react-bootstrap/Modal'
import Row from "react-bootstrap/Row"
import DatePicker from "react-datepicker"
import { withTranslation, WithTranslation } from "react-i18next"
import Skeleton from 'react-loading-skeleton'
import { useHistory } from "react-router"
import { v4 as uuidv4 } from "uuid"
import * as Yup from 'yup'
import { MerchantAdminApi } from "../../Api"
import AlertMessage from "../../components/AlertMessage"
import ImageUploadModal from "../../components/ImageUploadModal"
import i18n from "../../i18n"
import { MessagesLocation } from "../../Locations"
import { IMemberStore } from "../../models/MemberStore"
import { IMessageStore } from "../../models/MessageStore"
import { IUserSessionStore } from "../../models/UserSessionStore"
import { urlToFile } from "../../utils"
import "./CreateUpdateVenueScreen.scss"

const SEND_EMAIL_ERROR = "sendEmailError"

export interface SendEmailScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  history?: any
  messageStore?: IMessageStore
  memberStore?: IMemberStore
}

const IMAGE_UPLOAD_STATUS = {
  UPLOADING: "UPLOADING",
  ERROR: "ERROR"
}

const SEND_STATUS = {
  DRAFT: 'DRAFT',
  READY: 'READY',
  SENT: 'SENT'
}

const SendEmailScreen = (props: SendEmailScreenProps) => {
  const {t, userSessionStore, api, messageStore, memberStore} = props
  
  const [croppedLogo, setCroppedLogo] = useState('')
  const [showGoBackModal, setShowGoBackModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [selectedSendStatus, setSelectedSendStatus] = useState('');

  const [showUploadLogoPhotoModal, setShowUploadLogoPhotoModal] = useState(false);
  const history = useHistory()
  const getVenueIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    if (urlVars.length >= 5 && urlVars[3] === 'venue') {
      return urlVars[4]
    } else {
      return ''
    }
  }

  const venueId = getVenueIDFromURL()
  const selectedVenue = userSessionStore?.currentOrganization?.venues.find(venue => venue.id === venueId)
  const selectedMessage = messageStore?.messages.find(message => message.id === history.location?.state?.messageId)
  const isMessageSent = selectedMessage?.status === SEND_STATUS.SENT
  const [uploadedLogoImage, setUploadedLogoImage] = useState(selectedMessage?.payload?.logoURL || "");
  const [isCustomTime, setIsCustomTime] = useState(selectedMessage?.isCustomTime || false);

  const venuesNames = userSessionStore?.currentOrganization?.venues.map(venue => venue.name) || []

  const HTTP_HTTPS_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

  const validationSchema = Yup.object().shape({
    toMembersInVenue: Yup.string(),
    emailSubject: Yup.string(),
    emailTitle: Yup.string(),
    emailContent: Yup.string(),
    logoURL: Yup.string(),
    topics: Yup.array().of(
      Yup.object().shape({
        topicTitle: Yup.string(),
        topicContent: Yup.string(),
        imgURL: Yup.string(),
        url: Yup.string().matches(
          HTTP_HTTPS_REGEX,
          t('manageVenuesScreen.urlMustIncludeHTTPPrefix')
        ).nullable(true),
      })
    )
  });

  const newMessageValues = {
    id: `NEW__${uuidv4()}`,
    toMembersInVenue: venuesNames.length > 0 ? venuesNames[0] : '',
    emailSubject: '',
    logoURL: '',
    emailTitle: '',
    emailContent: '',
    startSendingTime: moment(new Date()).utc().format('YYYY-MM-DD h:mm:ss'),
    messageType: "EMAIL",
    topics: [
      {
        imgURL: '',
        topicTitle: '',
        topicContent: '',
        url: ''
      }
    ]
  }

  const initValues = selectedMessage ? {
    id: selectedMessage.id,
    toMembersInVenue: selectedMessage.payload.toMembersInVenue || newMessageValues.toMembersInVenue,
    emailSubject: selectedMessage.payload.emailSubject || newMessageValues.emailSubject,
    logoURL: selectedMessage.payload.logoURL || newMessageValues.logoURL,
    emailTitle: selectedMessage.payload.emailTitle || newMessageValues.emailTitle,
    emailContent: selectedMessage.payload.emailContent || newMessageValues.emailContent,
    startSendingTime: selectedMessage.sendTime ? `${moment(selectedMessage.sendTime).utc().format('YYYY-MM-DD')} ${moment(selectedMessage.sendTime).utc().format('HH:mm')}` : newMessageValues.startSendingTime,
    messageType: "EMAIL",
    // @ts-ignore
    topics: selectedMessage?.payload?.topics?.map(topic => topic.toJSON()) || newMessageValues.topics
  } : {
    ...newMessageValues
  }


  const onBackToMessages = (isFormEdited: boolean) => {
    if (isFormEdited)
      setShowGoBackModal(true)
    else
      history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
  }

  const handleUploadLogoImage = async (imageBlob: any) => {
    setUploadedLogoImage(IMAGE_UPLOAD_STATUS.UPLOADING)
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await api!.uploadImage('venue/logo', imageFile)
    if (imageURL) {
      setUploadedLogoImage(imageURL)
    } else {
      setUploadedLogoImage(IMAGE_UPLOAD_STATUS.ERROR)
    }
  }


  const handleSaveAndPublish = async (messages: any[], newMessage: boolean) => {
    const response = await api!.postMessages(messages, undefined, SEND_EMAIL_ERROR)
    if (response && response.messages.length > 0) {
        if (newMessage) {
            messageStore?.addMessage(response.messages[0])
        } else {
            messageStore?.updateMessage(response.messages[0])
        }
        history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }
  }

  const UploadedImage = ({uploadedImage}: {uploadedImage: any}) => {
    if (uploadedImage === "") {
      return <Card className="croppedLogo"/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton height={200} width={200}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return <Card className="croppedLogo">
        <div className="alert alert-danger mt-5" role="alert">
          {t('manageVenuesScreen.imageUploadFailed')}
        </div>
      </Card>
    } else {
      return <img className="croppedLogo" src={uploadedImage} alt="logo"/>
    }
  }


  // @ts-ignore
  const TopicContainer = ({topic, index, handleChange, setFieldValue, errors, touched}) => {

    const [croppedImg, setCroppedImg] = useState('')
    const [showUploadImgModal, setShowUploadImgModal] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(topic.imgURL || '');

    const handleUploadImage = async (imageBlob: any) => {
      setUploadedImage(IMAGE_UPLOAD_STATUS.UPLOADING)
      const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
      // TODO: update from vennue/logo to appropriate type
      const imageURL = await api!.uploadImage('message/email/body', imageFile)
      if (imageURL) {
        setUploadedImage(imageURL)
        setFieldValue(`topics.${index}.imgURL`, imageURL);
      } else {
        setUploadedImage(IMAGE_UPLOAD_STATUS.ERROR)
      }
    }

    return <Container className="formContainer mt-4">

      <ImageUploadModal
        show={showUploadImgModal}
        imageURL={topic.imgURL}
        aspectRatio={1}
        cropShape="round"
        onCroppedImage={(croppedImage) => {
          setCroppedImg(croppedImage)
        }}
        onCancel={() => {
          setShowUploadImgModal(false)
        }}
        onSave={() => {
          setShowUploadImgModal(false)
          handleUploadImage(croppedImg)
        }}/>

      <Row className="justify-content-center">
        <Col md={4}>
          <Field
            name={`topics.${index}.imgURL`}
            render={() => (
              <FormGroup controlId={`topics.${index}.imgURL`}>
                <p className={`textInputLabel mb-2 text-center text-primary`}>{t(`sendEmailScreen.topicPhoto`)}</p>
                {!isMessageSent &&
                  <label className="btn btn-secondary d-block" style={{width: 150, margin: '0px auto'}}>
                    {t(`manageVenuesScreen.uploadPhoto`)}
                    <input
                      id={`topics.${index}.imgURL`}
                      name="file"
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(event) => {
                        if (event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]?.type.includes("image")) {
                          setFieldValue(`topics.${index}.imgURL`, URL.createObjectURL(event.currentTarget.files[0]));
                          setShowUploadImgModal(true)
                        }
                      }}/>
                  </label>
                }
              </FormGroup>
            )}
          />
        </Col>
      </Row>

      <Row className="justify-content-center" style={{height: 200}}>
        <Col md={4} className="text-center">
          <UploadedImage uploadedImage={uploadedImage || topic.imgURL}/>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={2}></Col>
        <Col md={8}>
          <Field
            name={`topics.${index}.topicTitle`}
            render={() => (
              <FormGroup controlId={`topics.${index}.topicTitle`}>
                <FormLabel style={{fontSize: 20}}
                  // TODO: Handle errors
                  // ${errors.topics[index].topicTitleame2 && touched.topics[index].topicTitle ? 'text-danger' : ''}
                  className={`textInputLabel font-weight-bold d-block text-center`}>
                    {t("sendEmailScreen.topicTitle")}
                </FormLabel>
                <FormControl
                  className="textInput"
                  placeholder={t("sendEmailScreen.topicPlaceholder")}
                  type={'text'}
                  // isInvalid={errors.topics[index].topicTitle && touched.topics[index].topicTitle ? true : false}
                  value={topic.topicTitle}
                  onChange={handleChange}/>
                {/* {errors.topics[index].topicTitle && touched.topics[index].topicTitle ? (
                  <small className="text-danger">{errors.topics[index].topicTitle}</small>
                ) : null} */}
              </FormGroup>
            )}
          />
        </Col>
        <Col md={2}></Col>
      </Row>

      <Row className="mt-4">
      <Col md={2}></Col>
      <Col md={8}>
        <Field
          name={`topics.${index}.topicContent`}
          render={() => (
            <FormGroup controlId={`topics.${index}.topicContent`}>
              <FormLabel
                // TODO: Handle errors
                // ${errors.description && touched.description ? 'text-danger' : ''}
                className={`textInputLabel d-block text-center`}>
                  {t(`sendEmailScreen.topicParagraphTitle`)}
              </FormLabel>
              <FormControl
                className="textInput"
                as='textarea'
                rows={8}
                placeholder={t(`sendEmailScreen.topicParagraphPlaceholder`)}
                // isInvalid={errors.description && touched.description ? true : false}
                value={topic.topicContent}
                onChange={(e) => {
                  setFieldValue(`topics.${index}.topicContent`, e.target.value)
                }}/>
              {/* {errors.description && touched.description ? (
                <small className="text-danger">{errors.description}</small>
              ) : null} */}
            </FormGroup>
          )}
        />
      </Col>
      <Col md={2}></Col>
      </Row>

      <Row className="mt-4">
        <Col md={2}></Col>
        <Col md={8}>
          <Field
            name={`topics.${index}.url`}
            render={() => (
              <>
                <InputGroup className="mb-3">
                  <InputGroup.Text id={`topics.${index}.url`}>
                    {t("sendEmailScreen.link")}:
                  </InputGroup.Text>

                  <FormControl 
                    id={`topics.${index}.url`} 
                    value={topic.url} 
                    onChange={handleChange}
                    placeholder="https://"
                    aria-describedby={`topics.${index}.url`} />
                  
                </InputGroup>
                {(errors.topics && touched.topics && errors.topics[index] && touched.topics[index] && errors.topics[index].url && touched.topics[index].url) ? (
                  <small className="text-danger">{errors.topics[index].url}</small>
                ) : null}
              </>
            )}
          />
        </Col>
        <Col md={2}></Col>
      </Row>

    </Container>
  }

  return (
    <Container>
      <AlertMessage source={SEND_EMAIL_ERROR}/>

      <Modal show={showGoBackModal} centered onHide={() => {
        setShowGoBackModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{t("messagesScreen.goBackPopup.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("messagesScreen.goBackPopup.description")}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => {
            setShowGoBackModal(false)
          }}>
            {t("messagesScreen.goBackPopup.cancel")}
          </Button>
          <Button variant="primary" onClick={() => {
            history.push(MessagesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
          }}>
            {t("messagesScreen.goBackPopup.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>


      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const selectedVenue = userSessionStore!.currentOrganization?.venues.find(venue => venue.name === values.toMembersInVenue)
          const nowTime = moment(new Date()).add('minutes', 1).utc().format('YYYY-MM-DD HH:mm:ss')
          const sentToMembersCount = memberStore?.members.filter(member => member.venueMemberships.find(venueMembership => venueMembership.id.id === selectedVenue?.id )).length

          const emailMessage = {
            "status": selectedSendStatus,
            "messageType": "EMAIL",
            "isCustomTime": isCustomTime,
            "sentToMembersCount": sentToMembersCount,
            "payload": {
                ...values,
                logoURL: uploadedLogoImage || ''
            },
            "id": values.id,
            "targetVenueId": selectedVenue?.id,
            "sendTime": isCustomTime ? values.startSendingTime : nowTime
          }

          const isNewMessage = history.location.state.messageId === 'NEW'
          handleSaveAndPublish([emailMessage], isNewMessage)
        }}
      >
        {({dirty, errors, touched, values, handleChange, handleSubmit, handleBlur, resetForm, setFieldValue}) => (
          <Form>
            <Modal show={showRevertModal} centered onHide={() => {
              setShowRevertModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.revertChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              
              <Modal.Body>{t("manageVenuesScreen.revertChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  resetForm()
                  setUploadedLogoImage(selectedVenue?.images?.logoImage || "")
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showSaveModal} centered onHide={() => {
              setShowSaveModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.saveChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.saveChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowSaveModal(false)
                }}>
                  {t("manageVenuesScreen.saveChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  handleSubmit()
                  setShowSaveModal(false)
                }}>
                  {t("manageVenuesScreen.saveChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Row>
              <Col md={4}>
                <Button
                  variant="secondary"
                  className="font-weight-bold"
                  onClick={() => onBackToMessages(dirty)}>
                  <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
                  {t("sendEmailScreen.backToMessages")}
                </Button>
              </Col>
              <Col md={8}>

              </Col>

            </Row>
            <fieldset disabled={isMessageSent}>
            <h4 className="text-primary text-center mt-4 font-weight-bold">{t("sendEmailScreen.title")}</h4>

            {t("sendEmailScreen.description").split('\n').map(text => 
              <p className="p-0 m-0 text-center">{text}</p>
            )}

            <Container className="formContainer mt-4">

            <Row>
                <Col md={4}></Col>
                <Col md={4}>
                  <Field
                    name="toMembersInVenue"
                    render={() => (
                      <FormGroup controlId="toMembersInVenue">
                        <FormLabel
                          className={`textInputLabel d-block text-center ${errors.toMembersInVenue && touched.toMembersInVenue ? 'text-danger' : ''}`}>
                            <h6 className="text-primary font-weight-bold">{t("sendEmailScreen.audience")}</h6>
                          </FormLabel>
                        <FormControl className="textInput font-weight-bold" as='select'
                                     isInvalid={errors.toMembersInVenue && touched.toMembersInVenue ? true : false} value={values.toMembersInVenue}
                                     onChange={handleChange}>
                          {venuesNames.map(venueName => <option>{venueName}</option>)}
                        </FormControl>
                        {errors.toMembersInVenue && touched.toMembersInVenue ? (
                          <small className="text-danger">{errors.toMembersInVenue}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
                <Col md={4}></Col>
            </Row>

            <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  <Field
                    name="emailSubject"
                    render={() => (
                      <FormGroup controlId="emailSubject">
                        <FormLabel
                          className={`textInputLabel d-block text-center ${errors.emailSubject && touched.emailSubject ? 'text-danger' : ''}`}>
                            <h6 className="text-primary font-weight-bold mt-2">{t("sendEmailScreen.emailSubject")}</h6>
                            {t("sendEmailScreen.emailSubjectDescription").split('\n').map(text => 
                              <p className="p-0 m-0 text-center">{text}</p>
                            )}
                        </FormLabel>
                        <FormControl
                          className="textInput"
                          placeholder={t("sendEmailScreen.emailSubjectPlaceholder")}
                          type={'text'}
                          isInvalid={errors.emailSubject && touched.emailSubject ? true : false}
                          value={values.emailSubject}
                          onChange={handleChange}/>
                        {errors.emailSubject && touched.emailSubject ? (
                          <small className="text-danger">{errors.emailSubject}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
                <Col md={2}></Col>
              </Row>

            </Container>

            <h5 className="text-primary text-center mt-4 font-weight-bold">{t("sendEmailScreen.emailHeader")}</h5>
            <p className="p-0 m-0 text-center">{t("sendEmailScreen.emailHeaderDescription")}</p>

            <Container className="formContainer mt-4 mb-3">

                <ImageUploadModal
                show={showUploadLogoPhotoModal}
                imageURL={values.logoURL ? values.logoURL : ""}
                aspectRatio={1}
                cropShape="round"
                onCroppedImage={(croppedImage) => {
                  setCroppedLogo(croppedImage)
                }}
                onCancel={() => {
                  setShowUploadLogoPhotoModal(false)
                }}
                onSave={() => {
                  setShowUploadLogoPhotoModal(false)
                  handleUploadLogoImage(croppedLogo)
                }}/>

              <Row className="justify-content-center">
                
                  <Col md={4}>
                    <Field
                      name="logoURL"
                      render={() => (
                        <FormGroup controlId="logoURL">
                          <p
                            className={`textInputLabel mb-2 text-center text-primary ${errors.logoURL && touched.logoURL ? 'text-danger' : ''}`}>{t(`sendEmailScreen.companyLogo`)}</p>
                          {!isMessageSent &&
                            <label className="btn btn-secondary d-block" style={{width: 150, margin: '0px auto'}}>
                              {t(`manageVenuesScreen.uploadPhoto`)}
                              <input
                                id="logoURL"
                                name="file"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(event) => {
                                  if (event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]?.type.includes("image")) {
                                    setFieldValue("logoURL", URL.createObjectURL(event.currentTarget.files[0]));
                                    setShowUploadLogoPhotoModal(true)
                                  }
                                }}/>
                            </label>
                          }
                        </FormGroup>
                      )}
                    />
                  </Col>
              </Row>

              <Row className="justify-content-center" style={{height: 200}}>
                <Col md={4} className="text-center">
                  <UploadedImage uploadedImage={uploadedLogoImage}/>
                </Col>
              </Row>

              <Row className="mb-2 mt-4">
                <Col md={2}></Col>
                <Col md={8}>
                  <Field
                    name="emailTitle"
                    render={() => (
                      <FormGroup controlId="emailTitle">
                        <FormLabel style={{fontSize: 20}}
                          className={`textInputLabel font-weight-bold d-block text-center ${errors.emailTitle && touched.emailTitle ? 'text-danger' : ''}`}>{t("sendEmailScreen.emailTitle")}</FormLabel>
                        <FormControl
                          className="textInput"
                          placeholder={t("sendEmailScreen.emailSubjectPlaceholder")}
                          type={'text'}
                          isInvalid={errors.emailTitle && touched.emailTitle ? true : false}
                          value={values.emailTitle}
                          onChange={handleChange}/>
                        {errors.emailTitle && touched.emailTitle ? (
                          <small className="text-danger">{errors.emailTitle}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
                <Col md={2}></Col>
              </Row>

              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  <Field
                    name="emailContent"
                    render={() => (
                      <FormGroup controlId="emailContent">
                        <FormLabel
                          className={`textInputLabel d-block text-center ${errors.emailContent && touched.emailContent ? 'text-danger' : ''}`}>{t(`sendEmailScreen.paragraphTitle`)}</FormLabel>
                        <FormControl
                          className="textInput"
                          as='textarea'
                          rows={8}
                          placeholder={t(`sendEmailScreen.paragraphPlaceholder`)}
                          isInvalid={errors.emailContent && touched.emailContent ? true : false}
                          value={values.emailContent ? values.emailContent : undefined}
                          onChange={(e) => {
                            setFieldValue('emailContent', e.target.value)
                          }}/>
                        {errors.emailContent && touched.emailContent ? (
                          <small className="text-danger">{errors.emailContent}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
                <Col md={2}></Col>
              </Row>

            </Container>

            {t("sendEmailScreen.topicsExplainer").split('\n').map(text => 
              <p className="p-0 m-0 text-center">{text}</p>
            )}

            <br/>

            <FieldArray
              name="topics"
              render={arrayHelpers => (
                <div>
                  {values.topics.length > 0 &&
                    values.topics.map((topic: any, index) => (
                      <div key={index}>

                      <h5 className="text-primary text-center mt-4 mb-3 font-weight-bold">{`${t(`sendEmailScreen.topic`)} ${index + 1}`}</h5>

                      {t("sendEmailScreen.topicDescription").split('\n').map(text => 
                        <p className="p-0 m-0 text-center">{text}</p>
                      )}
                      
                      <TopicContainer 
                        topic={topic} 
                        index={index} 
                        handleChange={handleChange} 
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}/>

                      </div>
                    ))}

                  {!isMessageSent &&
                    <div className="text-center">
                      <div
                        onClick={() =>
                          arrayHelpers.push({ 
                            topicTitle: "",
                            topicContent: "",
                            imgURL: "",
                            firstName: "", 
                            lastName: "" ,
                            url: ""
                          })
                        } 
                        className="text-primary font-weight-bold text-center mt-4 btn">
                        <FontAwesomeIcon className="mr-2" icon={["fas", "plus"]}/>{t("sendEmailScreen.addNewTopic")}
                      </div>
                      <p>{t("sendEmailScreen.addNewTopicDescription")}</p>
                    </div>
                  }
                  
                </div>
              )}
            />
     

            <Container className="formContainer mt-4" style={{padding: '0px !important'}}>
              <Row className="justify-content-center">
                <Col md={2}></Col>
                <Col md={6} >
                  <Field
                    name="startSendingTime"
                    render={() => (
                      <FormGroup controlId="startSendingTime">
                        <FormLabel
                          className={`textInputLabel text-primary font-weight-bold d-block text-center ${errors.startSendingTime && touched.startSendingTime ? 'text-danger' : ''}`}>{t("sendPushNotificationsScreen.startSending")}</FormLabel>
                        <FormControl className="textInput" as='select'
                                    style={{width: 250, margin: '0px auto'}}
                                     isInvalid={errors.startSendingTime && touched.startSendingTime ? true : false}
                                     defaultValue={isCustomTime ? t("sendPushNotificationsScreen.customTime") : t("sendPushNotificationsScreen.now")}
                                     onChange={(e) => {
                                       if (e.target.value === t("sendPushNotificationsScreen.now")) {
                                        setIsCustomTime(false)
                                       } else {
                                        setIsCustomTime(true)
                                        setFieldValue(
                                          'startSendingTime', 
                                          `${moment(values.startSendingTime).utc().format('YYYY-MM-DD')} 23:59`
                                        )
                                       }
                                     }}>
                          {[t("sendPushNotificationsScreen.now"), t("sendPushNotificationsScreen.customTime")].map(optionName => <option>{optionName}</option>)}
                        </FormControl>
                        {errors.startSendingTime && touched.startSendingTime ? (
                          <small className="text-danger">{errors.startSendingTime}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                    
                    {isCustomTime &&
                        <>
                            <DatePicker 
                            // @ts-ignore
                            selected={values.startSendingTime ? new Date(values.startSendingTime) : undefined}
                            disabled={isMessageSent}
                            onChange={(date) => setFieldValue('startSendingTime', `${moment(date).utc().format('YYYY-MM-DD')} ${moment(values.startSendingTime).utc().format('HH:mm')}`) }
                            // @ts-ignore
                            // minDate={new Date(values.payments.validFrom)}
                            className="datePicker pl-3 pt-1 pb-1 mr-3"
                            dateFormat="P"
                            locale={i18n.languages[0]}/>

                        <div className="d-inline-block" style={{position: 'relative'}}>
                            <input className="textInputLabel datePicker pl-2 pr-1" 
                            defaultValue={moment(values.startSendingTime).utc().format('HH:mm')}
                            onChange={(e) => 
                              setFieldValue(
                                'startSendingTime', 
                                moment(`${moment(values.startSendingTime).format('YYYY-MM-DD')} ${e.target.value}`).format('YYYY-MM-DD HH:mm')
                              )
                            }
                            style={{height: 34}} 
                            type="time" id="appt" name="appt" min="00:00" max="24:00" required></input>
                        </div>
                      </>
                    }
                    
                </Col>
                <Col md={2}></Col>
            </Row>
            </Container>

            {!isMessageSent &&
              <p className="text-center mt-3">{t("sendEmailScreen.sendToReviewEmailDescription")}</p>
            }

            <div>
              {userSessionStore!.isSuperAdmin &&
                <Button variant="secondary mt-4 mr-2" style={{float: 'right'}} onClick={() => {
                  setSelectedSendStatus(SEND_STATUS.SENT)
                  setShowSaveModal(true)
                }} className="font-weight-bold">
                    {t("messagesScreen.markAsSent")}
                </Button>
              }
              <Button variant="primary mt-4 mr-2" style={{float: 'right'}} onClick={() => {
                setSelectedSendStatus(SEND_STATUS.READY)
                setShowSaveModal(true)
              }} className="font-weight-bold">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "mobile"]}/>
                  {t("sendEmailScreen.sendToReview")}
              </Button>
              <Button variant="secondary mt-4 mr-2" style={{float: 'right'}} onClick={() => {
                setSelectedSendStatus(SEND_STATUS.DRAFT)
                setShowSaveModal(true)
              }} className="font-weight-bold">
                  <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
                  {t("sendPushNotificationsScreen.saveDraft")}
              </Button>
            </div>

            <div style={{height: 40}}></div>
            </fieldset>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default withTranslation()(inject("userSessionStore", "api", "messageStore", "memberStore")(observer(SendEmailScreen)))
