import log from "loglevelnext"
import { flow, getEnv, getRoot, Instance, types } from "mobx-state-tree"
import { IMessage, Message } from "./Message"
import { IRootStore } from "./RootStore"
import moment from 'moment';
const MESSAGE_TYPE = {
  EMAIL: 'EMAIL',
  PUSH: 'PUSH'
}

const MESSAGE_STATUS = {
  DRAFT: 'DRAFT',
  READY: 'READY',
  SENT: 'SENT'
}

export const MessageStore = types.model("BenefitStore")
  .props({
    messages: types.optional(types.late(() => types.array(Message)), []),
  }).views(self => ({

  }))
  .views(self => ({
    getTotalPushNotificationsSent(): number {
      const rootStore = getRoot<IRootStore>(self);
      const userSessionStore = rootStore.userSessionStore;
      return self.messages.filter(message => 
        message.messageType === MESSAGE_TYPE.PUSH && 
        message.status === MESSAGE_STATUS.SENT &&
        (userSessionStore.isOrganizationContext() ? true : message.configuration?.venues.some(venue => venue.id === userSessionStore.selectedBranch?.id))
      ).length;
    },
    getPushNotificationsSentLast30Days(): number {
      const rootStore = getRoot<IRootStore>(self);
      const userSessionStore = rootStore.userSessionStore;
      const thirtyDaysAgo = moment().subtract(30, 'days').toDate();
      
      return self.messages.filter(message => 
        message.messageType === MESSAGE_TYPE.PUSH && 
        message.status === MESSAGE_STATUS.SENT &&
        moment(message.sendTime).isAfter(thirtyDaysAgo) &&
        (userSessionStore.isOrganizationContext() ? true : message.configuration?.venues.some(venue => venue.id === userSessionStore.selectedBranch?.id))
      ).length;
    },
    getTotalEmailsSent(): number {
      const rootStore = getRoot<IRootStore>(self);
      const userSessionStore = rootStore.userSessionStore;
      return self.messages.filter(message => 
        message.messageType === MESSAGE_TYPE.EMAIL && 
        message.status === MESSAGE_STATUS.SENT &&
        (userSessionStore.isOrganizationContext() ? true : message.configuration?.venues.some(venue => venue.id === userSessionStore.selectedBranch?.id))
      ).length;
    }
  }))
  .actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {
      log.debug("MemberStore: fetchData")
      const response = yield getEnv(self).api.fetchMessages(undefined, undefined, requestSource)

      const fetchedMessages = response.payload.data

      const validMessages = fetchedMessages?.filter((message: IMessage) => Boolean(message?.id));

      
      if (response.modified) {
        self.messages.replace(validMessages)
      }
      return response.etag
    }),
    addMessage(message: IMessage) {
      if (message?.id) {

        self.messages.unshift(message)
      }
    },
    removeMessage(messageId: string) {
      const messageIndex = self.messages.findIndex(message => message.id === messageId);
      if (messageIndex !== -1) {
        self.messages.splice(messageIndex, 1);
      }
    },
    updateMessage(message: any) {
      if (message?.id) {
        const messageToUpdateIndex = self.messages.findIndex(s => s.id === message.id)
        self.messages[messageToUpdateIndex] = message
      }
    },
    resetData() {
      log.debug("MessageStore: resetData")
      self.messages.clear()
    },
  }))

export type IMessageStore = Instance<typeof MessageStore>
