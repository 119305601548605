import {Redirect, Route, RouteProps} from "react-router"
import {IUserSessionStore} from "../models/UserSessionStore"
import {inject} from "mobx-react"
import React from "react"
import Container from "react-bootstrap/Container"

interface AppRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  userSessionStore?: IUserSessionStore
  enabled?: boolean
  authenticationRequired: boolean
  loggedInRedirect?: string
  loggedOutRedirect?: string
  navBar?: any
  forceRemount?: boolean
}

export const AppRoute = inject("userSessionStore")((props: AppRouteProps) => {
  // @ts-ignore
  const {component: Component, userSessionStore, enabled=true, authenticationRequired, loggedInRedirect, loggedOutRedirect, navBar, forceRemount, ...rest} = props
  const remountProps = forceRemount ? { key: Date.now() } : {}
  return (
    <Route
      {...rest}
      // FIXME: refactor :)
      render={(routeProps) => {
        if (enabled) {
          if (!authenticationRequired) {
            // no authentication required but logged in & redirect set
            if (userSessionStore!.isLoggedIn() && loggedInRedirect) {
              return (<Redirect
                  to={{
                    pathname: loggedInRedirect,
                    state: {from: routeProps.location},
                  }}/>
              )
            } else {
              return (<>
                {navBar && navBar()}
                  <Container className="mt-4" {...remountProps}>
                      <Component {...routeProps} />
                  </Container>
              </>)
            }
          } else {
            // auth required & logged in
            if (userSessionStore!.isLoggedIn()) {
              return (<>
                {navBar && navBar()}
                <Container className="mt-4" {...remountProps}>
                  <Component {...routeProps} />
                </Container>
              </>)
            } else {
              // logged out redirect set
              if (loggedOutRedirect) {
                return (<Redirect
                    to={{
                      pathname: loggedOutRedirect,
                      state: {from: routeProps.location},
                    }}/>
                )
              } else {
                return (<Redirect
                    to={{
                      pathname: "/login",
                      state: {from: routeProps.location},
                    }}/>
                )
              }
            }
          }

        } else {
          return null
        }
      }}
    />
  )
})
