import {RouteComponentProps, withRouter} from "react-router"
import {IUserSessionStore} from "../models/UserSessionStore"
import {inject, observer} from "mobx-react"
import React from "react"
import {withTranslation, WithTranslation} from "react-i18next"
import {IBenefitReportStore} from "../models/BenefitReportStore"
import {BenefitTable} from "../components/BenefitTable"
import {IRootStore} from "../models/RootStore"
import AlertMessage from "../components/AlertMessage"
import {trackEvent} from "../tracking"
import trackingConstants from "../tracking/trackingConstants"
import log from "loglevelnext"
import Dropdown from "react-bootstrap/Dropdown"
import Button from "react-bootstrap/Button"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {BenefitsModifyLocation} from "../Locations"
import {IVenue} from "../models/Venue"
import {SummaryHeader, SummaryItem} from "../components/SummaryHeader"

const BENEFIT_SCREEN_ERROR = "benefitScreen"

export interface BenefitsScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  benefitReportStore?: IBenefitReportStore
  rootStore?: IRootStore
}

@(withRouter as any)
@inject("userSessionStore")
@inject("benefitReportStore")
@observer
class BenefitsScreen extends React.Component<BenefitsScreenProps> {

  handleDateFilter(this: this, selection: { startDate: Date, endDate: Date } | null) {
    log.debug("BenefitsScreen: handleDateFilter, selection:", selection)
    this.props.benefitReportStore!.setDateFilter(selection)
  }

  componentDidMount(): void {
    log.debug("BenefitsScreen mounted")
    trackEvent(trackingConstants.screens.benefits, {selectedBranch: this.props.userSessionStore!.selectedBranch!.name})
  }

  forwardToModifyScreen(venue: IVenue) {
    this.props.history.push(BenefitsModifyLocation.toUrl(venue.urlFields()))
  }

  render() {
    const {userSessionStore, benefitReportStore, t} = this.props

    const summaries: Array<SummaryItem> = [
      {
        title: t("benefitScreen.summary.totalActivationsTitle"),
        value: benefitReportStore!.reporting.benefitsUsedTotal.toString(),
        footer: t("benefitScreen.summary.totalActivationsFooter"),
      },
      {
        title: t("benefitScreen.summary.activationsLastMonthTitle"),
        value: benefitReportStore!.reporting.benefitsUsedLast30Days.toString(),
        footer: t("benefitScreen.summary.activationsLastMonthFooter"),
      },
    ]

    const headerButtons = (
      <>
        {userSessionStore!.isOrganizationContext() ?
          <Dropdown
            className="mr-2"
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown.Toggle variant="secondary" id="dropdown-edit-benefits-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "pen-to-square"]}/>{t("benefitScreen.modifyButton")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                                 onClick={this.forwardToModifyScreen.bind(this, venue)}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
            className="mr-2"
            onClick={this.forwardToModifyScreen.bind(this, userSessionStore!.selectedBranch! as IVenue)}
           variant="secondary"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "pen-to-square"]}/>{t("benefitScreen.modifyButton")}
          </Button>}

        {userSessionStore!.isOrganizationContext() ?
          <Dropdown
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown.Toggle variant="success" id="dropdown-edit-benefits-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "star"]}/>{t("benefitScreen.addButton")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                                 onClick={this.forwardToModifyScreen.bind(this, venue)}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
            onClick={this.forwardToModifyScreen.bind(this, userSessionStore!.selectedBranch! as IVenue)}
            variant="success"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "star"]}/>{t("benefitScreen.addButton")}
          </Button>}

      </>
    )

    return (
      <div>
        <AlertMessage source={BENEFIT_SCREEN_ERROR}/>
        <SummaryHeader summaries={summaries} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} buttons={headerButtons}/>

        <BenefitTable data={benefitReportStore!.filteredReport} onDateFilterChange={this.handleDateFilter.bind(this)}
                      isOrganizationContext={userSessionStore!.isOrganizationContext()}/>
      </div>
    )
  }
}

export default withTranslation()(BenefitsScreen)
