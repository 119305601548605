import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { colors } from "../../theme/colors";

const cardBorderRadius = 5

interface MemberGrowthTimelineProps {
  data: Array<{ day: string; cumulative_count: number }>;
  showTooltip?: boolean;
}

const EmptyTimelineChart = () => {
  const { t } = useTranslation();
  
  return  <Card style={{ width: 592, height: '100%', position: 'relative', borderRadius: cardBorderRadius }}>
  <Card.Body style={{ borderRadius: cardBorderRadius, paddingBottom: 28, paddingTop: 12 }}>
    <div style={{
      color: colors.white,
      fontSize: 18,
    }}>{t("homeScreen.memberGrowthTimeline")}</div>
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={[]}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <XAxis />
        <YAxis axisLine={true} tick={false} width={1} />
        <Area type="monotone" dataKey="cumulative_count" stroke="#FFFFFF" fillOpacity={1} fill="url(#colorUv)" />
      </AreaChart>
    </ResponsiveContainer>
  </Card.Body>
</Card>
}

const isValidTimelineData = (data: any): data is Array<{ day: string; cumulative_count: number }> => {
  if (!Array.isArray(data) || data.length === 0) return false;
  
  return data.every(item => 
    item &&
    typeof item === 'object' &&
    typeof item.day === 'string' &&
    !isNaN(new Date(item.day).getTime()) && 
    typeof item.cumulative_count === 'number' &&
    !isNaN(item.cumulative_count)
  );
};


const MemberGrowthTimeline: React.FC<MemberGrowthTimelineProps> = ({ data, showTooltip = true }) => {
  const { t } = useTranslation();

 
  if (!isValidTimelineData(data)) {
    return (
      <EmptyTimelineChart/>
    );
  }

  const shouldShowGranularView = () => {
    if (data.length <= 1) return true;
    
    const firstDate = moment(data[0].day);
    const lastDate = moment(data[data.length - 1].day);
    const monthsDiff = lastDate.diff(firstDate, 'months');
    
    return monthsDiff < 1;
  };

  const formatDate = (date: string) => {
    const momentDate = moment(date);
    const currentYear = moment().year();
    const dateYear = momentDate.year();
    
    if (shouldShowGranularView()) {
      const hasSimilarDates = data.some(item => {
        const itemDate = moment(item.day);
        return itemDate.month() === momentDate.month() && 
               itemDate.year() === momentDate.year() && 
               itemDate.date() !== momentDate.date();
      });

      if (hasSimilarDates) {
        return dateYear === currentYear 
          ? momentDate.format('DD MMM')
          : momentDate.format('DD MMM YY');
      }
      
      return dateYear === currentYear 
        ? momentDate.format('MMM')
        : momentDate.format('MMM YY');
    }

    return momentDate.format('MMM YY');
  };

  const formatTooltipDate = (label: string, formattedData: Array<{ day: string; originalDate: string }>) => {
    const originalDate = formattedData.find(item => item.day === label)?.originalDate;
    if (!originalDate) return label;
  
    const momentDate = moment(originalDate);
    const isCurrentYear = momentDate.year() === moment().year();
    
    return isCurrentYear 
      ? momentDate.format('DD MMM')
      : momentDate.format('DD MMM YYYY');
  };
  

  const formattedData = data?.map(item => ({
    ...item,
    originalDate: item.day,
    day: typeof item.day === 'string' && item.day.includes('202') ? formatDate(item.day) : item.day,
  }));

  return (
    <Card style={{ width: 592, height: '100%', position: 'relative', borderRadius: cardBorderRadius }}>
      <Card.Body style={{ borderRadius: cardBorderRadius, paddingBottom: 28, paddingTop: 12 }}>
        <div style={{
          color: colors.white,
          fontSize: 18,
        }}>{t("homeScreen.memberGrowthTimeline")}</div>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={formattedData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FFFFFF" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
              </linearGradient>
            </defs>
            <XAxis 
              dataKey="day"
            />
            <YAxis axisLine={true} tick={false} width={1} />
            {showTooltip && (
           <Tooltip 
           contentStyle={{
             backgroundColor: 'rgba(0, 0, 0, 0.8)',
             border: 'none',
             borderRadius: '4px',
             color: '#FFFFFF'
           }}
           labelStyle={{ color: '#FFFFFF' }}
           labelFormatter={(label: string) => formatTooltipDate(label, formattedData)}
           formatter={(value: any, name: string) => [value, t("homeScreen.members") as string]}
         />
            )}
            <Area 
              type="monotone" 
              dataKey="cumulative_count" 
              name={t("homeScreen.members")}
              stroke="#FFFFFF" 
              fillOpacity={1} 
              fill="url(#colorUv)" 
            />
          </AreaChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};

  export default MemberGrowthTimeline