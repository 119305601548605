import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, Form, Formik } from 'formik'
import React from "react"
import { Button } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import Row from "react-bootstrap/Row"
import * as Yup from 'yup'

export interface ManageVenuesScreenProps {
  history?: any
  onSubmit?: (organizationName: string) => void
  name: string
}

const ManageVenuesScreen = (props: ManageVenuesScreenProps) => {
  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required('Required'),
  });

  const initValues = {
    organizationName: props.name,
  }

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        if (props.onSubmit) {
          props.onSubmit(values.organizationName || "")
        }
      }}
    >
    {({ errors, touched, values, handleChange }) => (
      <Form>
        <Container className="no-gutters">
          <Row>
            <Col className="col-lg-6">
              <Field 
                name="name"
                render={() => (
                  <FormGroup controlId="organizationName">
                    <FormControl className="textInput" type={'text'} isInvalid={errors.organizationName && touched.organizationName ? true : false} value={values.organizationName} onChange={handleChange} />
                    {errors.organizationName && touched.organizationName ? (
                      <small className="text-danger">{errors.organizationName}</small>
                    ) : null}
                  </FormGroup>
                )}
              />
            </Col>
            <Col className="col-lg-6">
              <Button className="ml-2" variant="primary" type="submit">
                <FontAwesomeIcon icon={["fas", "check"]}/>
              </Button>
            </Col>
          </Row>
        </Container>
        
      </Form>
      )}
    </Formik>
  )
}

export default ManageVenuesScreen
