import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Field, Form, Formik} from 'formik'
import {inject, observer} from "mobx-react"
import React, {useState} from "react"
import {Container, Form as BootstrapForm} from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Card from 'react-bootstrap/Card'
import Col from "react-bootstrap/Col"
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Modal from 'react-bootstrap/Modal'
import Row from "react-bootstrap/Row"
import {withTranslation, WithTranslation} from "react-i18next"
import Skeleton from 'react-loading-skeleton'
import Select from "react-select"
import * as Yup from 'yup'
import {MerchantAdminApi} from "../../Api"
import AlertMessage from "../../components/AlertMessage"
import ImageUploadModal from "../../components/ImageUploadModal"
import i18n from "../../i18n"
import {ManageVenuesLocation} from "../../Locations"
import {IUserSessionStore} from "../../models/UserSessionStore"
import {urlToFile} from "../../utils"
import {categories} from "./categories"
import "./CreateUpdateVenueScreen.scss"
import { colors } from "../../theme/colors"

const ADD_VENUE_ERROR = "addVenueError"
const CHECK_VENUE_HANDLE_ERROR = 'checkVenueHandleError'

export interface AddVenueScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  history?: any
}

const IMAGE_UPLOAD_STATUS = {
  UPLOADING: "UPLOADING",
  ERROR: "ERROR"
}


const CreateUpdateVenueScreen = (props: AddVenueScreenProps) => {
  const {t, userSessionStore, api, history} = props
  
  const [croppedLogo, setCroppedLogo] = useState('')
  const [croppedCover, setCroppedCover] = useState('')
  const [showHideVenueModal, setShowHideVenueModal] = useState(false);
  const [showGoBackModal, setShowGoBackModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showUploadLogoPhotoModal, setShowUploadLogoPhotoModal] = useState(false);
  const [showUploadCoverPhotoModal, setShowUploadCoverPhotoModal] = useState(false);
  const [isClubyHandleValid, setIsClubyHandleValid] = useState(true);

  const AVAILABLE_CITIES_LIST = props.userSessionStore!.countryConfig!.cities

  const getVenueIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    if (urlVars.length >= 5 && urlVars[3] === 'venue') {
      return urlVars[4]
    } else {
      return ''
    }
  }

  const venueId = getVenueIDFromURL()
  const selectedVenue = userSessionStore?.currentOrganization?.venues.find(venue => venue.id === venueId)
  const [uploadedLogoImage, setUploadedLogoImage] = useState(selectedVenue?.images?.logoImage || "");
  const [uploadedCoverImage, setUploadedCoverImage] = useState(selectedVenue?.images?.mainImage || "");

  const currentLanguage: string = i18n.languages[0];

  const HTTP_HTTPS_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  const HTTP_HTTPS_MAILTO_REGEX = /^(http|https):\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$|^mailto:[^@\s]+@[^@\s]+\.[^@\s]+$/



  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    businessID: Yup.string().nullable(true),
    streetAddress: Yup.string().nullable(true),
    postalCode: Yup.string().nullable(true),
    websiteURL: Yup.string().matches(
      HTTP_HTTPS_REGEX,
      t('manageVenuesScreen.urlMustIncludeHTTPPrefix')
    ).nullable(true),
    emailAddress: Yup.string().email().nullable(true),
    phoneNumber: Yup.string().nullable(true),
    whatsappNumber: Yup.string().nullable(true),
    instagram: Yup.string().nullable(true),
    facebookPageURL: Yup.string().matches(
      HTTP_HTTPS_REGEX,
      t('manageVenuesScreen.urlMustIncludeHTTPPrefix')
    ).nullable(true),
    messengerURL: Yup.string().matches(
      HTTP_HTTPS_REGEX,
      t('manageVenuesScreen.urlMustIncludeHTTPPrefix')
    ).nullable(true),
    bookingURL: Yup.string().matches(
      HTTP_HTTPS_MAILTO_REGEX,
      t('manageVenuesScreen.urlMustIncludeHTTPMailtoPrefix')
    ).nullable(true),
    longitude: Yup.string().nullable(true),
    latitude: Yup.string().nullable(true),
    isClubyHandleValid: Yup.boolean().required().oneOf([true]),
    clubyHandle: Yup.string().required('Required'),
    description: Yup.object().nullable(true),
    city: Yup.string().required('Required'),
    logoURL: Yup.string().nullable(true),
    coverURL: Yup.string().nullable(true),
    hideVenue: Yup.bool(),
    iban: Yup.string().nullable(true),
    categories: Yup.array().min(1)
  });

  const categoriesOptions = Object.keys(categories).map((categoryName: string) => ({
    value: categoryName, label: (categories as any)[categoryName][currentLanguage]
  }))
  //@ts-ignore
  const selectedVenueCategories = selectedVenue?.categories ? selectedVenue.categories[currentLanguage] : ''
  const filteredCategoriesOptions = categoriesOptions.filter(categoryOption => selectedVenueCategories.split(", ").includes(categoryOption.label))

  const initValues = selectedVenue ?
    {
      id: selectedVenue.id,
      name: selectedVenue.name || undefined,
      businessID: selectedVenue.businessId || undefined,
      streetAddress: selectedVenue.street || undefined,
      postalCode: selectedVenue.postcode || undefined,
      websiteURL: selectedVenue?.contactInformation?.www || undefined,
      emailAddress: selectedVenue?.contactInformation?.email || undefined,
      phoneNumber: selectedVenue?.contactInformation?.phone ? selectedVenue.contactInformation?.phone.replace('tel:', '') : undefined,
      whatsappNumber: selectedVenue?.contactInformation?.whatsapp || undefined,
      instagram: selectedVenue?.contactInformation?.instagram || undefined,
      facebookPageURL: selectedVenue?.contactInformation?.facebook || undefined,
      messengerURL: selectedVenue?.contactInformation?.messenger || undefined,
      bookingURL: selectedVenue?.enabledFeatures?.tableBookingUri || undefined,
      longitude: selectedVenue.lng || undefined,
      latitude: selectedVenue.lat || undefined,
      clubyHandle: selectedVenue.handle || undefined,
      isClubyHandleValid: true,
      description: selectedVenue?.description?.default || selectedVenue?.description?.en || selectedVenue?.description?.fi,
      city: selectedVenue.city || undefined,
      logoURL: selectedVenue?.images?.logoImage || undefined,
      coverURL: selectedVenue?.images?.mainImage || undefined,
      iban: selectedVenue._bankAccount || undefined,
      categories: filteredCategoriesOptions,
      hideVenue: selectedVenue.isHidden
    }
    :
    {
      id: "NEW__",
      name: '',
      businessID: '',
      streetAddress: '',
      postalCode: '',
      websiteURL: '',
      emailAddress: '',
      phoneNumber: '',
      whatsappNumber: '',
      instagram: '',
      facebookPageURL: '',
      messengerURL: '',
      bookingURL: '',
      longitude: '',
      latitude: '',
      clubyHandle: '',
      isClubyHandleValid: false,
      description: '',
      city: 'Helsinki',
      logoURL: '',
      coverURL: '',
      iban: '',
      categories: [],
      hideVenue: false
    }

  const checkVenueHandle = async (clubyHandle: string, setFieldValue: (fieldName: string, value: any) => void) => {
    const CLUBY_HANDLE_REGEX = /^[a-z0-9]+(?:-[a-z0-9]+)*$/
    if (clubyHandle.length < 1 || !CLUBY_HANDLE_REGEX.test(clubyHandle)) {
      setFieldValue('isClubyHandleValid', false)
      setIsClubyHandleValid(false)
      return
    }

    const handleValidity = await props.api!.checkVenueHandle(clubyHandle, undefined, CHECK_VENUE_HANDLE_ERROR)

    if (handleValidity === "FREE" || clubyHandle === selectedVenue?.handle) {
      setIsClubyHandleValid(true)
      setFieldValue('isClubyHandleValid', true)
    } else {
      setFieldValue('isClubyHandleValid', false)
      setIsClubyHandleValid(false)
    }
  }

  interface ClubyHandleApprovalProps {
    longEnough: boolean
  }

  const ClubyHandleApproval = (props: ClubyHandleApprovalProps) => {
    if (!props.longEnough) {
      return null
    }

    if (isClubyHandleValid) {
      return <FontAwesomeIcon className="ml-2 text-success" icon={["fas", "check"]}/>
    } else {
      return <small className="text-error ml-2">{t("manageVenuesScreen.tryDifferentHandle")}</small>
    }
  }


  const onBackToVenues = (isFormEdited: boolean) => {
    if (isFormEdited)
      setShowGoBackModal(true)
    else
      history.push(ManageVenuesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
  }

  const handleUploadLogoImage = async (imageBlob: any) => {
    setUploadedLogoImage(IMAGE_UPLOAD_STATUS.UPLOADING)
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await api!.uploadImage('venue/logo', imageFile)
    if (imageURL) {
      setUploadedLogoImage(imageURL)
    } else {
      setUploadedLogoImage(IMAGE_UPLOAD_STATUS.ERROR)
    }
  }

  const handleUploadCoverImage = async (imageBlob: any) => {
    setUploadedCoverImage(IMAGE_UPLOAD_STATUS.UPLOADING)
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await api!.uploadImage('venue/main', imageFile)
    if (imageURL) {
      setUploadedCoverImage(imageURL)
    } else {
      setUploadedCoverImage(IMAGE_UPLOAD_STATUS.ERROR)
    }
  }

  const handleSaveAndPublish = async (venues: any[], newVenue: boolean) => {
    const response = await api!.postVenues(venues, undefined, ADD_VENUE_ERROR)
    if (response && response.venues.length > 0) {
      if (newVenue) {
        userSessionStore?.currentOrganization?.addVenue(response.venues[0])
      } else {
        userSessionStore?.currentOrganization?.updateVenue(response.venues[0])
      }
      history.push(ManageVenuesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }
  }

  const LogoImage = () => {
    if (uploadedLogoImage === "") {
      return <Card className="croppedLogo"/>
    } else if (uploadedLogoImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton circle={true} height={200} width={200}/>
    } else if (uploadedLogoImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return <Card className="croppedLogo">
        <div className="alert alert-danger mt-5" role="alert">
          {t('manageVenuesScreen.imageUploadFailed')}
        </div>
      </Card>
    } else {
      return <img className="croppedLogo" src={uploadedLogoImage} alt="logo"/>
    }
  }

  const CoverImage = () => {
    if (uploadedCoverImage === "") {
      return <Card className="croppedCover"/>
    } else if (uploadedCoverImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton height={198} width={330}/>
    } else if (uploadedCoverImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return <Card className="croppedCover">
        <div className="alert alert-danger mt-5" role="alert">
          {t('manageVenuesScreen.imageUploadFailed')}
        </div>
      </Card>
    } else {
      return <img className="croppedCover" src={uploadedCoverImage} alt="logo"/>
    }
  }

  const formatFormValues = (values: any, updatedFields: any) => {
    const fieldsToSend: any = {}

    if (values.name) fieldsToSend.name = values.name
    if (updatedFields.clubyHandle) fieldsToSend.handle = values.clubyHandle
    if (values.description) fieldsToSend.description = {default: values.description}
    if (values.categories) fieldsToSend.categories = {
      en: values.categories?.map((c: any) => (categories as any)[c.value].en).join(', '),
      fi: values.categories?.map((c: any) => (categories as any)[c.value].fi).join(', ')
    }
    if (values.logoURL || values.coverURL) {
      const imagesObj: any = {}
      if (values.logoURL) imagesObj.logoImage = uploadedLogoImage
      if (values.coverURL) imagesObj.mainImage = uploadedCoverImage
      fieldsToSend.images = imagesObj
    }
    if (values.websiteURL || values.emailAddress || values.phoneNumber || values.facebookPageURL || values.messengerURL || values.whatsappNumber || values.instagram) {
      const contactInformationObj: any = {}
      contactInformationObj.www = values.websiteURL || null
  contactInformationObj.email = values.emailAddress || null
  contactInformationObj.phone = values.phoneNumber ? 'tel:' + values.phoneNumber : null
  contactInformationObj.whatsapp = values.whatsappNumber ? values.whatsappNumber : null
  contactInformationObj.instagram = values.instagram ? values.instagram : null
      contactInformationObj.facebook = values.facebookPageURL ? values.facebookPageURL : null
      contactInformationObj.messenger = values.messengerURL ? values.messengerURL : null
      fieldsToSend.contactInformation = contactInformationObj
    }
  fieldsToSend.enabledFeatures = {
      "tableBookingUri": values.bookingURL || null
  }
    if (values.businessID) fieldsToSend.businessId = values.businessID
    if (values.iban) fieldsToSend._bankAccount = values.iban
    if (values.streetAddress) fieldsToSend.street = values.streetAddress
    if (values.city) fieldsToSend.city = values.city
    if (values.postalCode) fieldsToSend.postcode = values.postalCode
    fieldsToSend.lat = values.latitude || null
    fieldsToSend.lng = values.longitude || null
    if ("hideVenue" in updatedFields) fieldsToSend.isHidden = values.hideVenue



    return fieldsToSend
  }


  const getObjChangedValues = (values: any, initialValues: any) => {
    return Object
      .entries(values)
      .reduce((acc: any, [key, value]) => {
        const hasChanged = initialValues[key] !== value

        if (hasChanged) {
          acc[key] = value
        }

        return acc
      }, {})
  }

  const selectedCategoriesWithUpdatedLang = (selectedCategories: any[]) => {
    const selectedCategiruesIDS = selectedCategories.map((sc: any) => sc.value)
    return categoriesOptions.filter(c => selectedCategiruesIDS.includes(c.value))
  }

  return (
    <Container>
      <AlertMessage source={ADD_VENUE_ERROR}/>

      <Modal show={showGoBackModal} centered onHide={() => {
        setShowGoBackModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{t("manageVenuesScreen.goBackPopup.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("manageVenuesScreen.goBackPopup.description")}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => {
            setShowGoBackModal(false)
          }}>
            {t("manageVenuesScreen.goBackPopup.cancel")}
          </Button>
          <Button variant="primary" onClick={() => {
            history.push(ManageVenuesLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
          }}>
            {t("manageVenuesScreen.goBackPopup.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>


      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const updatedFields = getObjChangedValues(values, initValues)
          const venueData = {
            id: values.id,
            city: values.city,
            ...formatFormValues(values, updatedFields)
          }
          const isNewVenue = values.id === 'NEW__'
          handleSaveAndPublish([venueData], isNewVenue)
        }}
      >
        {({dirty, errors, touched, values, handleChange, handleSubmit, handleBlur, resetForm, setFieldValue}) => (
          <Form>
            <Modal show={showRevertModal} centered onHide={() => {
              setShowRevertModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.revertChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.revertChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  resetForm()
                  setUploadedLogoImage(selectedVenue?.images?.logoImage || "")
                  setUploadedCoverImage(selectedVenue?.images?.mainImage || "")
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showSaveModal} centered onHide={() => {
              setShowSaveModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.saveChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.saveChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowSaveModal(false)
                }}>
                  {t("manageVenuesScreen.saveChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  handleSubmit()
                  setShowSaveModal(false)
                }}>
                  {t("manageVenuesScreen.saveChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Row>
              <Col md={4}>
                <Button
                  variant="secondary"
                  className="font-weight-bold"
                  onClick={() => onBackToVenues(dirty)}>
                  <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
                  {t("manageVenuesScreen.backToVenues")}
                </Button>
              </Col>
              <Col md={8}>
                <div className="float-right">
                  <Button variant="outline-primary font-weight-bold" className="mr-3" onClick={() => setShowRevertModal(true)}>
                    <FontAwesomeIcon className="mr-2" icon={["fas", "trash-alt"]}/>
                    {t("manageVenuesScreen.revert")}
                  </Button>
                  <Button variant="outline-success" className="font-weight-bold" onClick={() => setShowSaveModal(true)}>
                    <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                    {t("manageVenuesScreen.saveAndPublish")}
                  </Button>
                </div>

              </Col>

            </Row>
            <h4 className="text-primary text-center mt-4">{values.name}</h4>

            <Container className="formContainer">
              <p className="text-uppercase">{t(`manageVenuesScreen.basicInformation`)}</p>

              <Row>
                <Col md={6}>
                  <Field
                    name="name"
                    render={() => (
                      <FormGroup controlId="name">
                        <FormLabel
                          className={`textInputLabel ${errors.name && touched.name ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.name`)}</FormLabel>
                        <FormControl
                          className="textInput"
                          type={'text'}
                          isInvalid={errors.name && touched.name ? true : false}
                          value={values.name}
                          onChange={handleChange}/>
                        {errors.name && touched.name ? (
                          <small className="text-danger">{errors.name}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <p className="mb-0">{t(`manageVenuesScreen.handle`)}</p>
              <small>{t(`manageVenuesScreen.handleDescription`)}</small>
              <Row>
                <Col md={12}>
                  <span className="text-secondary">cluby.com/</span>
                  <Field
                    name="clubyHandle"
                    render={() => (
                      <FormGroup controlId="clubyHandle" className="form-horizontal clubyHandleInput ml-1">
                        <FormControl
                          htmlSize={40}
                          className="textInput inline-block"
                          type={'text'}
                          isInvalid={!isClubyHandleValid || (errors.clubyHandle && touched.clubyHandle) ? true : false}
                          value={values.clubyHandle}
                          onChange={e => {
                            setFieldValue('clubyHandle', e.target.value.toLowerCase())
                            checkVenueHandle(e.target.value, setFieldValue)
                          }}/>
                      </FormGroup>
                    )}
                  />
                  <ClubyHandleApproval longEnough={values.clubyHandle ? values.clubyHandle.length > 1 : false}/>
                  {errors.clubyHandle && touched.clubyHandle ? (
                    <small className="text-danger ml-2">{errors.clubyHandle}</small>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field
                    name="description"
                    render={() => (
                      <FormGroup controlId="description">
                        <FormLabel
                          className={`textInputLabel ${errors.description && touched.description ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.description`)}</FormLabel>
                        <FormControl
                          className="textInput"
                          as='textarea'
                          rows={4}
                          isInvalid={errors.description && touched.description ? true : false}
                          value={values.description ? values.description : undefined}
                          onChange={(e) => {
                            setFieldValue('description', e.target.value)
                          }}/>
                        {errors.description && touched.description ? (
                          <small className="text-danger">{errors.description}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormLabel className="textInputLabel">{t(`manageVenuesScreen.categories`)}</FormLabel>
                  <br/>
                  <small className="text-secondary">{t(`manageVenuesScreen.categoriesDescription`)}</small>
                  <br/>
                  {errors.categories && touched.categories ? (
                    <small className="text-danger">{errors.categories}</small>
                  ) : null}
                  <Select
                    placeholder={t("manageVenuesScreen.selectCategories")}
                    onChange={(selectedOptions: any) => {
                      if (selectedOptions.length <= 5)
                        setFieldValue('categories', selectedOptions)
                    }}
                    isMulti
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        backgroundColor: 'transparent', 
                      }),
                      menu: (provided: any) => ({
                        ...provided,
                        backgroundColor: colors.background, 
                      }),
                      multiValue: (provided: any) => ({
                        ...provided,
                        backgroundColor: colors.primaryText, 
                      }),
                      option: (provided: any, state: any) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? colors.primaryText  : colors.background ,
                        color: state.isFocused ? colors.black : colors.primaryText, 
                      }),
                      multiValueRemove: (provided: any) => ({
                        ...provided,
                        color: colors.background, 
                        ':hover': {
                          color: 'hoverColorForXIcon', 
                          backgroundColor: 'hoverBackgroundColorForXIcon', 
                        },
                      }),
                    }}
                    options={categoriesOptions}
                    value={selectedCategoriesWithUpdatedLang(values.categories)}
                    closeMenuOnSelect={false}
                    className="mb-2"
                  />
                </Col>
              </Row>

              <ImageUploadModal
                show={showUploadLogoPhotoModal}
                imageURL={values.logoURL ? values.logoURL : ""}
                aspectRatio={1}
                cropShape="round"
                onCroppedImage={(croppedImage) => {
                  setCroppedLogo(croppedImage)
                }}
                onCancel={() => {
                  setShowUploadLogoPhotoModal(false)
                }}
                onSave={() => {
                  setShowUploadLogoPhotoModal(false)
                  handleUploadLogoImage(croppedLogo)
                }}/>

              <Row>
                <Col md={6}>
                  <Field
                    name="logoURL"
                    render={() => (
                      <FormGroup controlId="logoURL">
                        <FormLabel
                          className={`textInputLabel ${errors.logoURL && touched.logoURL ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.logo`)}</FormLabel>
                        <br/>
                        <label className="btn btn-secondary">
                          {t(`manageVenuesScreen.uploadPhoto`)}
                          <input
                            id="logoURL"
                            name="file"
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(event) => {
                              if (event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]?.type.includes("image")) {
                                setFieldValue("logoURL", URL.createObjectURL(event.currentTarget.files[0]));
                                setShowUploadLogoPhotoModal(true)
                              }
                            }}/>
                        </label>
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row style={{height: 200}}>
                <Col md={4}>
                  <LogoImage/>
                </Col>
              </Row>

              <ImageUploadModal
                show={showUploadCoverPhotoModal}
                imageURL={values.coverURL ? values.coverURL : ""}
                aspectRatio={1500 / 900}
                cropShape="rect"
                onCroppedImage={(croppedImage) => {
                  setCroppedCover(croppedImage)
                }}
                onCancel={() => {
                  setShowUploadCoverPhotoModal(false)
                }}
                onSave={() => {
                  setShowUploadCoverPhotoModal(false)
                  handleUploadCoverImage(croppedCover)
                }}/>

              <Row>
                <Col md={6}>
                  <Field
                    name="coverURL"
                    render={() => (
                      <FormGroup controlId="coverURL">
                        <FormLabel
                          className={`textInputLabel mt-3 mb-0 ${errors.coverURL && touched.coverURL ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.coverPhoto`)}</FormLabel>
                        <div className="mb-2">
                          <small>{t('manageVenuesScreen.coverDescription')}</small>
                        </div>

                        <label className="btn btn-secondary">
                          {t(`manageVenuesScreen.uploadPhoto`)}
                          <input
                            type="file"
                            id="coverURL"
                            hidden
                            name="file"
                            accept="image/*"
                            onChange={(event) => {
                              if (event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]?.type.includes("image")) {
                                setFieldValue("coverURL", URL.createObjectURL(event.currentTarget.files[0]));
                                setShowUploadCoverPhotoModal(true)
                              }
                            }}/>
                        </label>
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row style={{height: 200}}>
                <Col md={4}>
                  <CoverImage/>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field
                    name="businessID"
                    render={() => (
                      <FormGroup controlId="businessID">
                        <FormLabel
                          className={`textInputLabel ${errors.businessID && touched.businessID ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.businessID`)}</FormLabel>
                        <FormControl className="textInput" type={'text'}
                                     isInvalid={errors.businessID && touched.businessID ? true : false}
                                     value={values.businessID} onChange={handleChange}/>
                        {errors.businessID && touched.businessID ? (
                          <small className="text-danger">{errors.businessID}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field
                    name="iban"
                    render={() => (
                      <FormGroup controlId="iban">
                        <FormLabel
                          className={`textInputLabel ${errors.iban && touched.iban ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.iban`)}</FormLabel>
                        <FormControl className="textInput" type={'text'}
                                     isInvalid={errors.iban && touched.iban ? true : false} value={values.iban}
                                     onChange={handleChange}/>
                        {errors.iban && touched.iban ? (
                          <small className="text-danger">{errors.iban}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <p className="text-uppercase">{t(`manageVenuesScreen.locationInfo`)}</p>

              <Row>
                <Col md={6}>
                  <Field
                    name="streetAddress"
                    render={() => (
                      <FormGroup controlId="streetAddress">
                        <FormLabel
                          className={`textInputLabel ${errors.streetAddress && touched.streetAddress ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.streetAddress`)}</FormLabel>
                        <FormControl className="textInput" type={'text'}
                                     isInvalid={errors.streetAddress && touched.streetAddress ? true : false}
                                     value={values.streetAddress} onChange={handleChange}/>
                        {errors.streetAddress && touched.streetAddress ? (
                          <small className="text-danger">{errors.streetAddress}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Field
                    name="postalCode"
                    render={() => (
                      <FormGroup controlId="postalCode">
                        <FormLabel
                          className={`textInputLabel ${errors.postalCode && touched.postalCode ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.postalCode`)}</FormLabel>
                        <FormControl className="textInput" type={'text'}
                                     isInvalid={errors.postalCode && touched.postalCode ? true : false}
                                     value={values.postalCode} onChange={handleChange}/>
                        {errors.postalCode && touched.postalCode ? (
                          <small className="text-danger">{errors.postalCode}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field
                    name="city"
                    render={() => (
                      <FormGroup controlId="city">
                        <FormLabel
                          className={`textInputLabel ${errors.city && touched.city ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.city`)}</FormLabel>
                        <FormControl className="textInput" as='select'
                                     isInvalid={errors.city && touched.city ? true : false} value={values.city}
                                     onChange={handleChange}>
                          {AVAILABLE_CITIES_LIST.map(city => <option>{city}</option>)}
                        </FormControl>
                        {errors.city && touched.city ? (
                          <small className="text-danger">{errors.city}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Field
                    name="latitude"
                    render={() => (
                      <FormGroup controlId="latitude">
                        <FormLabel
                          className={`mb-0 textInputLabel ${errors.latitude && touched.latitude ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.latitude`)}</FormLabel>
                        <br/>
                        <small>{t(`manageVenuesScreen.useFormatLat`)}</small>
                        <FormControl className="textInput" type={'text'}
                                     isInvalid={errors.latitude && touched.latitude ? true : false}
                                     value={values.latitude} onChange={handleChange}/>
                        {errors.latitude && touched.latitude ? (
                          <small className="text-danger">{errors.latitude}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Field
                    name="longitude"
                    render={() => (
                      <FormGroup controlId="longitude">
                        <FormLabel
                          className={`mb-0 textInputLabel ${errors.longitude && touched.longitude ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.longitude`)}</FormLabel>
                        <br/>
                        <small>{t(`manageVenuesScreen.useFormatLng`)}</small>
                        <FormControl className="textInput" type={'text'}
                                     isInvalid={errors.longitude && touched.longitude ? true : false}
                                     value={values.longitude} onChange={handleChange}/>
                        {errors.longitude && touched.longitude ? (
                          <small className="text-danger">{errors.longitude}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <p className="text-uppercase">{t(`manageVenuesScreen.additionalInfo`)}</p>
              {
                [
                  {name: 'websiteURL', hasErrors: errors.websiteURL && touched.websiteURL, value: values.websiteURL},
                  {
                    name: 'emailAddress',
                    hasErrors: errors.emailAddress && touched.emailAddress,
                    value: values.emailAddress
                  },
                  {
                    name: 'phoneNumber',
                    hasErrors: errors.phoneNumber && touched.phoneNumber,
                    value: values.phoneNumber
                  },
                  {
                    name: 'whatsappNumber',
                    hasErrors: errors.whatsappNumber && touched.whatsappNumber,
                    value: values.whatsappNumber
                  },
                  {
                    name: 'facebookPageURL',
                    hasErrors: errors.facebookPageURL && touched.facebookPageURL,
                    value: values.facebookPageURL
                  },
                  {
                    name: 'messengerURL',
                    hasErrors: errors.messengerURL && touched.messengerURL,
                    value: values.messengerURL
                  },
                  {
                    name: 'instagram',
                    hasErrors: errors.instagram && touched.instagram,
                    value: values.instagram
                  },
                  {name: 'bookingURL', hasErrors: errors.bookingURL && touched.bookingURL, value: values.bookingURL},
                ].map((input, index) =>
                  <Row key={index}>
                    <Col md={6}>
                      <Field
                        name={input.name}
                        render={() => (
                          <FormGroup controlId={input.name}>
                            <FormLabel
                              className={`textInputLabel ${input.hasErrors ? 'text-danger' : ''}`}>{t(`manageVenuesScreen.${input.name}`)}</FormLabel>
                            <FormControl className="textInput" type={'text'} isInvalid={input.hasErrors ? true : false}
                                         value={input.value} onBlur={handleBlur} onChange={handleChange}/>
                            {input.hasErrors ? (
                              <small className="text-danger">{(errors as any)[input.name]}</small>
                            ) : null}
                          </FormGroup>
                        )}
                      />
                    </Col>
                  </Row>
                )
              }

              <Row className="mb-3">
                <Col md={6}>
                  <FormGroup controlId="hideVenue">
                    <BootstrapForm.Check
                      type="switch"
                      id="hideVenue"
                      className="float-right"
                      checked={values.hideVenue}
                      onChange={() => {
                        if (values.hideVenue) {
                          setFieldValue('hideVenue', false)
                        } else {
                          setShowHideVenueModal(true)
                        }
                      }}
                      label={t("manageVenuesScreen.hideVenue")}
                    />
                  </FormGroup>

                </Col>
              </Row>

              <Modal show={showHideVenueModal} centered onHide={() => {
                setShowHideVenueModal(false)
              }}>
                <Modal.Header closeButton>
                  <Modal.Title>{t("manageVenuesScreen.doYouWantToHideVenue")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("manageVenuesScreen.hideVenueDescription")}</Modal.Body>
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={() => {
                    setShowHideVenueModal(false)
                    setFieldValue('hideVenue', false)
                  }}>
                    {t("manageVenuesScreen.cancel")}
                  </Button>
                  <Button variant="primary" onClick={() => {
                    setShowHideVenueModal(false)
                    setFieldValue('hideVenue', true)
                  }}>
                    {t("manageVenuesScreen.hide")}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Row>
                <Col md={6}>
                  <Button variant="outline-success" onClick={() => setShowSaveModal(true)} className="font-weight-bold">
                    <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                    {t("manageVenuesScreen.saveAndPublish")}
                  </Button>
                </Col>
              </Row>

            </Container>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default withTranslation()(inject("userSessionStore", "api")(observer(CreateUpdateVenueScreen)))
