// @ts-ignore
export function trackEvent(event: string, metadata?: any) {
  // @ts-ignore
  if (window.dataLayer) {
    // @ts-ignore
    window.dataLayer.push({
      'event': 'trackEvent',
      'trackEvent': event,
      'metadata': metadata
    })
  }
}
