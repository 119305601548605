import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { inject, observer } from "mobx-react"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { withTranslation, WithTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import { MerchantAdminApi } from "../Api"
import AlertMessage from "../components/AlertMessage"
import { ProductCard } from "../components/ProductCard"
import { ProductModifyLocation, ProductsLocation } from "../Locations"
import { IProductStore } from "../models/ProductStore"
import { IRootStore } from "../models/RootStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import { IVenue } from "../models/Venue"

const PRODUCTS_MODIFY_SCREEN_ERROR = "productsModifyScreen"


export interface ProductsModifyScreenProps extends RouteComponentProps, WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  productStore?: IProductStore
  rootStore?: IRootStore
}


const ProductsModifyScreen = (props: ProductsModifyScreenProps) => {
  const {userSessionStore, productStore, history, t} = props

  const getVenueIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    if (urlVars.length >= 5 && urlVars[3] === 'venue') {
      return urlVars[4]
    } else {
      return ''
    }
  }

  const venueId = getVenueIDFromURL()
  const selectedVenue = userSessionStore?.currentOrganization?.venues.find(venue => venue.id === venueId)
  
  const productsFilteredByVenue = productStore?.nonRewardProducts().filter((product) => (product.restaurantId as any).id === venueId) || []
  const activeProducts = productsFilteredByVenue.filter(product => product.visible) || []
  const inactiveProducts = productsFilteredByVenue.filter(product => !product.visible) || []

  const navToProductModifyScreen = (venue: IVenue) =>
    history.push(ProductModifyLocation.toUrl({...venue.urlFields(), productId: "NEW"}))

  return (
    <div>
      <AlertMessage source={PRODUCTS_MODIFY_SCREEN_ERROR}/>
      <Container>
        <Row>
          <Col className="col-4 p-0">
            <Button
              onClick={() => history.push(ProductsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))}
              variant="secondary">
                <FontAwesomeIcon className="mr-2" icon={["fal", "chevrons-left"]}/>
                {t("productsScreen.backButton")}
            </Button>
          </Col>
          <Col className="col-8 p-0 text-right">
          </Col>
        </Row>
      </Container>
      <div className="text-center mt-5 mb-5">
        <h2 className="p-0 m-0 text-primary font-weight-bold">{selectedVenue?.name}</h2>
        <p className="p-0 m-0 mt-2 font-size-medium">{t("productsScreen.productModifySubtitle")}</p>
      </div>

      <Container>
        <Row>
          <Col className="col-6">
            <h4 className="mt-3 text-primary">{t("productsScreen.soldProducts")} ({activeProducts.length})</h4>
          </Col>
          <Col className="col-6 d-flex align-items-center justify-content-end">
            <Button
              onClick={() => navToProductModifyScreen(selectedVenue as IVenue)}
              variant="success"><FontAwesomeIcon className="mr-2"
                                                icon={["fal", "star"]}/>{t("productsScreen.addProduct")}
            </Button>
          </Col>
        </Row>
      </Container>
      
      <div className="mt-3">
        {activeProducts.length > 0 
          ? activeProducts.map((product) => <ProductCard onEdit={() => history.push(ProductModifyLocation.toUrl({...userSessionStore!.selectedBranch!.urlFields(), productId: product.id}))} product={product}/>)
          : <p className="font-size-medium">{t("productsScreen.noActiveProducts")}</p>}
      </div>

      <h4
        className="mt-5 text-primary">{t("productsScreen.inactiveProducts")} ({inactiveProducts.length})</h4>
      <div className="mt-3">
      {inactiveProducts.length > 0 
          ? inactiveProducts.map((product) => <ProductCard onEdit={() => history.push(ProductModifyLocation.toUrl({...userSessionStore!.selectedBranch!.urlFields(), productId: product.id}))} product={product}/>)
          : <p className="font-size-medium">{t("productsScreen.noInactiveProducts")}</p>}
      </div>
    </div>
  )
}

export default withTranslation()(inject("userSessionStore", "rootStore", "api", "productStore")(observer(ProductsModifyScreen)))
