import {MerchantAdminApi} from "../Api"
import {inject, observer} from "mobx-react"
import React from "react"
import {TextInput, ValidationForm} from "react-bootstrap4-form-validation"
import {IUserSessionStore} from "../models/UserSessionStore"
import {RouteComponentProps} from "react-router"
import {LoginLocation} from "../Locations"
import validator from "validator"
import AlertMessage from "../components/AlertMessage"
import {WithTranslation, withTranslation} from "react-i18next"


type ResetPasswordScreenState = {
  buttonPressed: boolean
  resetPasswordCompleted: boolean
  resetPasswordMessage?: string
  formData: { [index: string]: string }
}

interface MatchParams {
  resetToken?: string,
}

export interface ResetPasswordScreenProps extends RouteComponentProps<MatchParams>, WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
}

const RESET_PASSWORD_EMAIL_SCREEN_ERROR = "resetPasswordEmailScreen"
const RESET_PASSWORD_TOKEN_SCREEN_ERROR = "resetPasswordTokenScreen"


@inject("api")
@inject("userSessionStore")
@observer
class ResetPasswordScreen extends React.Component<ResetPasswordScreenProps, ResetPasswordScreenState> {
  redirectTimeout: any

  constructor(props: ResetPasswordScreenProps) {
    super(props)
    this.state = {
      buttonPressed: false,
      resetPasswordCompleted: false,
      resetPasswordMessage: undefined,
      formData: {
        email: "",
        resetToken: "",
        passphrase: "",
        passphraseConfirm: "",
      },
    }
  }

  componentDidMount(): void {
    const resetToken = this.props.match.params["resetToken"] || ""
    this.setState({
      formData: {
        email: "",
        resetToken: resetToken,
        passphrase: "",
        passphraseConfirm: "",
      }
    })
  }

  async onFormSubmit(e: any, formData: any, inputs: any) {
    const {resetToken, passphrase, email} = this.state.formData
    const {api} = this.props
    e.preventDefault()
    this.setState({buttonPressed: true})
    try {
      const resetResponse = resetToken !== "" ? await api!.resetPassword(resetToken, passphrase, RESET_PASSWORD_TOKEN_SCREEN_ERROR)
        : await api!.resetPasswordRequest(email, RESET_PASSWORD_EMAIL_SCREEN_ERROR)
      if (!resetResponse.ok) {
        return
      }

      // @ts-ignore
      this.setState({resetPasswordCompleted: true})
      this.redirectTimeout = setTimeout(() => {
        this.props.history.push(LoginLocation.toUrl())
      }, 3000)
    } finally {
      this.setState({buttonPressed: false})
    }
  }

  handleChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [target.name]: target.value,
      },
    }))

  }

  matchPassword = (value: any) => {
    return value && value === this.state.formData.passphrase
  }

  renderCompletedMessage() {
    const {t} = this.props
    return (
      <>
        <div className="alert alert-success" role="alert">
          <h5>{this.state.formData.resetToken !== "" ? t("passwordResetSuccess") : t("passwordResetLink")}</h5>
        </div>
      </>
    )
  }

  renderEmailForm() {
    const {t} = this.props
    return (
      <>
        <AlertMessage source={RESET_PASSWORD_EMAIL_SCREEN_ERROR} />

        <ValidationForm onSubmit={this.onFormSubmit.bind(this)}>
          <fieldset disabled={this.state.buttonPressed}>
            <div className="form-group row">
              <div className="col-md-12 text-left">
                <label htmlFor="email">{t("email")}</label>
                <TextInput name="email" id="email" required
                           value={this.state.formData.email}
                           onChange={this.handleChange}
                           validator={validator.isEmail}
                           errorMessage={{
                             validator: t("validationErrorEmail"),
                             required: t("fieldRequired")
                           }}
                           successMessage={t("validationSuccess")}/>
              </div>
              <div className="col-md-12 text-left mt-4">
                <button ref="loginButton" className="btn-block btn btn-primary">{t("sendResetLink")}
                </button>
              </div>
            </div>
          </fieldset>
        </ValidationForm>
      </>
    )
  }

  renderResetForm() {
    const {t} = this.props
    return (
      <>
        <AlertMessage source={RESET_PASSWORD_TOKEN_SCREEN_ERROR} />

        <ValidationForm onSubmit={this.onFormSubmit.bind(this)}>
          <fieldset disabled={this.state.buttonPressed}>
            <div className="form-group row">
              <div className="col-md-12 text-left">
                <label htmlFor="passphrase">{t("passphrase")}</label>
                <TextInput name="passphrase" id="passphrase" required
                           minLength="8"
                           type="password"
                           autoComplete="off"
                           value={this.state.formData.passphrase}
                           errorMessage={{
                             validator: t("validationErrorPassphrase"),
                             minLength: t("passwordMinLength"),
                             required: t("fieldRequired")
                           }}
                           onChange={this.handleChange}
                           successMessage={t("validationSuccess")}/>
              </div>
              <div className="col-md-12 text-left">
                <label htmlFor="passphraseConfirm">{t("passphraseConfirm")}</label>
                <TextInput name="passphraseConfirm" id="passphraseConfirm" required
                           minLength="8"
                           type="password"
                           autoComplete="off"
                           value={this.state.formData.passphraseConfirm}
                           validator={this.matchPassword}
                           errorMessage={{
                             required: t("confirmPassPhraseRequired"),
                             validator: t("passphraseNotMatch"),
                             minLength: t("passwordMinLength"),
                           }}
                           onChange={this.handleChange}
                           successMessage={t("validationSuccess")}/>
              </div>
              <div className="col-md-12 text-left mt-4">
                <button ref="loginButton" className="btn-block btn btn-primary">{t("reset")}
                </button>
              </div>
            </div>
          </fieldset>
        </ValidationForm>
      </>
    )
  }

  render() {
    const {resetPasswordCompleted, formData: {resetToken}} = this.state
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-md-5">
            <div className="card">
              <h4 className="card-header">{this.props.t("reset")}</h4>
              <div className="card-body">
                {resetPasswordCompleted ?
                  this.renderCompletedMessage()
                  :
                  resetToken !== "" ? this.renderResetForm() : this.renderEmailForm()
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default withTranslation()(ResetPasswordScreen)