import React, {useEffect, useRef, useState} from "react"
import Card from "react-bootstrap/Card"
import {IconName, IconProp} from "@fortawesome/fontawesome-svg-core"
import {BenefitFormat, BenefitInterval, BenefitKind, IBenefit} from "../models/Benefit"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useTranslation, withTranslation} from "react-i18next"
import Button from "react-bootstrap/Button"
import {inject, observer} from "mobx-react"
import i18n from "../i18n"
import {TextInput, TextInputGroup, ValidationForm} from "react-bootstrap4-form-validation"
import Form from "react-bootstrap/Form"
import {IconPickerDropdown} from "./IconPickerDropdown"
import {applySnapshot, clone, getIdentifier, getParent, getSnapshot} from "mobx-state-tree"
import * as _ from "lodash"
import Badge from "react-bootstrap/Badge"
import Dropdown from "react-bootstrap/Dropdown"
import {IBenefitStore} from "../models/BenefitStore"
import SplitButton from "react-bootstrap/SplitButton"
import ImageUploadModal from "./ImageUploadModal"
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { urlToFile } from "../utils"
import { MerchantAdminApi } from "../Api"
import Skeleton from "react-loading-skeleton"
import { colors } from "../theme/colors"
import { IVenueStatus } from "../models/MemberStore"
import Select, { ValueType, OptionTypeBase, OptionsType } from 'react-select'

const benefitIconList = [
  "coffee",
  "beer",
  "sandwich",
  "glass-champagne",
  "ice-cream",
  "wine-glass-alt",
  "pizza",
  "stroopwafel",
  "hamburger",
  "french-fries",
  "drumstick",
  "mug-hot",
  "cocktail",
  "wine-glass",
  "utensils-alt",
  "glass-cheers",
  "salad",
  "soup",
  "wine-bottle",
  "pie",
  "ticket-alt",
  "cheeseburger",
  "cheese",
  "star",
  "glass",
  "box",
].sort()

export interface BenefitModifyCardProps {
  benefit: IBenefit
  getRef: any
  api?: MerchantAdminApi
  onEditModeToggle(): boolean
  onBenefitModify(modified: boolean): void
  isNew?: boolean
  originalBenefit?: IBenefit
  activeMemberStatusesAndSubscriptions?: IVenueStatus[]
}

const IMAGE_UPLOAD_STATUS = {
  UPLOADING: "UPLOADING",
  ERROR: "ERROR"
}

export const BenefitModifyCard = (props: Readonly<BenefitModifyCardProps>) => {

  const {benefit, onEditModeToggle, onBenefitModify, isNew, originalBenefit, api} = props

  const thisRef = useRef(null)
  const validationFormRef = React.useRef(null)

  const [editMode, setEditMode] = useState(isNew && benefit.localeTitle() === "")
  const [isModified, setIsModified] = useState(false)
  const [isOriginal, setIsOriginal] = useState(originalBenefit ? compareBenefits(originalBenefit, benefit) : true)
  const [showUploadPhotoModal, setShowUploadPhotoModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('')
  const [croppedPhoto, setCroppedPhoto] = useState('')

  useEffect(() => {

    function resetCard() {
      setIsOriginal(true)
      setIsModified(false)
      setModifiedBenefit(clone(originalBenefit!))
      setEditMode(false)
    }

    // @ts-ignore
    thisRef.current = {
      formRef: validationFormRef,
      editMode: editMode,
      isModified: isModified,
      isOriginal: isOriginal,
      setEditMode: setEditMode,
      resetCard: resetCard,
    }
    props.getRef(thisRef)
  }, [validationFormRef, editMode, isModified, isOriginal, originalBenefit, props])

  const [modifiedBenefit, setModifiedBenefit] = useState(clone(benefit))
  const [uploadedImage, setUploadedImage] = useState(modifiedBenefit?.images?.mainImage || "");

  const {t} = useTranslation()

  const icon: IconProp = ["fas", benefit.style.icon.name as IconName]
  const benefitIntervalDescription = benefit.kind === BenefitKind.RECURRING ?
    t("benefit.intervals.recurring") :
    t("benefit.intervals." + benefit.rules.interval)

  const currentLanguage = i18n.languages[0]
  const alternativeLanguage = currentLanguage === "en" ? "fi" : "en"


  function onSubmit(e: any, formData: any, inputs: any) {
    e.preventDefault()

    if (isModified) {
      applySnapshot(benefit, getSnapshot(modifiedBenefit))
      setIsModified(false)
      onUpdate()
    }
    setEditMode(false)
  }

  function onSubmitError(e: any, formData: any, inputs: any) {
    e.preventDefault()
  }

  function onModify() {
    const benefitSnapshot = getSnapshot(benefit)
    const modifiedBenefitSnapshot = getSnapshot(modifiedBenefit)

    const hadModifications = isModified.valueOf()

    const hasModifications = !_.isEqual(benefitSnapshot, modifiedBenefitSnapshot)

    setIsModified(hasModifications)
    if (!hadModifications && hasModifications) onBenefitModify(true)
    if (hadModifications && !hasModifications) onBenefitModify(false)

  }

  function compareBenefits(benefit1: IBenefit, benefit2: IBenefit): boolean {
    return _.isEqual(
      _.omit(getSnapshot(benefit1), "editedAt"), _.omit(getSnapshot(benefit2), "editedAt"))
  }

  function onUpdate() {
    if (originalBenefit) setIsOriginal(compareBenefits(originalBenefit, benefit))
  }

  const handleUploadImage = async (imageBlob: any) => {
    setUploadedImage(IMAGE_UPLOAD_STATUS.UPLOADING)
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await api!.uploadImage('venue/benefit/main', imageFile)
    if (imageURL) {
      setUploadedImage(imageURL)
      modifiedBenefit.setMainImage(imageURL)
      onModify()
    } else {
      setUploadedImage(IMAGE_UPLOAD_STATUS.ERROR)
    }
  }

  const BenefitImage = () => {
    if (uploadedImage === "") {
      return <Card style={{width: '100%', height: '60%'}}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton height={'60%'} width={'100%'}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return <Card style={{width: '100%', height: '60%'}}>
        <div className="alert alert-danger mt-5" role="alert">
          {t('manageVenuesScreen.imageUploadFailed')}
        </div>
      </Card>
    } else {
      return <img style={{width: '100%', height: '60%', borderRadius: '5px'}} src={uploadedImage} alt="logo"/>
    }
  }
  // ({ modifiedBenefit, statuses }: {modifiedBenefit: IBenefit, statuses: IVenueStatus[]})

  interface OptionType {
    value: string;
    label: string;
  }
  
  const BenefitStatusLinkDropdown = ({ modifiedBenefit, statuses }: { modifiedBenefit: IBenefit, statuses?: IVenueStatus[] }) => {
    const options = statuses ? statuses.map(status => ({
      value: status.id,
      label: status.localeTitle + " - " + status.restaurantId.name
    })) : [];
  
    const handleChange = (selectedOptions: OptionType[]) => {
      const selectedStatusIds = selectedOptions?.map(option => option.value) || [];
      modifiedBenefit.setLinkedVenueStatuses(selectedStatusIds);
      onModify()
    };
  
    const currentValue = options.filter(option => 
      modifiedBenefit.linkedVenueStatuses.includes(option.value)
    );
  
    return (
      <Select
        isMulti
        options={options}
        onChange={handleChange}
        value={currentValue}
        className="react-select-container react-select-container-benefits-statuses"
        classNamePrefix="react-select"
        placeholder={t("benefitModifyScreen.modifyCard.allMembers")}
        
      />
    );
  };
  
  
  

  console.log('modifiedBenefit', modifiedBenefit.linkedVenueStatuses.slice())
  
  return (
    <Card key={benefit.id} className="mb-3">
      {editMode ?
        <ValidationForm ref={validationFormRef} className="w-100 d-inline" onSubmit={onSubmit}
                        onErrorSubmit={onSubmitError}>
          <Card.Header className="d-flex align-items-center justify-content-end py-1">
            {isNew &&
            <Button style={{minWidth: "5em"}} className="ml-2" variant={"link"}
                    onClick={() => {
                      (getParent(benefit, 2) as IBenefitStore).removeBenefit(benefit)
                    }}>
              <FontAwesomeIcon className="m-0 mr-2"
                               icon={["fal", "trash-alt"]}/>{t("benefitModifyScreen.modifyCard.removeButton")}
            </Button>
            }
            <Button style={{minWidth: "5em"}} className="ml-2" disabled={!isModified} variant={"link"}
                    onClick={() => {
                      setIsModified(false)
                      setModifiedBenefit(clone(benefit))

                      if (validationFormRef) {
                        // @ts-ignore
                        validationFormRef.current.resetValidationState(true)
                      }
                      onBenefitModify(false)
                    }}>
              <FontAwesomeIcon className="m-0 mr-2"
                               icon={["fal", "undo"]}/>{t("benefitModifyScreen.modifyCard.undoButton")}
            </Button>
            <Button style={{minWidth: "10rem"}} className="ml-2" type="submit" variant={"link"}>
              {isModified ?
                <><FontAwesomeIcon className="m-0 mr-2"
                                   icon={["fal", "clipboard-check"]}/>{t("benefitModifyScreen.modifyCard.applyButton")}</>
                :
                <><FontAwesomeIcon className="m-0 mr-2"
                                   icon={["fal", "clipboard-check"]}/>{t("benefitModifyScreen.modifyCard.closeButton")}</>
              }
            </Button>
          </Card.Header>
          <Card.Body className="p-0 m-0">
            <Container>
                <Row>
                  <Col
                    className="pr-3 m-0 py-2 pb-3 pt-3 flex-column d-flex align-items-center justify-content-center">
                    <div style={{color: colors.primaryText}}>{t("benefitModifyScreen.modifyCard.benefitType")}</div>
                    <Form.Control className="text-center mt-1 text-primary border-primary"
                                  style={{backgroundImage: "none", marginBottom: 10}}
                                  as="select" onChange={(event: any) => {
                      modifiedBenefit.setInterval(event.target.value)
                      onModify()
                    }}
                                  value={modifiedBenefit.getInterval()}
                    >
                      <option
                        value={BenefitInterval.ONE_TIME}>{t("benefit.intervals." + BenefitInterval.ONE_TIME)}</option>
                      <option value={BenefitInterval.MONTH}>{t("benefit.intervals." + BenefitInterval.MONTH)}</option>
                      <option value={BenefitInterval.WEEK}>{t("benefit.intervals." + BenefitInterval.WEEK)}</option>
                      <option value={BenefitInterval.DAY}>{t("benefit.intervals." + BenefitInterval.DAY)}</option>
                      <option value={BenefitKind.RECURRING}>{t("benefit.intervals.recurring")}</option>
                    </Form.Control>


                    <div style={{color: '#212529'}}>{t("benefitModifyScreen.modifyCard.availableTo")}</div>
                    {/* <Form.Control
                      className="text-center mt-1 text-primary border-primary"
                      style={{backgroundImage: "none"}}
                      as="select"
                      multiple
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        console.log('event', event)
                        const selectElement = event.target;
                        console.log('selectElement', selectElement)
                        const selectedOptions = Array.from(selectElement.options)
                          .filter(option => option.selected)
                          .map(option => option.value);
                      console.log('selectedOptions', selectedOptions)
                        modifiedBenefit.setLinkedVenueStatuses(selectedOptions);
                        // onModify(); // Uncomment if you need to handle additional modifications
                      }}                      
                      value={modifiedBenefit.linkedVenueStatuses.map(status => status.id)}
                    >
                      <option value={[]}>{t('benefitModifyScreen.modifyCard.allMembers')}</option>
                      {props.activeMemberStatusesAndSubscriptions?.map(status => (
                        <option key={status.id} value={status.id}>{status.localeTitle}</option>
                      ))}
                    </Form.Control> */}
                    <BenefitStatusLinkDropdown
                        modifiedBenefit={modifiedBenefit}
                        statuses={props.activeMemberStatusesAndSubscriptions}
                      />

                  </Col>
                  <Col className="col-10 d-flex align-items-center py-0">
                    <Container>
                      <Row>
                        <Col className="col-7 m-0 p-0 pt-1 pr-3">
                          <div className="m-0 p-0 mb-1">{t("benefitModifyScreen.modifyCard.descriptionTitle")}</div>
                          <TextInputGroup
                            inputGroupStyle={{minHeight: 59}}
                            prepend={<div style={{width: 45}}
                                          className="form-control input-group-text">{currentLanguage.toUpperCase()}</div>}
                            className="form-control"
                            value={modifiedBenefit.localeText(currentLanguage)}
                            onChange={(event: any) => {
                              modifiedBenefit.setText(currentLanguage, event.target.value)
                              onModify()
                            }}
                            name="titleCurrentLanguage" id="titleCurrentLanguage" required
                            minLength={3}
                            maxLength={66}
                            placeholder={t("benefitModifyScreen.modifyCard.placeholderMainLanguage")}
                            errorMessage={{
                              minLength: t("benefitModifyScreen.modifyCard.validationLengthBenefit"),
                              validator: t("benefitModifyScreen.modifyCard.validationErrorBenefit"),
                              required: t("fieldRequired")
                            }}
                          />
                          <TextInputGroup
                            inputGroupStyle={{minHeight: 59, marginTop: 2}}
                            prepend={<div style={{width: 45}}
                                          className="form-control input-group-text">{alternativeLanguage.toUpperCase()}</div>}
                            className="form-control"
                            onChange={(event: any) => {
                              modifiedBenefit.setText(alternativeLanguage, event.target.value)
                              onModify()
                            }}
                            value={modifiedBenefit.localeText(alternativeLanguage)}
                            name="titleAlternativeLanguage" id="titleAlternativeLanguage" required
                            minLength={3}
                            maxLength={66}
                            placeholder={t("benefitModifyScreen.modifyCard.placeholderAlternativeLanguage")}
                            errorMessage={{
                              minLength: t("benefitModifyScreen.modifyCard.validationLengthBenefit"),
                              validator: t("benefitModifyScreen.modifyCard.validationErrorBenefit"),
                              required: t("fieldRequired")
                            }}
                          />
                        </Col>
                        <Col className="col-3 p-2 pt-1 d-flex flex-column justify-content-center">
                          <div className="m-0 p-0 mb-1">{t("benefitModifyScreen.modifyCard.priceDiscountTitle")}</div>
                          <TextInput
                            className="form-control"
                            value={modifiedBenefit.format === BenefitFormat.OFFER_WITH_PRICE ? modifiedBenefit.localePrice(currentLanguage) : ""}
                            onChange={(event: any) => {
                              console.log('event.target.value', event.target.value)
                              modifiedBenefit.setPrice(currentLanguage, event.target.value)
                              modifiedBenefit.setPrice(alternativeLanguage, event.target.value)
                              onModify()
                            }}
                            maxLength={6}
                            name="priceCurrentLanguage" id="priceCurrentLanguage"
                            placeholder={t("benefitModifyScreen.modifyCard.placeholderPriceDiscount")}
                            errorMessage={{validator: t("benefitModifyScreen.modifyCard.validationErrorPriceDiscount")}}
                          />
                          <div className="m-0 p-0 mb-1">{t("benefitModifyScreen.modifyCard.priceDiscountFooter")}</div>
                        </Col>
                        <Col className="col-2 pt-1 d-flex flex-column justify-content-center">
                          {/* TODO: Send the image URL to the benefits update/post API */}
                          <ImageUploadModal 
                            show={showUploadPhotoModal}
                            imageURL={selectedImage}
                            aspectRatio={5/3}
                            cropShape="rect"
                            onCroppedImage={(croppedImage) => {
                              setCroppedPhoto(croppedImage)
                            }}
                            onCancel={() => {
                              setShowUploadPhotoModal(false)
                            }}
                            onSave={() => {
                              setShowUploadPhotoModal(false)
                              handleUploadImage(croppedPhoto)
                            }}/>
                          
                          <label className="btn btn-secondary mb-2">
                            {t(`benefitModifyScreen.modifyCard.uploadPhoto`)}
                            <input 
                              id="photoURL"
                              name="file" 
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={(event) => {
                                if (event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]?.type.includes("image")) {
                                  setSelectedImage(URL.createObjectURL(event.currentTarget.files[0]));
                                  setShowUploadPhotoModal(true)
                                }
                            }} />
                          </label>
                          
                          <BenefitImage/>
                          
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
            </Container>
          </Card.Body>
        </ValidationForm>
        :
        <Card.Body className="p-0 m-0">
          <Container>
            <Row>
              <Col
                className="col-2 py-3 text-primary d-flex align-items-center justify-content-center">  
                <p className="pl-2 m-0 mt-1 d-flex align-items-center">
                  {benefitIntervalDescription}
                </p>
              </Col>
              <Col className="col-5 py-2 d-flex align-items-center justify-content-start">
                <div>
                  <p
                    className="pl-1 m-0 font-size-medium font-heading font-weight-bold">{benefit.localeTitle(currentLanguage)}</p>
                  <p className="pl-1 m-0 mt-1">{benefit.localeTitle(alternativeLanguage)}</p>

                {!isOriginal && !isNew && <Badge variant="danger">{t("benefitModifyScreen.modifyCard.modifiedBenefit")}</Badge>}
                {isNew && <Badge variant="success">{t("benefitModifyScreen.modifyCard.newBenefit")}</Badge>}
                </div>
              </Col>
              <Col
                className="col-2 py-3 text-primary d-flex align-items-center justify-content-center">  
                <div className="mr-3" style={{
                  textAlign: 'center'
                }}>
                  {benefit.images?.mainImage &&
                    <img style={{width: '50%', height: '30%', objectFit: 'cover', borderRadius: '5px'}} src={benefit.images?.mainImage}/>
                  }
                </div>
              </Col>
              <Col className="col-3 d-flex align-items-center justify-content-end">
                <Form.Check
                  className="mx-1 input-group-text border-0"
                  type="switch"
                  id={"active-switch-h" + benefit.id}
                  label={<small>{t("benefitModifyScreen.modifyCard.activeBenefit")}</small>}
                  checked={!benefit.isHidden}
                  onChange={(event: any) => {
                    benefit.setIsActive(event.target.checked)
                    onUpdate()
                  }}
                />
                <Dropdown 
                  as={ButtonGroup}
                  id={"edit-splitbutton-" + benefit.id}
                  onClick={(e: any) => {
                    /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
                    e.stopPropagation()
                  }}
                >
                  <Button 
                    onClick={(e: any) => {
                      const open = onEditModeToggle()
                      if (open) {
                        setModifiedBenefit(clone(benefit))
                        setEditMode(true)
                      }
                    }}
                    style={{minWidth: "8em"}} size={"sm"} variant="transparent">
                    <FontAwesomeIcon 
                      className="m-0 mr-2" 
                      icon={["fal", "pen-to-square"]}/>{t("benefitModifyScreen.modifyCard.editButton")}
                  </Button>

                  <Dropdown.Toggle split variant="transparent" id={"edit-splitbutton-" + benefit.id} />

                  <Dropdown.Menu>
                    {!isNew && <Dropdown.Item
                      onClick={() => {
                        setIsOriginal(true)
                        setModifiedBenefit(clone(originalBenefit!))
                        applySnapshot(benefit, getSnapshot(originalBenefit!))
                      }}
                      eventKey="revert"
                      disabled={isOriginal}><FontAwesomeIcon className="m-0 mr-2"
                                                            icon={["fal", "trash-undo"]}/>{t("benefitModifyScreen.modifyCard.revertMenuItem")}
                    </Dropdown.Item>}
                    {isNew && <Dropdown.Item
                      onClick={() => {
                        (getParent(benefit, 2) as IBenefitStore).removeBenefit(benefit)
                      }}
                      eventKey="remove"><FontAwesomeIcon className="m-0 mr-2"
                                                        icon={["fal", "trash-alt"]}/>{t("benefitModifyScreen.modifyCard.removeMenuItem")}
                    </Dropdown.Item>}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      }
    </Card>
  )
}

export default withTranslation()(inject("api")(observer(BenefitModifyCard)))
