import {MerchantAdminApi} from "../Api"
import {inject, observer} from "mobx-react"
import React from "react"
import {TextInput, ValidationForm} from "react-bootstrap4-form-validation"
import validator from "validator"
import {IUserSessionStore} from "../models/UserSessionStore"
import AlertMessage from "../components/AlertMessage"
import {withTranslation, WithTranslation} from "react-i18next"
import Card from "react-bootstrap/Card"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import NavLink from "react-bootstrap/NavLink";
import {ResetPasswordLocation} from "../Locations";
import { colors } from "../theme/colors"


type LoginScreenState = {
  buttonPressed: boolean
}

export interface LoginScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
}

const LOGIN_SCREEN_ERROR = "loginScreen"

@inject("api")
@inject("userSessionStore")
@observer
class LoginScreen extends React.Component<LoginScreenProps, LoginScreenState> {

  constructor(props: LoginScreenProps) {
    super(props)
    this.state = {
      buttonPressed: false,
    }
  }

  async onLoginSubmit(e: any, formData: any, inputs: any) {
    e.preventDefault()
    const {api} = this.props
    this.setState({buttonPressed: true})

    try {

      const loginSuccessful = await api!.login(formData.email, formData.passphrase, LOGIN_SCREEN_ERROR)
      if (!loginSuccessful) {
        return
      }

      await api!.refreshLoginStatus(true)

    } finally {
      this.setState({buttonPressed: false})
    }
  }

  render() {
    const {t} = this.props
    return (
      <Container>
        <Row>
          <Col className="col-5 mx-auto mt-5">
            <Card style={{
               border: `solid 1px ${colors.stroke}`
            }}>
              <Card.Header style={{background: colors.boxFill}} as="h5"><FontAwesomeIcon className="mr-2" icon={["fal", "sign-in"]}/>{t("auracardMerchants")}</Card.Header>
              <Card.Body>

                <AlertMessage source={LOGIN_SCREEN_ERROR}/>

                <ValidationForm onSubmit={this.onLoginSubmit.bind(this)}>
                  <fieldset disabled={this.state.buttonPressed}>
                    <div className="form-group row">
                      <div className="col-md-12 text-left">
                        <label htmlFor="email">{t("loginScreen.email")}</label>
                        <TextInput name="email" id="email" required
                                   validator={validator.isEmail}
                                   type="email"
                                   autoCorrect="off"
                                   autoCapitalize="none"
                                   errorMessage={{
                                     validator: t("validationErrorEmail"),
                                     required: t("fieldRequired")
                                   }}
                                   successMessage={t("validationSuccess")}/>
                      </div>
                      <div className="col-md-12 text-left">
                        <label htmlFor="passphrase">{t("loginScreen.password")}</label>
                        <TextInput name="passphrase" id="passphrase" required
                                   minLength="8"
                                   type="password"
                                   autoComplete="off"
                                   errorMessage={{
                                     validator: t("validationErrorPassphrase"),
                                     minLength: t("passwordMinLength"),
                                     required: t("fieldRequired")
                                   }}
                                   successMessage={t("validationSuccess")}/>
                      </div>
                      <div className="col-md-12 text-left mt-4">
                        <button ref="loginButton" className="btn-block btn btn-primary">{t("loginScreen.loginButton")}</button>
                      </div>
                      <div className="pt-2 col-md-12 justify-content-end d-flex">
                        <NavLink className="p-0 d-flex" href={ResetPasswordLocation.toUrl()}><small>{t("forgotPassword")}</small></NavLink>
                      </div>
                    </div>
                  </fieldset>
                </ValidationForm>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withTranslation()(LoginScreen)
