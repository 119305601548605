export default {
  screens: {
    profile: "Profile screen",
    members: "Members screen",
    events: "Events screen",
    eventsModify: "Events modify screen",
    benefits: "Benefits screen",
    benefitsModify: "Benefits modify screen"
  }
}
