import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment'
import 'moment/locale/fi'
const en = require('./i18n/en')
const fi = require('./i18n/fi')


const resources = {
  en: en,
  fi: fi,
}

i18n.on('languageChanged', function(lng) {
    moment.locale(lng);
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: {
      lookupCookie: 'i18next',
      order: ['querystring', 'cookie'],
    },
    resources,
    //lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        return value
      },
    },
  })

export default i18n
