import { TFunction } from "i18next";
import { REWARD_TYPE } from "../../models/RewardStore"
import { IVenue } from "../../models/Venue";

export const getRewardTypeText = ({rewardType, t}: {rewardType: string, t: TFunction;}) => {
    switch (rewardType) {
      case REWARD_TYPE.REWARD_BIRTHDAY:
        return t('rewardsScreen.birthdayGift')
      case REWARD_TYPE.REWARD_JOIN:
        return t('rewardsScreen.signUpReward')
      default:
        return 'Unknown reward type'
    }
  }


 export const getRewardAssignedVenuesName = ({
    venues,
    t,
  }:{venues: IVenue[] | undefined, t: TFunction}) => {

    if (!venues) {
        return '';
     }

    const venueNames = venues.map(venue => venue.name).join(' · ')
  
  
    if (venueNames) {
      return `${t('venues')}: ${venueNames}`;
    } else {
      return "";
    }
  };
