import {RouteComponentProps, withRouter} from "react-router"
import {IUserSessionStore} from "../models/UserSessionStore"
import {inject} from "mobx-react"
import React, {RefObject} from "react"
import Card from "react-bootstrap/Card"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {TextInput, ValidationForm} from "react-bootstrap4-form-validation"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import AlertMessage from "../components/AlertMessage"
import {WithTranslation, withTranslation} from "react-i18next"
import {MerchantAdminApi} from "../Api"
import {SuccessMessage} from "../components/SuccessMessage"
import {trackEvent} from "../tracking"
import trackingConstants from "../tracking/trackingConstants";


export interface ChangePasswordFormProps {
  userSessionStore?: IUserSessionStore
  api?: MerchantAdminApi
  t: WithTranslation["t"]
}

type ChangePasswordFormState = {
  buttonPressed: boolean
  passwordChanged: boolean
  formData: { [index: string]: string }
}

const PASSWORD_CHANGE_FORM_ERROR = "passwordChangeForm"

@inject("userSessionStore")
@inject("api")
export class ChangePasswordForm extends React.Component<ChangePasswordFormProps, ChangePasswordFormState> {
  formRef: RefObject<any>

  constructor(props: ChangePasswordFormProps) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      buttonPressed: false,
      passwordChanged: false,
      formData: {
        currentPassphrase: "",
        newPassphrase: "",
        newPassphraseConfirm: "",
      },
    }
  }

  async onFormSubmit(e: any, formData: any, inputs: any) {
    e.preventDefault()
    this.setState({buttonPressed: true})
    try {

      const passwordChangeResponse: any = await this.props.api!.changePassword(formData.currentPassphrase, formData.newPassphrase, PASSWORD_CHANGE_FORM_ERROR)
      if (!passwordChangeResponse.ok) {
        this.setState(prevState => ({
          formData: {
            ...prevState.formData,
            currentPassphrase: "",
          },
        }))
        this.formRef.current.validateInputs()
        return
      }

      this.setState({
        passwordChanged: true,
        formData: {
          currentPassphrase: "",
          newPassphrase: "",
          newPassphraseConfirm: "",
        },
      })
      this.formRef.current.resetValidationState(true)

    } finally {
      this.setState({buttonPressed: false})
    }
  }

  handleChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    this.setState(prevState => ({
      passwordChanged: false,
      formData: {
        ...prevState.formData,
        [target.name]: target.value,
      },
    }))
  }

  matchPassword = (value: any) => {
    return value && value === this.state.formData.newPassphrase
  }

  render() {
    const {t} = this.props
    return (
      <>
        <SuccessMessage show={this.state.passwordChanged} message={"Password changed successfully!"}/>
        <AlertMessage source={PASSWORD_CHANGE_FORM_ERROR}/>

        <ValidationForm onSubmit={this.onFormSubmit.bind(this)} ref={this.formRef}>
          <fieldset disabled={this.state.buttonPressed}>
            <div className="form-group row">
              <div className="col-md-12 text-left">
                <label htmlFor="currentPassphrase">{t("currentPassphrase")}</label>
                <TextInput name="currentPassphrase" id="currentPassphrase" required
                           minLength="8"
                           type="password"
                           autoComplete="off"
                           value={this.state.formData.currentPassphrase}
                           errorMessage={{
                             validator: t("validationErrorPassphrase"),
                             minLength: t("passwordMinLength"),
                             required: t("fieldRequired")
                           }}
                           onChange={this.handleChange}
                           successMessage={t("validationSuccess")}/>
              </div>
              <div className="col-md-12 text-left">
                <label htmlFor="newPassphrase">{t("newPassphrase")}</label>
                <TextInput name="newPassphrase" id="newPassphrase" required
                           minLength="8"
                           type="password"
                           autoComplete="off"
                           value={this.state.formData.newPassphrase}
                           errorMessage={{
                             validator: t("validationErrorPassphraseNew"),
                             minLength: t("passwordMinLength"),
                             required: t("fieldRequired")
                           }}
                           onChange={this.handleChange}
                           successMessage={t("validationSuccess")}/>
              </div>
              <div className="col-md-12 text-left">
                <label htmlFor="newPassphraseConfirm">{t("newPassphraseConfirm")}</label>
                <TextInput name="newPassphraseConfirm" id="newPassphraseConfirm" required
                           minLength="8"
                           type="password"
                           autoComplete="off"
                           value={this.state.formData.newPassphraseConfirm}
                           validator={this.matchPassword}
                           errorMessage={{
                             required: t("confirmPassPhraseRequired"),
                             validator: t("passphraseNotMatch"),
                             minLength: t("passwordMinLength")
                           }}
                           onChange={this.handleChange}
                           successMessage={t("validationSuccess")}/>
              </div>
              <div className="col-lg-5 mt-3">
                <button ref="loginButton" className="btn-block btn btn-primary">{t("changePassphrase")}</button>
              </div>
            </div>
          </fieldset>
        </ValidationForm>
      </>
    )
  }

}

export interface ProfileScreenProps extends RouteComponentProps, RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
}


@(withRouter as any)
@inject("userSessionStore")
class ProfileScreen extends React.Component<ProfileScreenProps> {
  componentDidMount(): void {
    trackEvent(trackingConstants.screens.profile)
  }

  render() {
    const profile = this.props.userSessionStore!.profile
    const {t} = this.props

    return (
      <>
        <Card>
          <Card.Header as="h5"><FontAwesomeIcon className="mr-2" icon={["fal", "user"]}/>{t("userProfile")}</Card.Header>
          <Card.Body className="pl-4">
            <Card.Title>{profile!.name}</Card.Title>
            <Card.Text><FontAwesomeIcon className="mr-1" icon={["fal", "envelope"]}/> {profile!.email}</Card.Text>
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Header as="h5"><FontAwesomeIcon className="mr-2" icon={["fal", "key"]}/>{t("changePassword")}</Card.Header>
          <Card.Body>
            <Container>
              <Row>
                <Col sm={6}>
                  <ChangePasswordForm t={t}/>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </>
    )
  }
}

export default withTranslation()(ProfileScreen)
