import {flow, getEnv, Instance, types} from "mobx-state-tree"
import {ProductReport, IProductReport} from "./ProductReport"
import * as _ from "lodash"
import log from "loglevelnext"

function filterProducts(productReports: Array<IProductReport> | undefined, venueIds: Array<string>) {
  return (productReports === undefined) ? [] : productReports.filter((productReport: IProductReport) => {
      return venueIds.includes(productReport.restaurantId.id)
    },
  )
}

export const ProductReportStore = types.model("ProductReportStore")
  .props({
    productReports: types.optional(types.array(ProductReport), []),
    dateFilterProductReports: types.maybe(types.array(ProductReport)),
    reporting: types.optional(types.model({
      soldTotal: types.optional(types.number, 0),
      sold30Days: types.optional(types.number, 0),
      revenueTotal: types.optional(types.number, 0),
      revenue30Days: types.optional(types.number, 0),
    }), {}),
  }).views(self => ({
    get filteredReportDefault() {
      log.debug("ProductReportStore: filteredReportDefault updated")
      return filterProducts(self.productReports, getEnv(self).userSessionStore.selectedBranchVenueIds)
    },
    get filteredReportDateFilter() {
      log.debug("ProductReportStore: filteredReportDateFilter updated")
      return filterProducts(self.dateFilterProductReports, getEnv(self).userSessionStore.selectedBranchVenueIds)
    },
  })).views(self => ({
    get filteredReport() {
      log.debug("ProductReportStore: filteredReport updated")
      return self.dateFilterProductReports ? self.filteredReportDateFilter : self.filteredReportDefault
    },
  })).actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {
      log.debug("ProductReportStore: fetchData")

      try {
        const response = yield getEnv(self).api.fetchProductReports(undefined, undefined, requestSource)
      
        if (response.modified) {
          self.productReports.replace(response.payload.data)
        }
        return response.etag
      } catch (error) {
        log.error("ProductReportStore: fetchData failed", error)
      }


    }),
    setDateFilter: flow(function* setProductReportDateFilter(selection: { startDate: Date, endDate: Date } | null, requestSource: string = "productsScreen") {
      if (!selection) {
        self.dateFilterProductReports = undefined
      } else {
        log.debug("ProductReportStore: setDateFilter, fetching data from the backend")
        const response = yield getEnv(self).api.fetchProductReports(undefined, selection, requestSource, true)
        self.dateFilterProductReports = response.payload.data
      }
    }),
    updateReport() {
      log.debug("ProductReportStore: updateReport")
      self.reporting.soldTotal = _.sumBy(self.filteredReportDefault, "soldTotal")
      self.reporting.sold30Days = _.sumBy(self.filteredReportDefault, "sold30Days")
      self.reporting.revenueTotal = _.sumBy(self.filteredReportDefault, "revenueTotal")
      self.reporting.revenue30Days = _.sumBy(self.filteredReportDefault, "revenue30Days")
    },
    resetData() {
      log.debug("ProductReportStore: resetData")
      self.productReports.clear()
      if (self.dateFilterProductReports) {
        self.dateFilterProductReports = undefined
      }
    },
  }))

export type IProductReportStore = Instance<typeof ProductReportStore>
